import { Box, Button, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ContractFunctionExecutionError } from 'viem';
import { useAccount, useWriteContract } from 'wagmi';
import AssetInput from '../../components/inputs/AssetInput';
import { FormattedNumber } from '../../components/primitives/FormattedNumber';
import { useSharedDependencies } from '../../providers/SharedDependencyProvider';
import { StakingAbi } from '../../shared/abi/Staking';
import { TokenAbi } from '../../shared/abi/Token';
import { useRootStore } from '../../store/root';
import { fromBigInt, MAX_UINT256, toBigInt } from '../../utils/tokenUtils';
import { checkRequiresApproval } from '../../utils/transactionUtils';
import { HardLockAbi } from '../../shared/abi/Hardlock';

const tabSx = {
    fontSize: '0.875rem',
    backgroundColor: '#c0c0c0', // Classic grey for Windows
    marginLeft: '-1px',
    marginRight: '2px',
    padding: '5px 12px',
    minHeight: 'unset',
    borderTop: '2px solid #c0c0c0',
    borderLeft: '2px solid #c0c0c0',
    borderRight: '2px solid #c0c0c0',
    borderRadius: '4px 4px 0 0',

    '&:hover': {
        backgroundColor: '#d0d0d0',
        cursor: 'pointer',
    },
    '&.Mui-selected': {
        backgroundColor: '#e0e0e0',
        fontWeight: 'bold',
        color: 'black',
        borderBottom: 'none',
    },
};

const Staking = () => {
    const { writeContractAsync } = useWriteContract();
    const { address } = useAccount();
    const [currentNetworkConfig] = useRootStore((store) => [store.currentNetworkConfig]);
    const { tokenService, stakingService } = useSharedDependencies();

    const [activeTab, setActiveTab] = useState(0);

    const [xpTokenBalance, setXpTokenBalance] = useState('0');
    const [xpLpTokenBalance, setXpLpTokenBalance] = useState('0');

    const [xpStakeTokenAmount, setXpStakeTokenAmount] = useState('0');
    const [xpLpStakeTokenAmount, setXpLpStakeTokenAmount] = useState('0');
    const [xpUnstakeTokenAmount, setXpUnstakeTokenAmount] = useState('0');
    const [xpLpUnstakeTokenAmount, setXpLpUnstakeTokenAmount] = useState('0');

    const [xpStakedTokenAmount, setXpStakedTokenAmount] = useState('0');
    const [xpLpStakedTokenAmount] = useState('0');

    const [stakingRewards, setStakingRewards] = useState('0');

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    const handleMaxStakeXp = () => {
        setXpStakeTokenAmount(xpTokenBalance);
    };

    const handleMaxStakeXpLp = () => {
        setXpLpStakeTokenAmount(xpLpTokenBalance);
    };

    const handleStakeXp = () => {
        const stake = async () => {
            try {
                if (xpStakeTokenAmount === '0') {
                    toast.error('Please enter a valid amount');
                    return;
                }
                const amount = toBigInt(xpStakeTokenAmount, currentNetworkConfig.baseAssetDecimals);
                const approvedAmount = await tokenService.allowance(
                    currentNetworkConfig,
                    address as `0x${string}`,
                    currentNetworkConfig.addresses.XP_TOKEN_ADDRESS,
                    currentNetworkConfig.addresses.XP_STAKING_ADDRESS as `0x${string}`,
                );
                if (checkRequiresApproval({ approvedAmount, amount })) {
                    await writeContractAsync({
                        address: currentNetworkConfig.addresses.XP_TOKEN_ADDRESS as `0x${string}`,
                        abi: TokenAbi,
                        functionName: 'approve',
                        args: [currentNetworkConfig.addresses.XP_STAKING_ADDRESS, MAX_UINT256],
                    });
                }

                await writeContractAsync({
                    abi: StakingAbi,
                    address: currentNetworkConfig.addresses.XP_STAKING_ADDRESS as `0x${string}`,
                    functionName: 'stake',
                    args: [amount],
                });

                toast.success('Staked XP');
            } catch (error) {
                console.error(error);

                if (error instanceof ContractFunctionExecutionError) {
                    toast.error(error.cause.shortMessage);
                } else {
                    toast.error('Transaction failed, check console for details');
                }
            }
        };
        stake();
    };

    useEffect(() => {
        const getBalances = async () => {
            if (address) {
                try {
                    const xpBal = await tokenService.balanceOf(
                        currentNetworkConfig,
                        currentNetworkConfig.addresses.XP_TOKEN_ADDRESS,
                        address,
                    );
                    const xpLpBal = await tokenService.balanceOf(
                        currentNetworkConfig,
                        currentNetworkConfig.addresses.XP_LP_ADDRESS,
                        address,
                    );

                    const stakingInfo = await stakingService.stakersInfos(
                        currentNetworkConfig,
                        address,
                    );

                    setStakingRewards(fromBigInt(stakingInfo.accumulatedRewardsPerToken, 18));
                    setXpStakedTokenAmount(fromBigInt(stakingInfo.tokensStaked, 18));
                    setXpTokenBalance(fromBigInt(xpBal, 18));
                    setXpLpTokenBalance(fromBigInt(xpLpBal, 18));
                } catch (error) {
                    console.log(error);
                }
            }
        };
        getBalances();
    }, [address, currentNetworkConfig, stakingService, tokenService]);

    const handleStakeXpLp = () => {
        const stake = async () => {
            try {
                if (xpLpStakeTokenAmount === '0') {
                    toast.error('Please enter a valid amount');
                    return;
                }

                const amount = toBigInt(
                    xpLpStakeTokenAmount,
                    currentNetworkConfig.baseAssetDecimals,
                );
                const approvedAmount = await tokenService.allowance(
                    currentNetworkConfig,
                    address as `0x${string}`,
                    currentNetworkConfig.addresses.XP_LP_ADDRESS,
                    currentNetworkConfig.addresses.HARDLOCK_ADDRESS as `0x${string}`,
                );
                if (checkRequiresApproval({ approvedAmount, amount })) {
                    await writeContractAsync({
                        address: currentNetworkConfig.addresses.XP_LP_ADDRESS as `0x${string}`,
                        abi: HardLockAbi,
                        functionName: 'approve',
                        args: [currentNetworkConfig.addresses.HARDLOCK_ADDRESS, MAX_UINT256],
                    });
                }

                await writeContractAsync({
                    abi: HardLockAbi,
                    address: currentNetworkConfig.addresses.HARDLOCK_ADDRESS as `0x${string}`,
                    functionName: 'stake',
                    args: [amount],
                });

                toast.success('Staked XP-ETH LP');
            } catch (error) {
                console.error(error);

                if (error instanceof ContractFunctionExecutionError) {
                    toast.error(error.cause.shortMessage);
                } else {
                    toast.error('Transaction failed, check console for details');
                }
            }
        };
        // stake();
    };

    const handleMaxUnstakeXp = () => {
        setXpUnstakeTokenAmount(xpStakedTokenAmount);
    };

    const handleMaxUnstakeXpLp = () => {
        setXpLpUnstakeTokenAmount(xpLpStakedTokenAmount);
    };

    const handleUnstakeXp = () => {
        const stake = async () => {
            try {
                const amount = toBigInt(
                    xpUnstakeTokenAmount,
                    currentNetworkConfig.baseAssetDecimals,
                );

                await writeContractAsync({
                    abi: StakingAbi,
                    address: currentNetworkConfig.addresses.XP_STAKING_ADDRESS as `0x${string}`,
                    functionName: 'unstake',
                    args: [amount],
                });

                toast.success('Unstaked XP');
            } catch (error) {
                console.error(error);

                if (error instanceof ContractFunctionExecutionError) {
                    toast.error(error.cause.shortMessage);
                } else {
                    toast.error('Transaction failed, check console for details');
                }
            }
        };
        stake();
    };

    const handleUnstakeXpLp = () => {
        const stake = async () => {
            try {
                const amount = toBigInt(
                    xpLpUnstakeTokenAmount,
                    currentNetworkConfig.baseAssetDecimals,
                );

                await writeContractAsync({
                    abi: StakingAbi,
                    address: currentNetworkConfig.addresses.XP_STAKING_ADDRESS as `0x${string}`,
                    functionName: 'unstake',
                    args: [amount],
                });

                toast.success('Unstaked XP-ETH LP');
            } catch (error) {
                console.error(error);

                if (error instanceof ContractFunctionExecutionError) {
                    toast.error(error.cause.shortMessage);
                } else {
                    toast.error('Transaction failed, check console for details');
                }
            }
        };
        stake();
    };

    const handleClaim = () => {
        const claim = async () => {
            try {
                const amount = toBigInt(stakingRewards, currentNetworkConfig.baseAssetDecimals);

                if (!amount) {
                    toast.error('No rewards to claim');
                    return;
                }

                await writeContractAsync({
                    abi: StakingAbi,
                    address: currentNetworkConfig.addresses.XP_STAKING_ADDRESS as `0x${string}`,
                    functionName: 'claim',
                });

                toast.success('Claimed rewards');
            } catch (error) {
                console.error(error);

                if (error instanceof ContractFunctionExecutionError) {
                    toast.error(error.cause.shortMessage);
                } else {
                    toast.error('Transaction failed, check console for details');
                }
            }
        };
        claim();
    };

    const handleClaimXpLpStakingRewards = () => {
        const claim = async () => {
            try {
                const amount = toBigInt(stakingRewards, currentNetworkConfig.baseAssetDecimals);

                if (!amount) {
                    toast.error('No rewards to claim');
                    return;
                }

                await writeContractAsync({
                    abi: StakingAbi,
                    address: currentNetworkConfig.addresses.HARDLOCK_ADDRESS as `0x${string}`,
                    functionName: 'claimShare',
                });

                toast.success('Claimed rewards');
            } catch (error) {
                console.error(error);

                if (error instanceof ContractFunctionExecutionError) {
                    toast.error(error.cause.shortMessage);
                } else {
                    toast.error('Transaction failed, check console for details');
                }
            }
        };
        claim();
    };

    return (
        <Box sx={{ p: 2 }}>
            <Box sx={{ pb: 2 }}>
                <Box
                    display="flex"
                    flexDirection="column"
                    sx={{ ml: 4, mr: 4, p: 2, border: '1px solid #d0d0bf' }}>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                        sx={{ mb: 1 }}>
                        <Typography sx={{ color: 'black' }}>Available XP</Typography>
                        <FormattedNumber
                            value={xpTokenBalance}
                            visibleDecimals={3}
                            sx={{ color: 'black' }}
                        />
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                        sx={{ mb: 1 }}>
                        <Typography sx={{ color: 'black' }}>Staked XP</Typography>
                        <FormattedNumber
                            value={xpStakedTokenAmount}
                            visibleDecimals={3}
                            sx={{ color: 'black' }}
                        />
                    </Box>

                    <Box
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                        sx={{ mb: 1 }}>
                        <Typography sx={{ color: 'black' }}>Available XP-ETH LP</Typography>
                        <FormattedNumber
                            value={xpLpTokenBalance}
                            visibleDecimals={3}
                            sx={{ color: 'black' }}
                        />
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                        sx={{ mb: 1 }}>
                        <Typography sx={{ color: 'black' }}>Staked XP-ETH LP</Typography>
                        <FormattedNumber
                            value={xpLpStakedTokenAmount}
                            visibleDecimals={3}
                            sx={{ color: 'black' }}
                        />
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                        sx={{ mb: 1 }}>
                        <Typography sx={{ color: 'black' }}>ETH Rewards</Typography>
                        <FormattedNumber
                            color="black"
                            value={stakingRewards}
                            visibleDecimals={4}
                        />
                    </Box>
                </Box>
            </Box>

            <Box sx={{ pb: 2 }}>
                <Tabs
                    value={activeTab}
                    onChange={handleChange}>
                    <Tab
                        label="Stake"
                        sx={tabSx}
                    />
                    <Tab
                        label="Unstake"
                        sx={tabSx}
                    />
                    <Tab
                        label="Claim"
                        sx={tabSx}
                    />
                </Tabs>

                <Box
                    sx={{
                        border: '1px solid #d0d0bf',
                    }}>
                    {activeTab === 0 && (
                        <Box
                            display="flex"
                            flexDirection="column"
                            sx={{
                                ml: 4,
                                mr: 4,
                                p: 1,
                            }}>
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="space-between"
                                width="100%"
                                sx={{ position: 'relative' }} // Enable positioning for the "Max" word
                            >
                                <Box>
                                    <Typography sx={{ color: 'black' }}>Stake XP</Typography>
                                </Box>
                                <Box sx={{ position: 'relative' }}>
                                    <AssetInput
                                        value={xpStakeTokenAmount}
                                        setAmount={setXpStakeTokenAmount}
                                    />
                                    <Typography
                                        sx={{
                                            position: 'absolute',
                                            bottom: 5,
                                            right: 10,
                                            color: 'blue',
                                            fontSize: '0.875rem',
                                            cursor: 'pointer',
                                        }}
                                        onClick={handleMaxStakeXp}>
                                        Max
                                    </Typography>
                                </Box>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        width: '100%',
                                        mt: 1, // Add some spacing at the top
                                    }}>
                                    <Button
                                        variant="windows"
                                        onClick={() => handleStakeXp()}>
                                        Stake
                                    </Button>
                                </Box>

                                <Box>
                                    <Typography sx={{ color: 'black' }}>Stake XP-ETH LP</Typography>
                                </Box>
                                <Box sx={{ position: 'relative' }}>
                                    <AssetInput
                                        value={xpLpStakeTokenAmount}
                                        setAmount={setXpLpStakeTokenAmount}
                                    />

                                    {/* Max word positioned in the bottom right */}
                                    <Typography
                                        sx={{
                                            position: 'absolute',
                                            bottom: 5,
                                            right: 10,
                                            color: 'blue',
                                            fontSize: '0.875rem',
                                            cursor: 'pointer',
                                        }}
                                        onClick={handleMaxStakeXpLp}>
                                        Max
                                    </Typography>
                                </Box>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        width: '100%',
                                        mt: 1,
                                    }}>
                                    <Button
                                        disabled={true}
                                        variant="windows"
                                        onClick={() => handleStakeXpLp()}>
                                        Stake
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    )}

                    {activeTab === 1 && (
                        <Box
                            display="flex"
                            flexDirection="column"
                            sx={{
                                ml: 4,
                                mr: 4,
                                p: 1,
                            }}>
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="space-between"
                                width="100%"
                                sx={{ position: 'relative' }} // Enable positioning for the "Max" word
                            >
                                <Box>
                                    <Typography sx={{ color: 'black' }}>Unstake XP</Typography>
                                    <Box
                                        display="flex"
                                        flexDirection="row">
                                        <Typography sx={{ color: 'black', pr: 1 }}>
                                            Unlock in{' '}
                                        </Typography>
                                        <Typography sx={{ color: 'red' }}>[tbd]</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ position: 'relative' }}>
                                    <AssetInput
                                        value={xpUnstakeTokenAmount}
                                        setAmount={setXpUnstakeTokenAmount}
                                    />

                                    <Typography
                                        sx={{
                                            position: 'absolute',
                                            bottom: 5,
                                            right: 10,
                                            color: 'blue',
                                            fontSize: '0.875rem',
                                            cursor: 'pointer',
                                        }}
                                        onClick={handleMaxUnstakeXp}>
                                        Max
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        width: '100%',
                                        mt: 2, // Add some spacing at the top
                                    }}>
                                    <Button
                                        variant="windows"
                                        onClick={() => handleUnstakeXp()}>
                                        Unstake
                                    </Button>
                                </Box>
                            </Box>

                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="space-between"
                                width="100%"
                                sx={{ position: 'relative' }}>
                                <Box>
                                    <Typography sx={{ color: 'black' }}>
                                        Unstake XP-ETH LP
                                    </Typography>
                                    <Box
                                        display="flex"
                                        flexDirection="row">
                                        <Typography sx={{ color: 'black', pr: 1 }}>
                                            Unlock in{' '}
                                        </Typography>
                                        <Typography sx={{ color: 'red' }}>[tbd]</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ position: 'relative' }}>
                                    <AssetInput
                                        value={xpLpUnstakeTokenAmount}
                                        setAmount={setXpLpUnstakeTokenAmount}
                                    />

                                    <Typography
                                        sx={{
                                            position: 'absolute',
                                            bottom: 5,
                                            right: 10,
                                            color: 'blue',
                                            fontSize: '0.875rem',
                                            cursor: 'pointer',
                                        }}
                                        onClick={handleMaxUnstakeXpLp}>
                                        Max
                                    </Typography>
                                </Box>
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    width: '100%',
                                    mt: 2,
                                }}>
                                <Button
                                    variant="windows"
                                    onClick={() => handleUnstakeXpLp()}>
                                    Unstake
                                </Button>
                            </Box>
                        </Box>
                    )}

                    {activeTab === 2 && (
                        <Box
                            display="flex"
                            flexDirection="column"
                            sx={{
                                ml: 3,
                                mr: 3,
                                p: 1,
                            }}>
                            <Box
                                display="flex"
                                alignItems="center"
                                gap="5px"
                                width="100%"
                                sx={{ mb: 1 }}>
                                <Typography sx={{ color: 'black' }}>
                                    Available ETH Rewards to Claim:
                                </Typography>
                                <FormattedNumber
                                    color="black"
                                    value={stakingRewards}
                                    visibleDecimals={4}
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    width: '100%',
                                }}>
                                <Button
                                    sx={{ ml: 2 }}
                                    variant="windows"
                                    onClick={() => handleClaim()}>
                                    {' '}
                                    Claim{' '}
                                </Button>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
};
export default Staking;
