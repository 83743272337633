import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

const UserClock = () => {
    const [time, setTime] = useState('');
    useEffect(() => {
        const updateTime = () => {
            const current = new Date();
            const hours = current.getHours();
            const minutes = current.getMinutes();
            const ampm = hours >= 12 ? 'PM' : 'AM';
            const formattedHours = hours % 12 || 12; // Convert 24-hour time to 12-hour format
            const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
            setTime(`${formattedHours}:${formattedMinutes} ${ampm}`);
        };

        updateTime(); // Update time immediately when component mounts
        const intervalId = setInterval(updateTime, 1000); // Update time every second

        return () => clearInterval(intervalId); // Clean up the interval on component unmount
    }, []);

    return <Typography sx={{ color: '#ffffff', marginRight: '5px' }}>{time}</Typography>;
};
export default UserClock;
