import { ArrowDownward } from '@mui/icons-material';
import { Box, Button, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ContractFunctionExecutionError } from 'viem';
import { useAccount, useWriteContract } from 'wagmi';
import TokenSelectButton from '../../components/buttons/TokenSelectButton';
import TokenInput from '../../components/inputs/TokenInput';
import SlippageSettingsPopover from '../../components/modals/SlippageSettingsPopover';
import { useNormalizedPair } from '../../hooks/useNormalizedPair';
import { usePriceImpact } from '../../hooks/usePriceImpact';
import { useReservesData } from '../../hooks/useReservesData';
import { useTokenData } from '../../hooks/useTokenData';
import { useSharedDependencies } from '../../providers/SharedDependencyProvider';
import { TokenAbi } from '../../shared/abi/Token';
import { VistaRouterAbi } from '../../shared/abi/VistaRouter';
import { useRootStore } from '../../store/root';
import { Token } from '../../types/Token';
import { MAX_UINT256, toBigInt } from '../../utils/tokenUtils';
import { checkRequiresApproval } from '../../utils/transactionUtils';

const Swap: React.FC = () => {
    const { isConnected, address } = useAccount();
    const { writeContractAsync } = useWriteContract();
    const { uiDataService, tokenService } = useSharedDependencies();
    const [currentNetworkConfig] = useRootStore((store) => [store.currentNetworkConfig]);
    const [slippageValue] = useRootStore((store) => [store.slippageValue]);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [token0ContractAddress, setToken0ContractAddress] = useState<string | undefined>();
    const [token0, setToken0] = useState<Token | undefined>();
    const [token0Amount, settoken0Amount] = useState('');
    const [token1ContractAddress, setToken1ContractAddress] = useState<string | undefined>();
    const [token1, setToken1] = useState<Token | undefined>();
    const [token1Amount, settoken1Amount] = useState('');
    const [balanceToken0, setBalanceToken0] = useState('');
    const [autoSlippage, setAutoSlippage] = useState(true);

    const { normalizedToken0, normalizedToken1 } = useNormalizedPair(token0, token1);

    const token0Data = useTokenData(token0ContractAddress || '');
    useEffect(() => {
        if (token0Data.data) {
            setToken0(token0Data.data);
        }
    }, [token0Data.data]);

    const token1Data = useTokenData(token1ContractAddress || '');
    useEffect(() => {
        if (token1Data.data) {
            setToken1(token1Data.data);
        }
    }, [token1Data.data]);

    const handleSettingsClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openPopover = Boolean(anchorEl);

    const handleSwitch = () => {
        const newToken0 = token1;
        const newToken1 = token0;
        const newToken0Address = token1ContractAddress;
        const newToken1Address = token0ContractAddress;
        setToken0(newToken0);
        setToken1(newToken1);
        setToken0ContractAddress(newToken0Address);
        setToken1ContractAddress(newToken1Address);
        settoken0Amount('');
        settoken1Amount('');
    };

    const { reserves, error: reservesError } = useReservesData({
        token0: normalizedToken0,
        token1: normalizedToken1,
    });

    const { expectedOutput, priceImpact } = usePriceImpact({
        amountIn: token0Amount,
        token0: normalizedToken0,
        token1: normalizedToken1,
        reserves,
    });

    useEffect(() => {
        if (!token0Amount) {
            settoken1Amount('');
        } else {
            settoken1Amount(expectedOutput);
        }
    }, [expectedOutput, token0Amount]);

    const handleSwap = () => {
        const swap = async () => {
            if (!isConnected || !address) {
                toast.error('Please connect your wallet');
                return;
            }

            if (reservesError) {
                toast.error('Liquidity is not available for the selected token pair.');
                return;
            }

            if (!normalizedToken0 || !normalizedToken1) {
                toast.error('Please select tokens');
                return;
            }

            if (!token0Amount || !token1Amount) {
                toast.error('Please enter the amount to swap');
                return;
            }

            if (balanceToken0 === '0' || Number(token0Amount) > Number(balanceToken0)) {
                toast.error('Insufficient balance');
                return;
            }

            const token0AmountBigInt = toBigInt(token0Amount, normalizedToken0.decimals);
            const token1AmountBigInt = toBigInt(token1Amount, normalizedToken1.decimals);

            const deadline = Math.floor(Date.now() / 1000) + 60 * 20;

            const pool = await uiDataService.GetPoolDataHumanized(
                currentNetworkConfig,
                normalizedToken0.address,
                normalizedToken1.address,
            );

            let finalSlippageValue = slippageValue;

            if (normalizedToken0.isBaseToken) {
                const token0AmountBigIntWithFee = token0AmountBigInt + pool.pairBuyLpFeeInEth;
                try {
                    if (autoSlippage) {
                        finalSlippageValue = Number(pool.pairBuyLpFee);
                    }

                    const token1Min =
                        token1AmountBigInt -
                        (token1AmountBigInt * BigInt(finalSlippageValue)) / 100n;

                    await writeContractAsync({
                        address: currentNetworkConfig.addresses
                            .NATIVE_ROUTER_ADDRESS as `0x${string}`,
                        abi: VistaRouterAbi,
                        functionName: 'swapExactETHForTokensSupportingFeeOnTransferTokens',
                        args: [
                            token1Min,
                            [normalizedToken0.address, normalizedToken1.address],
                            address,
                            deadline,
                        ],
                        value: token0AmountBigIntWithFee,
                    });

                    toast.success('Swap successful');
                } catch (error) {
                    console.error(error);

                    if (error instanceof ContractFunctionExecutionError) {
                        toast.error(error.cause.shortMessage);
                    } else {
                        toast.error('Transaction failed, check console for details');
                    }
                }
            } else {
                try {
                    const allowance = await tokenService.allowance(
                        currentNetworkConfig,
                        address,
                        normalizedToken0.address,
                        currentNetworkConfig.addresses.NATIVE_ROUTER_ADDRESS,
                    );

                    const approvalRequired = checkRequiresApproval({
                        approvedAmount: allowance,
                        amount: token0AmountBigInt,
                    });

                    if (approvalRequired) {
                        await writeContractAsync({
                            address: normalizedToken0.address as `0x${string}`,
                            abi: TokenAbi,
                            functionName: 'approve',
                            args: [
                                currentNetworkConfig.addresses.NATIVE_ROUTER_ADDRESS,
                                MAX_UINT256,
                            ],
                        });

                        toast.info('Approval in progress, please wait');

                        await new Promise((resolve) => setTimeout(resolve, 5000));
                    }

                    if (autoSlippage) {
                        finalSlippageValue = Number(pool.pairSellLpFee);
                    }

                    const token1Min =
                        token1AmountBigInt -
                        (token1AmountBigInt * BigInt(finalSlippageValue)) / 100n;

                    await writeContractAsync({
                        address: currentNetworkConfig.addresses
                            .NATIVE_ROUTER_ADDRESS as `0x${string}`,
                        abi: VistaRouterAbi,
                        functionName: 'swapExactTokensForETHSupportingFeeOnTransferTokens',
                        args: [
                            token0AmountBigInt,
                            token1Min > 0 ? token1Min : 0,
                            [normalizedToken0.address, normalizedToken1.address],
                            address,
                            deadline,
                        ],
                        value: pool.pairSellLpFeeInEth,
                    });

                    toast.success('Swap successful');
                } catch (error) {
                    console.error(error);

                    if (error instanceof ContractFunctionExecutionError) {
                        toast.error(error.cause.shortMessage);
                    } else {
                        toast.error('Transaction failed, check console for details');
                    }
                }
            }
        };
        swap();
    };

    return (
        <Box
            sx={{ pt: { xs: 4, sm: 2 }, position: 'relative' }}
            display="flex"
            flexDirection="column">
            <Box
                sx={{
                    position: 'absolute',
                    top: { xs: 2, sm: 2 },
                    right: { xs: 2, sm: 2 },
                    display: 'flex',
                    alignItems: 'center',
                }}>
                <IconButton
                    sx={{
                        height: '40px',
                        width: '40px',
                        padding: 0, // Ensures icon uses the full button area
                        borderRadius: '8px',
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.1)', // Optional: Add hover effect
                        },
                        zIndex: 50,
                    }}
                    onClick={handleSettingsClick}>
                    <img
                        src="/images/icons/ui/DLL.png"
                        alt="DLL"
                        height="75%"
                        width="75%"
                    />
                </IconButton>
                <Typography sx={{ color: 'black' }}>Slippage</Typography>
                {autoSlippage ? (
                    <Typography sx={{ color: 'black', pl: 0.8 }}>Auto</Typography>
                ) : (
                    <Typography sx={{ color: 'black', pl: 0.8 }}>{slippageValue}%</Typography>
                )}
            </Box>
            <Box
                width="100%"
                display="flex"
                flexDirection="row"
                alignItems="center">
                <Box flexGrow={2}>
                    <TokenInput
                        title="Sell"
                        token={token0}
                        tokenAmount={token0Amount}
                        setTokenAmount={settoken0Amount}
                        setBalanceToken={setBalanceToken0}
                    />
                </Box>
                <Box>
                    <TokenSelectButton
                        token={token0}
                        onAddressChange={setToken0ContractAddress}
                    />
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <ArrowDownward
                    sx={{ color: 'gray', cursor: 'pointer' }}
                    onClick={handleSwitch}
                />
            </Box>
            <Box
                width="100%"
                display="flex"
                flexDirection="row"
                alignItems="center">
                <Box flexGrow={2}>
                    <TokenInput
                        title="Buy"
                        token={token1}
                        tokenAmount={token1Amount}
                        priceImpact={priceImpact}
                        setTokenAmount={settoken1Amount}
                    />
                </Box>
                <Box>
                    <TokenSelectButton
                        token={token1}
                        onAddressChange={setToken1ContractAddress}
                    />
                </Box>
            </Box>
            {reservesError && (
                <Typography
                    color="error"
                    sx={{ mt: 2 }}>
                    Liquidity is not available for the selected token pair.
                </Typography>
            )}
            <Button
                sx={{ mt: 3 }}
                disabled={!token0 || !token1 || !token0Amount || !token1Amount}
                onClick={() => handleSwap()}
                fullWidth
                variant="windows">
                Swap {token0?.symbol} for {token1?.symbol}
            </Button>
            <SlippageSettingsPopover
                open={openPopover}
                handleClose={handleClose}
                auto={autoSlippage}
                setAuto={setAutoSlippage}
            />
        </Box>
    );
};

export default Swap;
