export const TokenAbi = [
    {
        type: 'constructor',
        inputs: [
            { name: '_tokenFactory', type: 'address', internalType: 'address' },
            { name: '_router', type: 'address', internalType: 'address' },
            { name: '_hardlock', type: 'address', internalType: 'address' },
        ],
        stateMutability: 'nonpayable',
    },
    { type: 'receive', stateMutability: 'payable' },
    {
        type: 'function',
        name: 'MAX_TX_AMOUNT',
        inputs: [],
        outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'MAX_WALLET_AMOUNT',
        inputs: [],
        outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'WETH',
        inputs: [],
        outputs: [{ name: '', type: 'address', internalType: 'address' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'allowance',
        inputs: [
            { name: 'owner', type: 'address', internalType: 'address' },
            { name: 'spender', type: 'address', internalType: 'address' },
        ],
        outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'approve',
        inputs: [
            { name: 'spender', type: 'address', internalType: 'address' },
            { name: 'amount', type: 'uint256', internalType: 'uint256' },
        ],
        outputs: [{ name: '', type: 'bool', internalType: 'bool' }],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'balanceOf',
        inputs: [{ name: 'account', type: 'address', internalType: 'address' }],
        outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'burn',
        inputs: [{ name: 'value', type: 'uint256', internalType: 'uint256' }],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'claimProtocolShare',
        inputs: [{ name: '_isLocked', type: 'bool', internalType: 'bool' }],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'decimals',
        inputs: [],
        outputs: [{ name: '', type: 'uint8', internalType: 'uint8' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'decreaseAllowance',
        inputs: [
            { name: 'spender', type: 'address', internalType: 'address' },
            { name: 'subtractedValue', type: 'uint256', internalType: 'uint256' },
        ],
        outputs: [{ name: '', type: 'bool', internalType: 'bool' }],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'deployer',
        inputs: [],
        outputs: [{ name: '', type: 'address', internalType: 'address payable' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'description',
        inputs: [],
        outputs: [{ name: '', type: 'string', internalType: 'string' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'feesTier',
        inputs: [],
        outputs: [{ name: '', type: 'uint8', internalType: 'enum ITokenLogic.FeesTier' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'hardlock',
        inputs: [],
        outputs: [{ name: '', type: 'address', internalType: 'contract IHardlock' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'increaseAllowance',
        inputs: [
            { name: 'spender', type: 'address', internalType: 'address' },
            { name: 'addedValue', type: 'uint256', internalType: 'uint256' },
        ],
        outputs: [{ name: '', type: 'bool', internalType: 'bool' }],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'initialize',
        inputs: [
            { name: '_deployer', type: 'address', internalType: 'address' },
            { name: '_name', type: 'string', internalType: 'string' },
            { name: '_symbol', type: 'string', internalType: 'string' },
            { name: 'lpBuyFee', type: 'uint8', internalType: 'uint8' },
            { name: 'lpSellFee', type: 'uint8', internalType: 'uint8' },
            { name: 'protocolBuyFee', type: 'uint8', internalType: 'uint8' },
            { name: 'protocolSellFee', type: 'uint8', internalType: 'uint8' },
            { name: 'toVitalik', type: 'uint256', internalType: 'uint256' },
            { name: 'vitalikAddress', type: 'address', internalType: 'address' },
        ],
        outputs: [],
        stateMutability: 'payable',
    },
    {
        type: 'function',
        name: 'launchTimestamp',
        inputs: [],
        outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'limitsActive',
        inputs: [],
        outputs: [{ name: '', type: 'bool', internalType: 'bool' }],
        stateMutability: 'view',
    },
    { type: 'function', name: 'lock', inputs: [], outputs: [], stateMutability: 'nonpayable' },
    {
        type: 'function',
        name: 'logo',
        inputs: [],
        outputs: [{ name: '', type: 'string', internalType: 'string' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'name',
        inputs: [],
        outputs: [{ name: '', type: 'string', internalType: 'string' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'setSocials',
        inputs: [
            { name: '_telegram', type: 'string', internalType: 'string' },
            { name: '_website', type: 'string', internalType: 'string' },
            { name: '_twitter', type: 'string', internalType: 'string' },
            { name: '_logo', type: 'string', internalType: 'string' },
            { name: '_description', type: 'string', internalType: 'string' },
        ],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'symbol',
        inputs: [],
        outputs: [{ name: '', type: 'string', internalType: 'string' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'telegram',
        inputs: [],
        outputs: [{ name: '', type: 'string', internalType: 'string' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'tokenFactory',
        inputs: [],
        outputs: [{ name: '', type: 'address', internalType: 'address' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'totalSupply',
        inputs: [],
        outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'transfer',
        inputs: [
            { name: 'recipient', type: 'address', internalType: 'address' },
            { name: 'amount', type: 'uint256', internalType: 'uint256' },
        ],
        outputs: [{ name: '', type: 'bool', internalType: 'bool' }],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'transferFrom',
        inputs: [
            { name: 'sender', type: 'address', internalType: 'address' },
            { name: 'recipient', type: 'address', internalType: 'address' },
            { name: 'amount', type: 'uint256', internalType: 'uint256' },
        ],
        outputs: [{ name: '', type: 'bool', internalType: 'bool' }],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'twitter',
        inputs: [],
        outputs: [{ name: '', type: 'string', internalType: 'string' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'uniswapFactory',
        inputs: [],
        outputs: [{ name: '', type: 'address', internalType: 'contract EtherVistaFactory' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'uniswapPair',
        inputs: [],
        outputs: [{ name: '', type: 'address', internalType: 'address' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'uniswapRouter',
        inputs: [],
        outputs: [{ name: '', type: 'address', internalType: 'contract EtherVistaRouter' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'website',
        inputs: [],
        outputs: [{ name: '', type: 'string', internalType: 'string' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'withdrawLiquidity',
        inputs: [],
        outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'nonpayable',
    },
    {
        type: 'event',
        name: 'Approval',
        inputs: [
            { name: 'owner', type: 'address', indexed: true, internalType: 'address' },
            { name: 'spender', type: 'address', indexed: true, internalType: 'address' },
            { name: 'value', type: 'uint256', indexed: false, internalType: 'uint256' },
        ],
        anonymous: false,
    },
    {
        type: 'event',
        name: 'Transfer',
        inputs: [
            { name: 'from', type: 'address', indexed: true, internalType: 'address' },
            { name: 'to', type: 'address', indexed: true, internalType: 'address' },
            { name: 'value', type: 'uint256', indexed: false, internalType: 'uint256' },
        ],
        anonymous: false,
    },
];
