import React from 'react';
import { useParams } from 'react-router-dom';
import ContentContainer from '../../components/containers/ContentContainer';
import TokenInfoContainer from '../../components/containers/TokenInfoContainer';
import MainLayout from '../../layout/MainLayout';
import { PageWithLayout } from '../../types';

const Token: PageWithLayout = () => {
    const { contractAddress } = useParams<{ contractAddress: string }>();

    return (
        <>
            <ContentContainer>
                {contractAddress && <TokenInfoContainer contractAddress={contractAddress} />}
            </ContentContainer>
        </>
    );
};

Token.getLayout = (page: React.ReactNode) => <MainLayout>{page}</MainLayout>;

export default Token;
