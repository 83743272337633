import { Box, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import RemoveLiquidityUserPanel from './RemoveLiquidityUserPanel';
import RemoveLiquidityDeployerPanel from './RemoveLiquidityDeployerPanel';
import { TabStyle } from '../../styles/TabStyle';

const RemoveLiquidityPanel = () => {
    const [activeTab, setActiveTab] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    return (
        <>
            <Box sx={{ pb: 2 }}>
                <Tabs
                    value={activeTab}
                    onChange={handleChange}>
                    <Tab
                        label="Remove User Liquidity"
                        sx={TabStyle}
                    />
                    <Tab
                        label="Remove Deployer Liquidity"
                        sx={TabStyle}
                    />
                </Tabs>

                <Box
                    sx={{
                        border: '1px solid #d0d0bf',
                    }}>
                    {activeTab === 0 && <RemoveLiquidityUserPanel />}

                    {activeTab === 1 && <RemoveLiquidityDeployerPanel />}
                </Box>
            </Box>
        </>
    );
};
export default RemoveLiquidityPanel;
