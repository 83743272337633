import { createPublicClient, http, PublicClient } from 'viem';
import { networkConfigs as _networkConfigs, BaseNetworkConfig } from '../config/networkConfigs';
import {
    ExplorerLinkBuilderConfig,
    ExplorerLinkBuilderProps,
    NetworkConfig,
} from '../types/NetworkConfig';
import { Network } from '../types/Web3/Network';
import { Token } from '../types/Token';

/**
 * Network Utils
 */
export const PROD_ENV = !process.env.REACT_APP_MODE || process.env.REACT_APP_MODE === 'prod';
export const ENABLE_TESTNET =
    PROD_ENV && global?.window?.localStorage.getItem('testnetsEnabled') === 'true';

export const PROTOCOL_BASE_URL = process.env.REACT_APP_XP_FUN_API_URL
    ? process.env.REACT_APP_XP_FUN_API_URL
    : '';

/**
 * Generates network configs based on networkConfigs & testnet settings.
 */
export const getNetworkConfigKey = (currentNetworkConfig: NetworkConfig): Network => {
    const networkKey = Object.keys(networkConfigs).find((key) => {
        const config = networkConfigs[key as Network];
        return JSON.stringify(config) === JSON.stringify(currentNetworkConfig);
    });

    if (!networkKey) {
        throw new Error('No matching network configuration found.');
    }

    return networkKey as Network;
};

export const networkConfigs = Object.keys(_networkConfigs).reduce(
    (acc, value) => {
        const key = value as keyof typeof _networkConfigs;
        acc[key] = _networkConfigs[key];
        // Additional logic can be added here if needed
        return acc;
    },
    {} as { [key in Network]: BaseNetworkConfig },
);

export function getNetworkConfig(networkKey: Network): NetworkConfig {
    const config = networkConfigs[networkKey];

    return {
        ...config,
        explorerLinkBuilder: linkBuilder({ baseUrl: config.explorerLink }),
    };
}

export function getProtocolBaseApi(): string {
    console.log(PROTOCOL_BASE_URL);
    return PROTOCOL_BASE_URL;
}

const providers: { [network: string]: PublicClient } = {};

export const getProvider = (network: Network): PublicClient => {
    if (!providers[network]) {
        const config = getNetworkConfig(network);
        const chainProviders: string[] = [];
        if (config.privateJsonRPCUrl) {
            chainProviders.push(config.privateJsonRPCUrl);
        }
        if (config.publicJsonRPCUrl.length) {
            config.publicJsonRPCUrl.map((rpc) => chainProviders.push(rpc));
        }
        if (!chainProviders.length) {
            throw new Error(`${network} has no jsonRPCUrl configured`);
        }

        providers[network] = createPublicClient({
            chain: config.chain,
            transport: http(chainProviders[0]),
        });
        //   if (chainProviders.length === 1) {
        //     providers[chainId] = new StaticJsonRpcProvider(chainProviders[0], chainId);
        //   } else {
        //     providers[chainId] = new RotationProvider(chainProviders, chainId);
        //   }
    }
    return providers[network];
};

//will filter here
export const availableNetworks: { [key: string]: BaseNetworkConfig } = Object.fromEntries(
    Object.entries(_networkConfigs).filter(([key, config]) => config.isAvailable),
);

/** Private */
const linkBuilder =
    ({
        baseUrl,
        addressPrefix = 'address',
        txPrefix = 'transactions',
    }: ExplorerLinkBuilderConfig) =>
    ({ tx, address }: ExplorerLinkBuilderProps): string => {
        if (tx) {
            return `${baseUrl}/${txPrefix}/${tx}`;
        }
        if (address) {
            return `${baseUrl}/${addressPrefix}/${address}`;
        }
        return baseUrl;
    };

export function hexToAscii(_hex: string): string {
    const hex = _hex.toString();
    let str = '';
    for (let n = 0; n < hex.length; n += 2) {
        str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
    }
    return str;
}

export function NormalizePair(currentNetworkConfig: NetworkConfig, token0: Token, token1: Token) {
    const isToken0Eth = token0.address === '0x0';
    const isToken1Eth = token1.address === '0x0';

    let normalizedToken0, normalizedToken1;

    if (isToken0Eth) {
        normalizedToken0 = {
            ...token0,
            address: currentNetworkConfig.addresses.BASE_ASSET_ADDRESS,
        };
        normalizedToken1 = token1;
    } else if (isToken1Eth) {
        normalizedToken0 = {
            ...token1,
            address: currentNetworkConfig.addresses.BASE_ASSET_ADDRESS,
        };
        normalizedToken1 = token0;
    } else {
        normalizedToken0 = token0;
        normalizedToken1 = token1;
    }

    return { normalizedToken0, normalizedToken1 };
}

// const getWrappedTokenAddress = (address: string, networkConfig: NetworkConfig) => {
//     return address === '0x0' ? networkConfig.addresses.BASE_ASSET_ADDRESS : address;
// };
