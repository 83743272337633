import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useAccount, useDisconnect } from 'wagmi';
import { useModalContext } from '../../hooks/useModalContext';

const HeaderConnectWalletButton = () => {
    const { isConnected } = useAccount();
    const { disconnect } = useDisconnect();
    const { openWalletConnect } = useModalContext();

    const handleClick = () => {
        if (isConnected) {
            disconnect();
        } else {
            openWalletConnect();
        }
    };

    return (
        <Box
            sx={{
                backgroundColor: '#00a300',
                color: 'white',
                height: 40,
                overflow: 'hidden',
                alignContent: 'center',
                boxShadow:
                    'inset 2px 2px 5px rgba(255, 255, 255, 0.6), inset -2px -2px 5px rgba(0, 0, 0, 0.5)',
            }}>
            <Box sx={{ right: 0 }}>
                <Button onClick={handleClick}>
                    <img
                        src="/images/xp_logo.png"
                        alt="xp logo"
                        height={30}
                    />
                    <Typography sx={{ pl: 1, color: 'white' }}>
                        {isConnected ? 'Disconnect' : 'Connect'}
                    </Typography>
                </Button>
            </Box>
        </Box>
    );
};

export default HeaderConnectWalletButton;
