import { StateCreator } from 'zustand';
import { RootStore } from './root';

export type Event = {
    eventHash: string;
};

export type EventSlice = {
    events: Event[];
    addEvent: (event: Event) => void;
};

export const createEventSlice: StateCreator<
    RootStore,
    [['zustand/subscribeWithSelector', never], ['zustand/devtools', never]],
    [],
    EventSlice
> = (set) => {
    return {
        events: [],
        addEvent: (event) =>
            set((state) => ({
                events: [...state.events, event],
            })),
    };
};
