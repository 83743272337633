import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Token } from '../../types/Token';
import TokenSelectModal from '../modals/TokenSelectModal';

interface TokenSelectButtonProps {
    token?: Token;
    onAddressChange?: (contractAddress: string) => void;
    disabled?: boolean;
}

const TokenSelectButton = ({ token, onAddressChange, disabled }: TokenSelectButtonProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Modal open/close handlers
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleSelectToken = (selectedContractAddress: string) => {
        if (onAddressChange) onAddressChange(selectedContractAddress);
        handleCloseModal();
    };

    return (
        <>
            <Button
                variant="windows"
                onClick={handleOpenModal}
                sx={{
                    backgroundColor: '#6a1b9a',
                    display: 'inline-flex',
                    textTransform: 'none',
                    padding: '6px 40px',
                    height: '30px',
                    width: 'auto',
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                }}
                disabled={disabled}>
                {token ? (
                    <Typography>{token.symbol}</Typography>
                ) : (
                    <Typography
                        variant="main16"
                        sx={{ lineHeight: 1, display: 'flex', alignItems: 'center' }}>
                        Select Token
                    </Typography>
                )}
                <ArrowDropDownIcon />
            </Button>

            {/* Token selection modal */}
            <TokenSelectModal
                open={isModalOpen}
                onClose={handleCloseModal}
                onSelectToken={handleSelectToken}
            />
        </>
    );
};

export default TokenSelectButton;
