import { PublicClient } from 'viem';
import { VistaRouterAbi } from '../shared/abi/VistaRouter';
import { NetworkConfig } from '../types/NetworkConfig';
import { Network } from '../types/Web3/Network';

export class RouterService {
    constructor(private readonly getProvider: (network: Network) => PublicClient) {}

    public async usdcToEth(
        currentNetworkConfig: NetworkConfig,
        usdcAmount: number,
    ): Promise<bigint> {
        const contract = {
            address: currentNetworkConfig.addresses.NATIVE_ROUTER_ADDRESS as `0x${string}`,
            abi: VistaRouterAbi,
        } as const;

        const client = this.getProvider(currentNetworkConfig.network);

        const result = await client.readContract({
            ...contract,
            functionName: 'usdcToEth',
            args: [usdcAmount],
        });

        const data = result as bigint;

        return data;
    }

    public async getAmountsOut(
        currentNetworkConfig: NetworkConfig,
        amount: bigint,
        path: string[],
    ): Promise<bigint> {
        const contract = {
            address: currentNetworkConfig.addresses.NATIVE_ROUTER_ADDRESS as `0x${string}`,
            abi: VistaRouterAbi,
        } as const;

        const client = this.getProvider(currentNetworkConfig.network);

        const result = await client.readContract({
            ...contract,
            functionName: 'getAmountsOut',
            args: [amount, path],
        });

        const data = result as bigint[];

        return data[1];
    }

    public async getAmountsIn(
        currentNetworkConfig: NetworkConfig,
        amount: bigint,
        path: string[],
    ): Promise<bigint> {
        const contract = {
            address: currentNetworkConfig.addresses.NATIVE_ROUTER_ADDRESS as `0x${string}`,
            abi: VistaRouterAbi,
        } as const;

        const client = this.getProvider(currentNetworkConfig.network);

        const result = await client.readContract({
            ...contract,
            functionName: 'getAmountsIn',
            args: [amount, path],
        });

        const data = result as bigint[];

        return data[1];
    }

    public async quote(
        currentNetworkConfig: NetworkConfig,
        amountA: bigint,
        reserveA: bigint,
        reserveB: bigint,
    ): Promise<bigint> {
        const contract = {
            address: currentNetworkConfig.addresses.NATIVE_ROUTER_ADDRESS as `0x${string}`,
            abi: VistaRouterAbi,
        } as const;

        const client = this.getProvider(currentNetworkConfig.network);

        const result = await client.readContract({
            ...contract,
            functionName: 'quote',
            args: [amountA, reserveA, reserveB],
        });

        const data = result as bigint;

        return data;
    }
}
