export const StakingAbi = [
    {
        type: 'constructor',
        inputs: [{ name: '_taxFarmingToken', type: 'address', internalType: 'address' }],
        stateMutability: 'nonpayable',
    },
    { type: 'receive', stateMutability: 'payable' },
    { type: 'function', name: 'claim', inputs: [], outputs: [], stateMutability: 'nonpayable' },
    {
        type: 'function',
        name: 'getUserRewards',
        inputs: [{ name: 'user', type: 'address', internalType: 'address' }],
        outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'lastClaimTime',
        inputs: [{ name: '', type: 'address', internalType: 'address' }],
        outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'lastTx',
        inputs: [{ name: '', type: 'address', internalType: 'address' }],
        outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'stake',
        inputs: [{ name: 'amount', type: 'uint256', internalType: 'uint256' }],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'stakersInfos',
        inputs: [{ name: '', type: 'address', internalType: 'address' }],
        outputs: [
            { name: 'tokensStaked', type: 'uint256', internalType: 'uint256' },
            { name: 'accumulatedRewardsPerToken', type: 'uint256', internalType: 'uint256' },
        ],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'taxFarmingToken',
        inputs: [],
        outputs: [{ name: '', type: 'address', internalType: 'contract IERC20' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'totalRewards',
        inputs: [],
        outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'totalStaked',
        inputs: [],
        outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'unstake',
        inputs: [{ name: 'amount', type: 'uint256', internalType: 'uint256' }],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'event',
        name: 'TokenStaked',
        inputs: [
            { name: 'user', type: 'address', indexed: true, internalType: 'address' },
            { name: 'amount', type: 'uint256', indexed: false, internalType: 'uint256' },
            {
                name: 'accumulatedRewardsPerToken',
                type: 'uint256',
                indexed: false,
                internalType: 'uint256',
            },
        ],
        anonymous: false,
    },
    {
        type: 'event',
        name: 'TokenUnstaked',
        inputs: [
            { name: 'user', type: 'address', indexed: true, internalType: 'address' },
            { name: 'amount', type: 'uint256', indexed: false, internalType: 'uint256' },
            {
                name: 'accumulatedRewardsPerToken',
                type: 'uint256',
                indexed: false,
                internalType: 'uint256',
            },
        ],
        anonymous: false,
    },
];
