import { Box, Link, Typography, styled } from '@mui/material';
import React, { useState } from 'react';
import { useAccount } from 'wagmi';
import UserClock from '../components/UserClock';

interface StyledLinkProps {
    onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

styled(Link)<StyledLinkProps>(({ theme }) => ({
    color: theme.palette.text.primary,
    '&:hover': {
        color: theme.palette.text.primary,
    },
    display: 'flex',
    alignItems: 'center',
}));

export function AppFooter() {
    const { isConnected } = useAccount();
    const [clippy] = useState(false);
    return (
        <>
            {clippy && (
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: '50px',
                        right: '10px',
                        padding: '10px',
                        borderRadius: '8px',
                        boxShadow: '2px 2px 10px rgba(0,0,0,0.3)',
                        zIndex: 1000, // Ensure it sits above other elements
                    }}>
                    <Typography sx={{ color: 'black' }}>Future Clippy</Typography>
                </Box>
            )}

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: '40px',
                    backgroundColor: '#0063b1',
                    padding: '0 10px',
                    boxShadow:
                        'inset 2px 2px 5px rgba(255, 255, 255, 0.6), inset -2px -2px 5px rgba(0, 0, 0, 0.5)',
                }}>
                {/* Start Button Section */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                    {/* <MenuDrawer /> */}
                    <Box>
                        <Typography sx={{ color: 'white' }}>Copyright 2024 XP.FUN Team</Typography>
                    </Box>
                </Box>

                {/* Clock Section */}
                <Box
                    sx={{
                        mr: -1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '0 10px',
                        backgroundColor: '#0082d9',
                        borderRadius: '8px',
                        height: '100%',
                        boxShadow:
                            'inset 2px 2px 5px rgba(255, 255, 255, 0.6), inset -2px -2px 5px rgba(0, 0, 0, 0.5)',
                    }}>
                    <Box sx={{ pt: 1 }}>
                        {isConnected && (
                            <img
                                src="/images/icons/ui/NetworkConnection.png"
                                alt="Network Connection"
                                width={25}
                            />
                        )}
                    </Box>
                    <UserClock />
                </Box>
            </Box>
        </>
    );
}
