import { BigNumber } from 'bignumber.js';
import { useCallback, useEffect, useState } from 'react';
import { useSharedDependencies } from '../providers/SharedDependencyProvider';
import { useRootStore } from '../store/root';
import { Token } from '../types/Token';
import { fromBigInt, toBigInt } from '../utils/tokenUtils';
import { usePoolData } from './usePoolData';
import { useToken } from './useWrappedToken';

interface UsePriceImpactParams {
    amountIn: string;
    token0: Token | undefined;
    token1: Token | undefined;
    reserves: { reserve0: BigNumber; reserve1: BigNumber } | null;
}

interface UsePriceImpactResult {
    expectedOutput: string;
    priceImpact: number | null;
    loading: boolean;
    error: string | null;
    refetch: () => void;
}

export const usePriceImpact = ({
    amountIn,
    token0,
    token1,
}: UsePriceImpactParams): UsePriceImpactResult => {
    const currentNetworkConfig = useRootStore((store) => store.currentNetworkConfig);
    const [expectedOutput, setExpectedOutput] = useState<string>('0');
    const [priceImpact, setPriceImpact] = useState<number | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const { routerService } = useSharedDependencies();

    useToken(token0, token1);

    const {
        pairData,
        isLoading,
        error: fetchPoolDataError,
    } = usePoolData({ token0Address: token0?.address, token1Address: token1?.address });

    const getData = useCallback(async () => {
        if (
            !token0 ||
            !token1 ||
            !amountIn ||
            isNaN(parseFloat(amountIn)) ||
            parseFloat(amountIn) === 0
        ) {
            setExpectedOutput('0');
            setPriceImpact(null);
            setLoading(false);
            return;
        }

        if (isLoading || fetchPoolDataError || !pairData) {
            setLoading(false);
            setError(fetchPoolDataError || 'Error fetching reserves data');
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const amountOut = await routerService.getAmountsOut(
                currentNetworkConfig,
                toBigInt(amountIn, token0.decimals),
                [token0.address, token1.address],
            );
            const expectedOutputValue = fromBigInt(amountOut, token1.decimals).toString();
            setExpectedOutput(expectedOutputValue);

            const reserve0 = pairData.reserves.token0Reserve;
            const reserve1 = pairData.reserves.token1Reserve;

            const quote = await routerService.quote(
                currentNetworkConfig,
                toBigInt(amountIn, token1.decimals),
                reserve1,
                reserve0,
            );
            const quoteBN = new BigNumber(quote.toString());
            const amountOutBn = new BigNumber(amountOut.toString());
            const ratio = amountOutBn.dividedBy(quoteBN);
            const priceImpactValue =
                new BigNumber(1).minus(ratio).multipliedBy(100).toNumber() * -1;
            setPriceImpact(priceImpactValue);

            setLoading(false);
        } catch (err) {
            setError('Error fetching swap amount or calculating price impact');
            setLoading(false);
        }
    }, [
        amountIn,
        token0,
        token1,
        routerService,
        currentNetworkConfig,
        fetchPoolDataError,
        isLoading,
        pairData,
    ]);

    useEffect(() => {
        getData();
    }, [getData]);

    return { expectedOutput, priceImpact, loading, error, refetch: getData };
};
