import { Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import WindowsContentContainer from '../../components/containers/WindowsContentContainer';
import Liquidity from '../liquidity/Liquidity';
import Rewards from '../rewards/Rewards';
import Staking from '../staking/Staking';
import Swap from '../swap/Swap';

const tabSx = {
    fontSize: '0.875rem',
    backgroundColor: '#c0c0c0', // Classic grey for Windows
    marginLeft: '-1px',
    marginRight: '2px',
    padding: '12px',
    minHeight: 'unset',
    borderTop: '2px solid #c0c0c0',
    borderLeft: '2px solid #c0c0c0',
    borderRight: '2px solid #c0c0c0',
    borderRadius: '4px 4px 0 0',

    '&:hover': {
        backgroundColor: '#d0d0d0',
        cursor: 'pointer',
    },
    '&.Mui-selected': {
        backgroundColor: '#e0e0e0',
        fontWeight: 'bold',
        color: 'black',
        borderBottom: 'none',
    },
};

const rainbowText = {
    animation: 'colorChange 3s linear infinite',
    color: 'rgb(255, 0, 0)', // Initial color

    '@keyframes colorChange': {
        '0%': {
            color: 'rgb(255, 0, 0)', // Red
        },
        '16%': {
            color: 'rgb(255, 127, 0)', // Orange
        },
        '33%': {
            color: 'rgb(255, 255, 0)', // Yellow
        },
        '50%': {
            color: 'rgb(0, 255, 0)', // Green
        },
        '66%': {
            color: 'rgb(0, 0, 255)', // Blue
        },
        '83%': {
            color: 'rgb(75, 0, 130)', // Indigo
        },
        '100%': {
            color: 'rgb(139, 0, 255)', // Violet
        },
    },
};

const Controller = () => {
    const [activeTab, setActiveTab] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    return (
        <WindowsContentContainer title="xp.fun">
            <Tabs
                value={activeTab}
                onChange={handleChange}
                sx={{
                    borderBottom: 'none',
                    '.MuiTabs-indicator': { display: 'none' },
                }}>
                <Tab
                    label="Swap"
                    sx={tabSx}
                />
                <Tab
                    label="Liquidity"
                    sx={tabSx}
                />
                <Tab
                    label="Staking"
                    sx={tabSx}
                />
                <Tab
                    label="Rewards"
                    sx={{ ...tabSx, ...rainbowText }}
                />
            </Tabs>

            {activeTab === 0 && <Swap />}
            {activeTab === 1 && <Liquidity />}
            {activeTab === 2 && <Staking />}
            {activeTab === 3 && <Rewards />}
        </WindowsContentContainer>
    );
};

export default Controller;
