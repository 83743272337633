import { Box, Container } from '@mui/material';
import React, { ReactNode } from 'react';

export interface ContentContainerProps {
    children: ReactNode;
}

const ContentContainer = ({ children }: ContentContainerProps) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
            }}>
            <Container
                sx={{
                    alignItems: 'center',
                }}>
                {children}
            </Container>
        </Box>
    );
};

export default ContentContainer;
