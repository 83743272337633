import { PublicClient } from 'viem';
import { VistaFactoryAbi } from '../shared/abi/VistaFactory';
import { NetworkConfig } from '../types/NetworkConfig';
import { Network } from '../types/Web3/Network';

export class FactoryService {
    constructor(private readonly getProvider: (network: Network) => PublicClient) {}

    public async getPair(
        networkConfig: NetworkConfig,
        token0Address: string,
        token1Address: string,
    ): Promise<string> {
        try {
            const client = this.getProvider(networkConfig.network);

            const contract = {
                address: networkConfig.addresses.NATIVE_FACTORY_ADDRESS as `0x${string}`,
                abi: VistaFactoryAbi,
            } as const;

            const result = await client.readContract({
                ...contract,
                functionName: 'getPair',
                args: [token0Address, token1Address],
            });

            const pairAddress = result as string;

            return pairAddress;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }
}
