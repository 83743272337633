import { IconButton, Paper } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import * as React from 'react';

export interface BasicModalProps {
    title?: string;
    open: boolean;
    children: React.ReactNode;
    setOpen: (value: boolean) => void;
    withCloseButton?: boolean;
    contentMaxWidth?: number;
}

export const BasicModal = ({
    title,
    open,
    setOpen,
    withCloseButton = true,
    contentMaxWidth = 420,
    children,
    ...props
}: BasicModalProps) => {
    const handleClose = () => setOpen(false);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backdropFilter: 'blur(5px)',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}
            onClick={(e) => {
                e.stopPropagation();
            }}
            {...props}>
            <Paper
                variant="windows"
                sx={{
                    position: 'relative',
                    margin: '10px',
                    overflowY: 'auto',
                    width: '100%',
                    maxWidth: { xs: '359px', sm: `${contentMaxWidth}px` },
                    maxHeight: 'calc(100vh - 20px)',
                }}>
                <Box
                    sx={{
                        fontFamily: '"Trebuchet MS", sans-serif',
                        background: `linear-gradient(
                                180deg,
                                rgba(9, 151, 255, 1) 0%,
                                rgba(0, 83, 238, 1) 8%,
                                rgba(0, 80, 238, 1) 40%,
                                rgba(0, 102, 255, 1) 88%,
                                rgba(0, 102, 255, 1) 93%,
                                rgba(0, 91, 255, 1) 95%,
                                rgba(0, 61, 215, 1) 96%,
                                rgba(0, 61, 215, 1) 100%
                              )`,
                        padding: '5px 5px 3px 6px',
                        borderTop: '1px solid #0831d9',
                        borderLeft: '1px solid #0831d9',
                        borderRight: '1px solid #001ea0',
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '7px',
                        fontSize: '17px',
                        textShadow: '1px 1px #0f1089',
                        height: '30px',
                    }}>
                    {title}
                    {withCloseButton && (
                        <Box sx={{ position: 'absolute', top: -2, right: '2px', zIndex: 5 }}>
                            <IconButton
                                sx={{
                                    minWidth: '21px',
                                    minHeight: '21px',
                                    ml: '2px', // margin-left: 2px
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    boxShadow: 'none',
                                    transition: 'background 100ms',
                                    border: 'none',
                                    '&:active, &:hover, &:focus': {
                                        boxShadow: 'none !important',
                                    },
                                    '&:hover': {
                                        backgroundImage: 'url(./icon/close-hover.svg)',
                                    },
                                    '&:not(:disabled):active': {
                                        backgroundImage: 'url(./icon/close-active.svg)',
                                    },
                                    backgroundImage: 'url(./icon/close.svg)',
                                }}
                                onClick={handleClose}
                                data-cy={'close-button'}>
                                <img
                                    src="/images/icons/ui/close.svg"
                                    alt="Close"
                                    width={21}
                                    height={21}
                                />
                            </IconButton>
                        </Box>
                    )}
                </Box>

                {children}
            </Paper>
        </Modal>
    );
};
