import { Box, Container, Grid2, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TokenSwapPanel from '../../modules/swap/TokenSwapPanel';
import TokenChart from '../../modules/token/TokenChart';
import TokenInfoHeader from '../../modules/token/TokenInfoHeader';
import TokenStatistics from '../../modules/token/TokenStatistics';
import { useSharedDependencies } from '../../providers/SharedDependencyProvider';
import { useRootStore } from '../../store/root';
import { Pool } from '../../types/Pools/Pool';
import WindowsContentContainer from './WindowsContentContainer';
import { useToken } from '../../hooks/useWrappedToken';
import { useTokenDetails } from '../../hooks/useTokenDetails';
import { CreatedToken } from '../../types/CreatedToken';

interface TokenInfoContainerProps {
    contractAddress: string;
}

const TokenInfoContainer: React.FC<TokenInfoContainerProps> = ({ contractAddress }) => {
    const [currentNetworkConfig] = useRootStore((store) => [store.currentNetworkConfig]);
    const { uiDataService } = useSharedDependencies();
    const [pool, setPool] = useState<Pool | undefined>(undefined);

    useEffect(() => {
        const getData = async () => {
            try {
                const data = await uiDataService.GetPoolDataHumanized(
                    currentNetworkConfig,
                    contractAddress,
                    currentNetworkConfig.addresses.BASE_ASSET_ADDRESS,
                );
                setPool(data);

            } catch (error) {
                console.log(error);
            }
        };
        if (contractAddress) getData();
    }, [contractAddress, currentNetworkConfig, uiDataService]);
    return (
        <>
            {pool ? (
                <Container
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '100%',
                        height: '100vh',
                        maxWidth: '100vw',
                        padding: 0,
                    }}>
                    <Grid2
                        container
                        spacing={2}
                        sx={{ pt: 3, height: '100%' }}>
                        <Grid2 size={{ xs: 12, lg: 9 }}>
                            <Box
                                sx={{
                                    pb: 2,
                                    alignItems: 'flex-start',
                                }}>
                                <TokenInfoHeader contractAddress={contractAddress} />
                            </Box>
                            <Box sx={{ pt: 2 }}>
                                <TokenChart
                                    title={`${pool.token0.symbol}/${pool.token1.symbol} Pool`}
                                    poolAddress={pool.pairAddress}
                                />
                            </Box>
                        </Grid2>
                        <Grid2 size={{ xs: 12, lg: 3 }}>
                            <Box>
                                <TokenSwapPanel pool={pool} />
                            </Box>
                            <Box sx={{ p: 2 }}>
                                <TokenStatistics pool={pool} />
                            </Box>
                        </Grid2>
                    </Grid2>
                </Container>
            ) : (
                <Box sx={{ mt: '25%' }}>
                    <WindowsContentContainer title="Loading">
                        <Typography sx={{ color: 'black' }}>Loading</Typography>
                    </WindowsContentContainer>
                </Box>
            )}
        </>
    );
};
export default TokenInfoContainer;
