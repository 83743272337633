import { enableMapSet } from 'immer';
import { create } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import { createLayoutSlice, LayoutSlice } from './layoutSlice';
import { createPoolSlice, PoolSlice } from './poolSlice';
import { createWalletSlice, WalletSlice } from './walletSlice';
import { createProtocolSlice, ProtocolSlice } from './protocolSlice';
import { createEventSlice, EventSlice } from './eventSlice';

enableMapSet();

export type RootStore = LayoutSlice & PoolSlice & ProtocolSlice & WalletSlice & EventSlice;

export const useRootStore = create<RootStore>()(
    subscribeWithSelector(
        devtools((...args) => {
            return {
                ...createLayoutSlice(...args),
                ...createPoolSlice(...args),
                ...createProtocolSlice(...args),
                ...createWalletSlice(...args),
                ...createEventSlice(...args),
            };
        }),
    ),
);

if (typeof document !== 'undefined') {
    document.onreadystatechange = function () {
        if (document.readyState === 'complete') {
            console.log('root loaded');
        }
    };
}

// export const useUserPoolDataSubscription = createSingletonSubscriber(() => {
//     return useRootStore.getState().refreshUserPoolData();
// }, 60000);
