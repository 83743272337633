import { useEffect, useState } from 'react';
import { Token } from '../types/Token';

export const useToken = (token0?: Token, token1?: Token) => {
    const [opposingToken, setOpposingToken] = useState<Token | null>(null);

    useEffect(() => {
        if (token0 && token1) {
            setOpposingToken(token0.address === '0x0' ? token1 : token0);
        }
    }, [token0, token1]);

    return opposingToken;
};
