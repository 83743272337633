import { PublicClient } from 'viem';
import { TokenAbi } from '../shared/abi/Token';
import { NetworkConfig } from '../types/NetworkConfig';
import { Network } from '../types/Web3/Network';
import { getProtocolBaseApi } from '../utils/protocolUtils';

export class TokenService {
    constructor(private readonly getProvider: (network: Network) => PublicClient) {}

    public async name(
        currentNetworkConfig: NetworkConfig,
        contractAddress: string,
    ): Promise<string> {
        const tokenContract = {
            address: contractAddress as `0x${string}`,
            abi: TokenAbi,
        } as const;

        const client = this.getProvider(currentNetworkConfig.network);

        const result = await client.readContract({
            ...tokenContract,
            functionName: 'name',
        });
        const data = result as string;

        return data;
    }

    public async symbol(
        currentNetworkConfig: NetworkConfig,
        contractAddress: string,
    ): Promise<string> {
        const tokenContract = {
            address: contractAddress as `0x${string}`,
            abi: TokenAbi,
        } as const;

        const client = this.getProvider(currentNetworkConfig.network);

        const result = await client.readContract({
            ...tokenContract,
            functionName: 'symbol',
        });

        const data = result as string;

        return data;
    }

    public async totalSupply(
        currentNetworkConfig: NetworkConfig,
        contractAddress: string,
    ): Promise<bigint> {
        const tokenContract = {
            address: contractAddress as `0x${string}`,
            abi: TokenAbi,
        } as const;

        const client = this.getProvider(currentNetworkConfig.network);

        const result = await client.readContract({
            ...tokenContract,
            functionName: 'totalSupply',
        });

        const data = result as bigint;

        return data;
    }

    public async decimals(
        currentNetworkConfig: NetworkConfig,
        contractAddress: string,
    ): Promise<number> {
        const tokenContract = {
            address: contractAddress as `0x${string}`,
            abi: TokenAbi,
        } as const;

        const client = this.getProvider(currentNetworkConfig.network);

        const result = await client.readContract({
            ...tokenContract,
            functionName: 'decimals',
        });
        const data = result as number;

        return data;
    }

    public async allowance(
        currentNetworkConfig: NetworkConfig,
        owner: string,
        contractAddress: string,
        spender: string,
    ): Promise<bigint> {
        const tokenContract = {
            address: contractAddress as `0x${string}`,
            abi: TokenAbi,
        } as const;

        const client = this.getProvider(currentNetworkConfig.network);

        const result = await client.readContract({
            ...tokenContract,
            functionName: 'allowance',
            args: [owner, spender],
        });
        const data = result as bigint;

        console.log('allowance', data);

        return data;
    }

    public async balanceOf(
        currentNetworkConfig: NetworkConfig,
        contractAddress: string,
        address: string,
    ): Promise<bigint> {
        const tokenContract = {
            address: contractAddress as `0x${string}`,
            abi: TokenAbi,
        } as const;

        const client = this.getProvider(currentNetworkConfig.network);

        const result = await client.readContract({
            ...tokenContract,
            functionName: 'balanceOf',
            args: [address],
        });

        const data = result as bigint;

        return data;
    }

    public async updateTokenDetails(
        message: string,
        address: string,
        description: string,
        web_url: string,
        twitter_url: string,
        discord_url: string,
        img_url: File | null,
    ): Promise<boolean> {
        try {
            const baseUrl = getProtocolBaseApi();

            const uri = `${baseUrl}/api/tokens/${address}`;

            const formData = new FormData();
            formData.append('message', message);
            formData.append('description', description);
            formData.append('web_url', web_url);
            formData.append('twitter_url', twitter_url);
            formData.append('discord_url', discord_url);

            if (img_url) {
                formData.append('img_url', img_url);
            }

            await fetch(uri, {
                method: 'POST',
                body: formData,
            });

            return true;
        } catch (error) {
            throw error;
        }
    }

    public async uploadImage(chain: number, img_url: File): Promise<string> {
        try {
            const baseUrl = getProtocolBaseApi();

            const uri = `${baseUrl}/api/images/${chain}`;

            const formData = new FormData();

            formData.append('img_url', img_url);

            const result = await fetch(uri, {
                method: 'POST',
                body: formData,
            });

            if (!result.ok) {
                throw new Error(`Failed to upload image: ${result.statusText}`);
            }

            const responseData = await result.json();

            const imgUrl = responseData.imgUrl.url;
            return imgUrl;
        } catch (error) {
            throw error;
        }
    }
}
