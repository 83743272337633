import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ContractFunctionExecutionError } from 'viem';
import { useAccount, useWriteContract } from 'wagmi';
import TokenSelectButton from '../../components/buttons/TokenSelectButton';
import { ToastContainerModal } from '../../components/modals/ToastContainerModal';
import { useTokenData } from '../../hooks/useTokenData';
import { Token } from '../../types/Token';
import { TokenFactoryAbi } from '../../shared/abi/TokenFactory';
import { useRootStore } from '../../store/root';

const RemoveLiquidityDeployerPanel = () => {
    const [currentNetworkConfig] = useRootStore((store) => [store.currentNetworkConfig]);
    const { writeContractAsync } = useWriteContract();
    const { isConnected, address } = useAccount();
    const [token0ContractAddress, setToken0ContractAddress] = useState<string | undefined>();
    const [token0, setToken0] = useState<Token | undefined>();

    const token0Data = useTokenData(token0ContractAddress || '');

    useEffect(() => {
        if (token0Data.data) {
            setToken0(token0Data.data);
        }
    }, [token0Data.data]);

    const handleRemoveLiquidity = () => {
        const removeLiquidity = async () => {
            if (!isConnected || !address) {
                toast.error('Please connect your wallet');
                return;
            }

            if (token0)
                try {
                    await writeContractAsync({
                        address: currentNetworkConfig.addresses.XP_FACTORY_ADDRESS as `0x${string}`,
                        abi: TokenFactoryAbi,
                        functionName: 'withdrawLiquidity',
                        args: [token0.address],
                    });
                } catch (error) {
                    console.error(error);

                    if (error instanceof ContractFunctionExecutionError) {
                        toast.error(error.cause.shortMessage);
                    } else {
                        toast.error('Transaction failed, check console for details');
                    }
                }
        };
        removeLiquidity();
    };
    return (
        <>
            <ToastContainerModal />

            <Box
                display="flex"
                flexDirection="column"
                sx={{
                    ml: 4,
                    mr: 4,
                    p: 1,
                }}>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    width="100%"
                    sx={{ position: 'relative' }}>
                    <Box sx={{ justifyContent: 'center' }}>
                        <Box sx={{ p: 2 }}>
                            <TokenSelectButton
                                token={token0}
                                onAddressChange={setToken0ContractAddress}
                            />
                        </Box>
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        width: '100%',
                    }}>
                    <Button
                        variant="windows"
                        onClick={() => handleRemoveLiquidity()}>
                        Remove Deployer Liquidity
                    </Button>
                </Box>
            </Box>
        </>
    );
};
export default RemoveLiquidityDeployerPanel;
