import { isAddress, PublicClient } from 'viem';
import { NetworkConfig } from '../types/NetworkConfig';
import { StakersInfo } from '../types/Staking/StakersInfo';
import { Network } from '../types/Web3/Network';
import { HardLockAbi } from '../shared/abi/Hardlock';

export class HardlockService {
    constructor(private readonly getProvider: (network: Network) => PublicClient) {}

    public async totalSupplies(
        currentNetworkConfig: NetworkConfig,
        address: string,
    ): Promise<boolean> {
        const tokenContract = {
            address: currentNetworkConfig.addresses.HARDLOCK_ADDRESS as `0x${string}`,
            abi: HardLockAbi,
        } as const;

        const client = this.getProvider(currentNetworkConfig.network);

        const result = await client.readContract({
            ...tokenContract,
            functionName: 'totalSupplies',
            args: [address],
        });

        const resultData = result as bigint;

        if (resultData > 0n) return true;

        return false;
    }

    public async viewShare(
        currentNetworkConfig: NetworkConfig,
        contractAddress: string,
    ): Promise<bigint> {
        if (!isAddress(contractAddress)) return 0n;
        const contract = {
            address: contractAddress as `0x${string}`,
            abi: HardLockAbi,
        } as const;

        const client = this.getProvider(currentNetworkConfig.network);

        const result = await client.readContract({
            ...contract,
            functionName: 'viewShare',
        });

        const data = result as bigint;

        return data;
    }

    public async stakersInfos(
        currentNetworkConfig: NetworkConfig,
        address: string,
    ): Promise<StakersInfo> {
        const tokenContract = {
            address: currentNetworkConfig.addresses.HARDLOCK_ADDRESS as `0x${string}`,
            abi: HardLockAbi,
        } as const;

        const client = this.getProvider(currentNetworkConfig.network);

        const result = await client.readContract({
            ...tokenContract,
            functionName: 'getStakerInfo',
            args: [address],
        });

        const resultData = result as bigint[];

        const data: StakersInfo = {
            tokensStaked: resultData[0],
            accumulatedRewardsPerToken: resultData[1],
        };

        return data;
    }
}
