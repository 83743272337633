import { useEffect, useState } from 'react';
import { useSharedDependencies } from '../providers/SharedDependencyProvider';
import { useRootStore } from '../store/root';
import { Pool } from '../types/Pools/Pool';

interface UsePoolDataProps {
    token0Address: string | undefined;
    token1Address: string | undefined;
}

export const usePoolData = ({ token0Address, token1Address }: UsePoolDataProps) => {
    const [currentNetworkConfig] = useRootStore((store) => [store.currentNetworkConfig]);
    const { uiDataService } = useSharedDependencies();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [pairData, setPairData] = useState<Pool | undefined>(undefined);

    useEffect(() => {
        const fetchPoolData = async () => {
            if (token0Address && token1Address) {
                setIsLoading(true);
                setError(undefined);
                try {
                    const poolData = await uiDataService.GetPoolDataHumanized(
                        currentNetworkConfig,
                        token0Address,
                        token1Address,
                    );

                    setPairData(poolData);
                } catch (err) {
                    setError(err instanceof Error ? err.message : 'An error occurred');
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchPoolData();
    }, [token0Address, token1Address, currentNetworkConfig, uiDataService]); // Dependency array ensures this runs when addresses change

    return { isLoading, error, pairData };
};
