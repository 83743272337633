import { useQuery } from '@tanstack/react-query';
import { queryKeysFactory } from '../shared/QueryKeysFactory';
import { useRootStore } from '../store/root';
import { NetworkConfig } from '../types/NetworkConfig';
import { TokenListing } from '../types/Tokens/TokenListing';

const fetchTokens = (currentNetworkConfig: NetworkConfig): TokenListing[] => {
    return currentNetworkConfig.topTokens;
};

export const useTopTokens = () => {
    const currentNetworkConfig = useRootStore((state) => state.currentNetworkConfig);

    return useQuery<TokenListing[], Error>({
        queryFn: () => fetchTokens(currentNetworkConfig),
        queryKey: queryKeysFactory.topTokens(currentNetworkConfig),
        enabled: true,
        initialData: [],
    });
};
