import { useQuery } from '@tanstack/react-query';
import { queryKeysFactory } from '../shared/QueryKeysFactory';
import { useRootStore } from '../store/root';
import { CreatedToken } from '../types/CreatedToken';
import { getProtocolBaseApi } from '../utils/protocolUtils';

const fetchTokenData = async (currentChainId: number, address: string) => {
    const baseUrl = getProtocolBaseApi();
    const url = `${baseUrl}/api/tokens/${currentChainId}/${address}`;

    const response = await fetch(url).then(async (res) => {
        if (!res.ok) {
            throw new Error('Network response was not ok');
        }

        return (await res.json()) as CreatedToken;
    });

    return response;
};

export const useTokenDetails = (address: string) => {
    const currentChainId = useRootStore((root) => root.currentChainId);
    return useQuery({
        queryFn: async () => {
            const data = fetchTokenData(currentChainId, address);

            return data;
        },
        queryKey: queryKeysFactory.tokenMetdata(address),
        enabled: !!address,
    });
};
