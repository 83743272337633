import { Box, Button, Slider, Typography } from '@mui/material';
import React from 'react';
import { useRootStore } from '../../store/root';
import { BasicModal } from '../primitives/BasicModal';

const xpSliderSx = {
    width: '90%',
    color: '#4caf50', // Green color for track
    '& .MuiSlider-thumb': {
        width: '16px',
        height: '20px',
        backgroundColor: '#d8d8d8', // Gray thumb background
        border: '1px solid #4caf50', // Green border to match XP style
        borderRadius: '2px',
        boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.8)',
        '&:before': {
            display: 'none', // Removes inner circle
        },
        '&:hover, &.Mui-focusVisible, &.Mui-active': {
            boxShadow: '0px 0px 4px rgba(0, 128, 0, 0.4)',
        },
    },
    '& .MuiSlider-track': {
        height: '2px',
        backgroundColor: '#4caf50',
    },
    '& .MuiSlider-rail': {
        height: '2px',
        opacity: 1,
        backgroundColor: '#c0c0c0', // Gray color for the rail
    },
    '& .MuiSlider-markLabel': {
        color: '#333', // Darker label color for better visibility
        fontSize: '0.875rem', // Adjust font size if needed
    },
    '& .MuiSlider-markLabelActive': {
        color: '#000', // Even darker color for the active label
    },
    '& .MuiSlider-mark': {
        backgroundColor: '#4caf50', // Green color for the tick marks
        height: '8px',
        width: '2px',
    },
};

interface SlippageSettingsPopoverProps {
    open: boolean;
    handleClose: () => void;
    setAuto: (value: boolean) => void;
    auto: boolean;
}

const SlippageSettingsPopover: React.FC<SlippageSettingsPopoverProps> = ({
    open,
    handleClose,
    auto,
    setAuto,
}) => {
    const [slippageValue, setSlippageValue] = useRootStore((store) => [
        store.slippageValue,
        store.setSlippageValue,
    ]);

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        setSlippageValue(newValue as number);
    };

    const marks = [
        { value: 1, label: '1' },
        { value: 100, label: '100' },
    ];
    return (
        <BasicModal
            open={open}
            setOpen={handleClose}>
            <Box minWidth={200}>
                <Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center "
                        sx={{ pt: 2 }}>
                        <Button
                            variant="windows"
                            onClick={() => setAuto(!auto)}
                            sx={{
                                backgroundColor: '#6a1b9a',
                                display: 'inline-flex',
                                textTransform: 'none',
                                padding: '6px 40px',
                                height: '30px',
                                width: 'auto',
                                alignItems: 'center',
                                whiteSpace: 'nowrap',
                            }}>
                            <Typography
                                variant="main16"
                                sx={{ lineHeight: 1, display: 'flex', alignItems: 'center' }}>
                                Auto {auto ? 'ON' : 'OFF'}
                            </Typography>
                        </Button>
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center "
                        sx={{ pt: 2 }}>
                        <Slider
                            onChange={handleSliderChange}
                            defaultValue={slippageValue}
                            sx={xpSliderSx}
                            step={1}
                            marks={marks}
                            min={0}
                            max={100}
                        />
                    </Box>
                    <Box
                        sx={{ p: 2 }}
                        display="flex"
                        flexDirection="row">
                        <Typography
                            color="black"
                            mt={1}>
                            Slippage:
                        </Typography>
                        <Typography
                            color="black"
                            sx={{ mt: 1, pl: 1 }}>
                            {slippageValue} %
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </BasicModal>
    );
};

export default SlippageSettingsPopover;
