import { BigNumber } from 'bignumber.js';
import { useCallback, useEffect, useState } from 'react';
import { useSharedDependencies } from '../providers/SharedDependencyProvider';
import { useRootStore } from '../store/root';
import { Token } from '../types/Token';

export interface UseReservesDataParams {
    token0: Token | undefined;
    token1: Token | undefined;
}

export interface UseReservesDataResult {
    reserves: { reserve0: BigNumber; reserve1: BigNumber } | null;
    poolAddress: string | undefined;
    loading: boolean;
    error: string | undefined;
    refetch: () => void;
}

export const useReservesData = ({
    token0,
    token1,
}: UseReservesDataParams): UseReservesDataResult => {
    const currentNetworkConfig = useRootStore((root) => root.currentNetworkConfig);
    const [reserves, setReserves] = useState<{ reserve0: BigNumber; reserve1: BigNumber } | null>(
        null,
    );
    const [poolAddress, setPoolAddress] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | undefined>(undefined);
    const { factoryService, uiDataService } = useSharedDependencies();

    const getReserveData = useCallback(async () => {
        if (!token0 || !token1) {
            setReserves(null);
            setLoading(false);
            return;
        }

        setLoading(true);
        setError(undefined);

        try {
            const poolAddress = await factoryService.getPair(
                currentNetworkConfig,
                token0.address,
                token1.address,
            );

            if (!poolAddress) {
                setPoolAddress(undefined);
            }
            setPoolAddress(poolAddress);

            const reserves = await uiDataService.GetPoolDataHumanized(
                currentNetworkConfig,
                token0.address,
                token1.address,
            );

            if (!reserves) {
                throw new Error('Reserves data not found');
            }

            setReserves({
                reserve0: new BigNumber(reserves.reserves.token0Reserve.toString()),
                reserve1: new BigNumber(reserves.reserves.token1Reserve.toString()),
            });
            setLoading(false);
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message);
            } else {
                setError('Error fetching reserves data');
            }
            setLoading(false);
        }
    }, [token0, token1, currentNetworkConfig, factoryService, uiDataService]);

    useEffect(() => {
        getReserveData();
    }, [getReserveData]);

    return { reserves, poolAddress, loading, error, refetch: getReserveData };
};
