// src/components/Header.tsx
import {
    Box,
    Link,
    Slide,
    Typography,
    useMediaQuery,
    useScrollTrigger,
    useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import HeaderConnectWalletButton from '../components/buttons/HeaderConnectWalletButton';
import { useRootStore } from '../store/root';

interface Props {
    children: React.ReactElement;
}

function HideOnScroll({ children }: Props) {
    const { breakpoints } = useTheme();
    const md = useMediaQuery(breakpoints.down('md'));
    const trigger = useScrollTrigger({ threshold: md ? 160 : 80 });

    return (
        <Slide
            appear={false}
            direction="down"
            in={!trigger}>
            {children}
        </Slide>
    );
}

const AppHeader: React.FC = () => {
    const { breakpoints } = useTheme();
    const md = useMediaQuery(breakpoints.down('md'));
    const sm = useMediaQuery(breakpoints.down('sm'));

    const [mobileDrawerOpen, setMobileDrawerOpen] = useRootStore((state) => [
        state.mobileDrawerOpen,
        state.setMobileDrawerOpen,
    ]);

    const [walletWidgetOpen, setWalletWidgetOpen] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    useEffect(() => {
        if (mobileDrawerOpen && !md) {
            setMobileDrawerOpen(false);
        }
        if (walletWidgetOpen) {
            setWalletWidgetOpen(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [md]);

    const headerHeight = 40;

    return (
        <>
            <HideOnScroll>
                <Box
                    component="header"
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    sx={(theme) => ({
                        height: headerHeight,
                        position: 'sticky',
                        top: 0,
                        transition: theme.transitions.create('top'),
                        zIndex: theme.zIndex.appBar,
                        padding: {
                            xs:
                                mobileMenuOpen || walletWidgetOpen
                                    ? '8px 20px'
                                    : '8px 8px 8px 20px',
                            xsm: '8px 20px',
                        },
                        display: 'flex',
                        alignItems: 'center',
                        overflow: 'hidden',
                        flexDirection: 'space-between',
                        backgroundColor: '#0063b1',
                        backdropFilter: 'blur(10px)',
                        boxShadow:
                            'inset 2px 2px 5px rgba(255, 255, 255, 0.6), inset -2px -2px 5px rgba(0, 0, 0, 0.5)',
                    })}>
                    <Box
                        component={Link}
                        href="/"
                        aria-label="Go to homepage"
                        sx={{
                            pt: 1,
                            pb: 1,
                            lineHeight: 0,
                            mr: 3,
                            transition: '0.3s ease all',
                            '&:hover': { opacity: 0.7 },
                        }}
                        onClick={() => setMobileMenuOpen(false)}>
                        <img
                            src="/images/xp_fun.png"
                            alt="XP.FUN"
                            width={100}
                        />
                    </Box>
                    {!sm && (
                        <Box sx={{ mr: 2 }}>
                            <Typography
                                component="div"
                                sx={{ display: 'flex', gap: 2 }}>
                                <Link
                                    rel="noreferrer"
                                    href="https://www.dextools.io/app/en/ether/pair-explorer/0x13777b43dc98ea10a67a08f45420fc7ac92a7d95"
                                    target="_blank"
                                    color="inherit"
                                    underline="hover">
                                    [chart]
                                </Link>
                                <Link
                                    href="https://x.com/xpdotfun"
                                    target="_blank"
                                    color="inherit"
                                    underline="hover">
                                    [twitter]
                                </Link>
                                <Link
                                    href="https://t.me/xpdotfunjoin"
                                    target="_blank"
                                    color="inherit"
                                    underline="hover">
                                    [telegram]
                                </Link>
                                <Link
                                    href="https://docs.xp.fun"
                                    color="inherit"
                                    underline="hover">
                                    [how it works]
                                </Link>
                            </Typography>
                        </Box>
                    )}

                    <Box sx={{ flexGrow: 1 }} />
                    <Box
                        sx={{
                            mr: -2,
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}>
                        <HeaderConnectWalletButton />
                    </Box>
                </Box>
            </HideOnScroll>
        </>
    );
};

export default AppHeader;
