import React, { createContext, useContext } from 'react';
// import { getProvider } from '../utils/marketAndNetworkUtils';
import invariant from 'tiny-invariant';
import { FactoryService } from '../services/FactoryService';
import { PairService } from '../services/PairService';
import { RouterService } from '../services/RouterService';
import { StakingService } from '../services/StakingService';
import { TokenService } from '../services/TokenService';
import { UiDataService } from '../services/UiDataService';
import { ApprovedAmountService } from '../services/approvedAmountService';
import { getProvider } from '../utils/protocolUtils';
import { HardlockService } from '../services/HardlockService';
import { TokenFactoryService } from '../services/TokenFactoryService';

interface ISharedDependenciesContext {
    factoryService: FactoryService;
    tokenService: TokenService;
    routerService: RouterService;
    pairService: PairService;
    uiDataService: UiDataService;
    approvedAmountService: ApprovedAmountService;
    stakingService: StakingService;
    hardlockService: HardlockService;
    tokenFactoryService: TokenFactoryService;
}

const SharedDependenciesContext = createContext<ISharedDependenciesContext | null>(null);

export const SharedDependenciesProvider: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    // services
    const factoryService = new FactoryService(getProvider);
    const tokenService = new TokenService(getProvider);
    const routerService = new RouterService(getProvider);
    const pairService = new PairService(getProvider);
    const uiDataService = new UiDataService(getProvider);
    const approvedAmountService = new ApprovedAmountService();
    const stakingService = new StakingService(getProvider);
    const hardlockService = new HardlockService(getProvider);
    const tokenFactoryService = new TokenFactoryService(getProvider);

    return (
        <SharedDependenciesContext.Provider
            value={{
                factoryService,
                tokenService,
                routerService,
                pairService,
                uiDataService,
                approvedAmountService,
                stakingService,
                hardlockService,
                tokenFactoryService
            }}>
            {children}
        </SharedDependenciesContext.Provider>
    );
};

export const useSharedDependencies = () => {
    const context = useContext(SharedDependenciesContext);
    invariant(context, 'Component should be wrapper inside a <SharedDependenciesProvider />');
    return context;
};
