import { PublicClient } from 'viem';
import { NetworkConfig } from '../types/NetworkConfig';
import { Pool } from '../types/Pools/Pool';
import { UserPool } from '../types/Pools/UserPool';
import { Token } from '../types/Token';
import { Network } from '../types/Web3/Network';
import { toBigInt } from '../utils/tokenUtils';
import { FactoryService } from './FactoryService';
import { PairService } from './PairService';
import { RouterService } from './RouterService';
import { TokenService } from './TokenService';
import { TokenFactoryService } from './TokenFactoryService';

export class UiDataService {
    constructor(private readonly getProvider: (network: Network) => PublicClient) {}

    private getPairService() {
        return new PairService(this.getProvider);
    }

    private getTokenService() {
        return new TokenService(this.getProvider);
    }

    private getRouterService() {
        return new RouterService(this.getProvider);
    }

    private getTokenFactoryService() {
        return new TokenFactoryService(this.getProvider);
    }

    public async GetPoolDataHumanized(
        currentNetworkConfig: NetworkConfig,
        token0Address: string,
        token1Address: string,
    ): Promise<Pool> {
        const factoryService = new FactoryService(this.getProvider);
        const pairService = new PairService(this.getProvider);
        const routerService = new RouterService(this.getProvider);
        const tokenFactoryService = new TokenFactoryService(this.getProvider);

        const pairAddress = await factoryService.getPair(
            currentNetworkConfig,
            token0Address,
            token1Address,
        );
        const token0 = await this.GetTokenMetadataHumanized(currentNetworkConfig, token0Address);
        const token1 = await this.GetTokenMetadataHumanized(currentNetworkConfig, token1Address);
        const pairBuyLpFee = await pairService.buyTotalFee(currentNetworkConfig, pairAddress);
        const pairBuyLpFeeInEth = await routerService.usdcToEth(currentNetworkConfig, pairBuyLpFee);
        const pairSellLpFee = await pairService.sellTotalFee(currentNetworkConfig, pairAddress);
        const pairSellLpFeeInEth = await routerService.usdcToEth(
            currentNetworkConfig,
            pairSellLpFee,
        );
        const getReserves = await pairService.getReserves(currentNetworkConfig, pairAddress);
        const totalCollected = await pairService.totalCollected(currentNetworkConfig, pairAddress);

        const fees = await tokenFactoryService.getTokenFees(
            currentNetworkConfig,
            token0Address === '0x0' ||
                token0Address === currentNetworkConfig.addresses.BASE_ASSET_ADDRESS
                ? token1Address
                : token0Address,
        );

        const tokenData = await tokenFactoryService.getTokenData(
            currentNetworkConfig,
            token0Address === '0x0' ||
                token0Address === currentNetworkConfig.addresses.BASE_ASSET_ADDRESS
                ? token1Address
                : token0Address,
        );

        return {
            pairAddress,
            token0,
            token1,
            pairBuyLpFee,
            pairSellLpFee,
            pairBuyLpFeeInEth,
            pairSellLpFeeInEth,
            totalCollected,
            reserves: { token0Reserve: getReserves[0], token1Reserve: getReserves[1] },
            tokenFees: { protocolFees: fees[1], deployerFees: fees[0] },
            isLpBurned: tokenData[1] === 0 ? false : true,
        };
    }

    public async GetUserPoolDataHumanized(
        currentNetworkConfig: NetworkConfig,
        poolAddress: string,
        user: string,
    ): Promise<UserPool> {
        const pairService = this.getPairService();
        const tokenService = this.getTokenService();
        const balance = await tokenService.balanceOf(currentNetworkConfig, poolAddress, user);
        const token0 = await pairService.token0(currentNetworkConfig, poolAddress);
        const token0Metadata = await this.GetTokenMetadataHumanized(
            currentNetworkConfig,
            token0.toString(),
        );
        const token1 = await pairService.token1(currentNetworkConfig, poolAddress);
        const token1Metadata = await this.GetTokenMetadataHumanized(
            currentNetworkConfig,
            token1.toString(),
        );

        const data: UserPool = {
            userAddress: user,
            balance,
            pool: {
                address: poolAddress,
                reserve0TokenAddress: token0.toString(),
                reserve0TokenSymbol: token0Metadata.symbol,
                reserve1TokenAdress: token1.toString(),
                reserve1TokenSymbol: token1Metadata.symbol,
            },
        };
        return data;
    }

    public async GetTokenMetadataHumanized(
        currentNetworkConfig: NetworkConfig,
        contractAddress: string,
    ): Promise<Token> {
        const tokenService = new TokenService(this.getProvider);

        let token: Token = {
            address: contractAddress,
            name: '',
            symbol: '',
            decimals: 0,
            totalSupply: 0n,
            isBaseToken: false,
        };

        if (
            contractAddress === '0x0' ||
            contractAddress === currentNetworkConfig.addresses.BASE_ASSET_ADDRESS
        ) {
            token.name = 'Ethereum';
            token.symbol = 'ETH';
            token.decimals = 18;
            token.totalSupply = 0n;
            token.isBaseToken = true;

            return token;
        }

        await Promise.all([
            tokenService.name(currentNetworkConfig, contractAddress),
            tokenService.symbol(currentNetworkConfig, contractAddress),
            tokenService.totalSupply(currentNetworkConfig, contractAddress),
            tokenService.decimals(currentNetworkConfig, contractAddress),
        ]).then((res) => {
            token.name = res[0];
            token.symbol = res[1];
            token.totalSupply = res[2];
            token.decimals = res[3];
        });

        return token;
    }

    public async GetLiquidityQuote(
        currentNetworkConfig: NetworkConfig,
        token: Token,
        amount: string,
        pool: Pool,
    ): Promise<bigint> {
        try {
            const routerService = this.getRouterService();

            let result = 0n;

            if (token.address === pool.token0.address) {
                result = await routerService.quote(
                    currentNetworkConfig,
                    toBigInt(amount, token.decimals),
                    pool.reserves.token0Reserve,
                    pool.reserves.token1Reserve,
                );
            } else {
                result = await routerService.quote(
                    currentNetworkConfig,
                    toBigInt(amount, token.decimals),
                    pool.reserves.token1Reserve,
                    pool.reserves.token0Reserve,
                );
            }
            return result;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }
}
