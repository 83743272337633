import { Box, IconButton, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import WindowsContentContainer from '../../components/containers/WindowsContentContainer';
import SlippageSettingsPopover from '../../components/modals/SlippageSettingsPopover';
import { useSharedDependencies } from '../../providers/SharedDependencyProvider';
import { useRootStore } from '../../store/root';
import { TabStyle } from '../../styles/TabStyle';
import { Pool } from '../../types/Pools/Pool';
import { fromBigInt, toBigInt } from '../../utils/tokenUtils';
import TokenSwapBuyPanel from './TokenSwapBuyPanel';
import TokenSwapSellPanel from './TokenSwapSellPanel';

interface TokenSwapPanelProps {
    pool: Pool;
}

const TokenSwapPanel: React.FC<TokenSwapPanelProps> = ({ pool }) => {
    const [slippageValue] = useRootStore((store) => [store.slippageValue]);
    const [activeTab, setActiveTab] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    const { routerService } = useSharedDependencies();

    const [currentNetworkConfig] = useRootStore((store) => [store.currentNetworkConfig]);

    const [token0] = useState(pool.token1);
    const [token0Amount, setToken0Amount] = useState('');
    const [token1] = useState(pool.token0);
    const [token1Amount, setToken1Amount] = useState('');

    const [autoSlippage, setAutoSlippage] = useState(true);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    useEffect(() => {
        if (!token0Amount) {
            setToken0Amount('');
        }
    }, [token0Amount]);

    useEffect(() => {
        const getAmountsOut = async () => {
            if (parseFloat(token0Amount) > 0) {
                const token0AmountBigInt = toBigInt(token0Amount, token0.decimals);
                const amount = await routerService.getAmountsOut(
                    currentNetworkConfig,
                    token0AmountBigInt,
                    [token0.address, token1.address],
                );
                const amountStr = fromBigInt(amount, token1.decimals);
                setToken1Amount(amountStr);
            } else {
                setToken1Amount('');
            }
        };

        if (token0Amount && token0 && token1) getAmountsOut();
    }, [token0Amount, currentNetworkConfig, routerService, token0, token1]);

    useEffect(() => {
        const getAmountsIn = async () => {
            if (parseFloat(token1Amount) > 0) {
                const token1AmountBigInt = toBigInt(token1Amount, token0.decimals);
                const amount = await routerService.getAmountsIn(
                    currentNetworkConfig,
                    token1AmountBigInt,
                    [token0.address, token1.address],
                );
                const amountStr = fromBigInt(amount, token1.decimals);
                setToken1Amount(amountStr);
            } else {
                setToken1Amount('');
            }
        };

        if (token1Amount) getAmountsIn();
    }, [
        token1Amount,
        currentNetworkConfig,
        routerService,
        token0.address,
        token1.address,
        token0.decimals,
        token1.decimals,
    ]);

    const handleSettingsClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openPopover = Boolean(anchorEl);

    return (
        <WindowsContentContainer
            title={`Swap ${token0.name} for ${token1.name}`}
            sx={{ alignItems: 'flex-end' }}>
            <Tabs
                value={activeTab}
                onChange={handleChange}
                sx={{
                    borderBottom: 'none',
                    '.MuiTabs-indicator': { display: 'none' },
                }}>
                <Tab
                    sx={TabStyle}
                    label="Buy"
                />
                <Tab
                    sx={TabStyle}
                    label="Sell"
                />
            </Tabs>

            <Box
                display="flex"
                flexDirection="column">
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                    <IconButton
                        sx={{
                            height: '40px',
                            width: '40px',
                            padding: 0, // Ensures icon uses the full button area
                            borderRadius: '8px',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.1)', // Optional: Add hover effect
                            },
                            zIndex: 50,
                        }}
                        onClick={handleSettingsClick}>
                        <img
                            src="/images/icons/ui/DLL.png"
                            alt="DLL"
                            height="75%"
                            width="75%"
                        />
                    </IconButton>
                    <Typography sx={{ color: 'black' }}>Slippage</Typography>
                    {autoSlippage ? (
                        <Typography sx={{ color: 'black', pl: 0.8 }}>Auto</Typography>
                    ) : (
                        <Typography sx={{ color: 'black', pl: 0.8 }}>{slippageValue}%</Typography>
                    )}
                </Box>

                {activeTab === 0 && (
                    <TokenSwapBuyPanel
                        pool={pool}
                        slippageValue={slippageValue}
                        autoSlippage={autoSlippage}
                    />
                )}
                {activeTab === 1 && (
                    <TokenSwapSellPanel
                        token={pool.token0}
                        pool={pool}
                        slippageValue={slippageValue}
                        autoSlippage={autoSlippage}
                    />
                )}
            </Box>

            <SlippageSettingsPopover
                open={openPopover}
                handleClose={handleClose}
                auto={autoSlippage}
                setAuto={setAutoSlippage}
            />
        </WindowsContentContainer>
    );
};
export default TokenSwapPanel;
