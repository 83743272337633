import { Box, Button, Slider, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ContractFunctionExecutionError } from 'viem';
import { useAccount, useWriteContract } from 'wagmi';
import TokenSelectButton from '../../components/buttons/TokenSelectButton';
import { ToastContainerModal } from '../../components/modals/ToastContainerModal';
import { useTokenData } from '../../hooks/useTokenData';
import { useToken } from '../../hooks/useWrappedToken';
import { useSharedDependencies } from '../../providers/SharedDependencyProvider';
import { useRootStore } from '../../store/root';
import { UserPool } from '../../types/Pools/UserPool';
import { Token } from '../../types/Token';
import { MAX_UINT256, toBigInt } from '../../utils/tokenUtils';
import { checkRequiresApproval } from '../../utils/transactionUtils';
import { VistaPairAbi } from '../../shared/abi/VistaPair';
import { VistaRouterAbi } from '../../shared/abi/VistaRouter';
import { ethers } from 'ethers';

const xpSliderSx = {
    width: '200px',
    color: '#4caf50',
    '& .MuiSlider-thumb': {
        width: '16px',
        height: '20px',
        backgroundColor: '#d8d8d8',
        border: '1px solid #4caf50',
        borderRadius: '2px',
        boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.8)',
        '&:before': {
            display: 'none',
        },
        '&:hover, &.Mui-focusVisible, &.Mui-active': {
            boxShadow: '0px 0px 4px rgba(0, 128, 0, 0.4)',
        },
    },
    '& .MuiSlider-track': {
        height: '2px',
        backgroundColor: '#4caf50',
    },
    '& .MuiSlider-rail': {
        height: '2px',
        opacity: 1,
        backgroundColor: '#c0c0c0',
    },
    '& .MuiSlider-markLabel': {
        color: '#333',
        fontSize: '0.875rem',
    },
    '& .MuiSlider-markLabelActive': {
        color: '#000',
    },
    '& .MuiSlider-mark': {
        backgroundColor: '#4caf50',
        height: '8px',
        width: '2px',
    },
};

const marks = [
    { value: 1, label: '1' },
    { value: 100, label: '100' },
];

const RemoveLiquidityUserPanel = () => {
    const { writeContractAsync } = useWriteContract();
    const { isConnected, address } = useAccount();
    const { uiDataService, factoryService, tokenService } = useSharedDependencies();
    const [currentNetworkConfig] = useRootStore((store) => [store.currentNetworkConfig]);
    const [liquidityAmount, setLiquidityAmount] = useState(0);
    const [userPoolData, setUserPoolData] = useState<UserPool | undefined>();

    const [token0ContractAddress, setToken0ContractAddress] = useState<string | undefined>();
    const [token0, setToken0] = useState<Token | undefined>();
    const [token1ContractAddress, setToken1ContractAddress] = useState<string | undefined>();
    const [token1, setToken1] = useState<Token | undefined>();

    const token0Data = useTokenData(token0ContractAddress || '');
    useEffect(() => {
        if (token0Data.data) {
            setToken0(token0Data.data);
        }
    }, [token0Data.data]);

    const token1Data = useTokenData(token1ContractAddress || '');
    useEffect(() => {
        if (token1Data.data) {
            setToken1(token1Data.data);
        }
    }, [token1Data.data]);
    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        setLiquidityAmount(newValue as number);
    };

    const token = useToken(token0, token1);

    React.useEffect(() => {
        const getData = async () => {
            if (token) {
                if (!isConnected || !address) {
                    toast.error('Please connect your wallet');
                    return;
                }

                const pool = await uiDataService.GetPoolDataHumanized(
                    currentNetworkConfig,
                    token.address,
                    currentNetworkConfig.addresses.BASE_ASSET_ADDRESS,
                );
                const userPoolData = await uiDataService.GetUserPoolDataHumanized(
                    currentNetworkConfig,
                    pool.pairAddress,
                    address,
                );
                setUserPoolData(userPoolData);
            }
        };
        getData();
    }, [
        token0,
        token1,
        address,
        currentNetworkConfig,
        factoryService,
        token,
        uiDataService,
        isConnected,
    ]);

    const handleRemoveLiquidity = () => {
        const removeLiquidity = async () => {
            if (!isConnected || !address) {
                toast.error('Please connect your wallet');
                return;
            }
            if (userPoolData && token0 && token1 && token)
                try {
                    if (userPoolData.balance && userPoolData.balance > 0n) {
                        const tokenToRemove = token0.isBaseToken ? token1.address : token0.address;

                        const amountToBigInt = ethers.toBigInt(liquidityAmount);

                        const liqToRemove = (userPoolData.balance * amountToBigInt) / 100n;

                        const allowance = await tokenService.allowance(
                            currentNetworkConfig,
                            address,
                            userPoolData.pool.address,
                            currentNetworkConfig.addresses.NATIVE_ROUTER_ADDRESS,
                        );

                        const approvalRequired = checkRequiresApproval({
                            approvedAmount: allowance,
                            amount: liqToRemove,
                        });

                        if (approvalRequired) {
                            await writeContractAsync({
                                address: userPoolData.pool.address as `0x${string}`,
                                abi: VistaPairAbi,
                                functionName: 'approve',
                                args: [
                                    currentNetworkConfig.addresses.NATIVE_ROUTER_ADDRESS,
                                    MAX_UINT256,
                                ],
                            });

                            toast.info('Approval in progress, please wait');

                            await new Promise((resolve) => setTimeout(resolve, 5000));
                        }

                        const reserves = await uiDataService.GetPoolDataHumanized(
                            currentNetworkConfig,
                            userPoolData.pool.reserve0TokenAddress,
                            userPoolData.pool.reserve1TokenAdress,
                        );
                        const totalSupply = await tokenService.totalSupply(
                            currentNetworkConfig,
                            userPoolData.pool.address,
                        );

                        const amountToken0Min =
                            (BigInt(reserves.reserves.token0Reserve) * liqToRemove) /
                            BigInt(totalSupply);
                        const amountToken1Min =
                            (BigInt(reserves.reserves.token1Reserve) * liqToRemove) /
                            BigInt(totalSupply);

                        const deadline = Math.floor(Date.now() / 1000) + 60 * 20;

                        console.log(userPoolData.balance, liqToRemove, amountToken0Min, amountToken1Min, deadline)

                        await writeContractAsync({
                            address: currentNetworkConfig.addresses.NATIVE_ROUTER_ADDRESS as `0x${string}`,
                            abi: VistaRouterAbi,
                            functionName: 'removeLiquidityETHSupportingFeeOnTransferTokens',
                            args: [
                                tokenToRemove,
                                liqToRemove,
                                amountToken0Min,
                                amountToken1Min,
                                deadline,
                            ],
                        });
                    } else {
                        toast.error('No liquidity to remove');
                        return;
                    }
                } catch (error) {
                    console.error(error);

                    if (error instanceof ContractFunctionExecutionError) {
                        toast.error(error.cause.shortMessage);
                    } else {
                        toast.error('Transaction failed, check console for details');
                    }
                }
        };
        removeLiquidity();
    };
    return (
        <>
            <ToastContainerModal />

            <Box
                display="flex"
                flexDirection="column"
                sx={{
                    ml: 4,
                    mr: 4,
                    p: 1,
                }}>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    width="100%"
                    sx={{ position: 'relative' }}>
                    <Box sx={{ justifyContent: 'center' }}>
                        <Box sx={{ p: 2 }}>
                            <TokenSelectButton
                                token={token0}
                                onAddressChange={setToken0ContractAddress}
                            />
                        </Box>
                        <Box sx={{ p: 2 }}>
                            <TokenSelectButton
                                token={token1}
                                onAddressChange={setToken1ContractAddress}
                            />
                        </Box>
                        <Box sx={{ p: 2 }}>
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center">
                                <Typography color="black">Amount</Typography>
                                <Slider
                                    onChange={handleSliderChange}
                                    value={liquidityAmount}
                                    sx={xpSliderSx}
                                    step={1}
                                    marks={marks}
                                    min={0}
                                    max={100}
                                />
                                <Typography color="black">{liquidityAmount}%</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        width: '100%',
                    }}>
                    <Button
                        variant="windows"
                        onClick={() => handleRemoveLiquidity()}>
                        Remove Liquidity
                    </Button>
                </Box>
            </Box>
        </>
    );
};
export default RemoveLiquidityUserPanel;
