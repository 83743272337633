// src/context/WebSocketContext.js

import { Alchemy, Network } from 'alchemy-sdk';
import React, { createContext, ReactNode, useContext, useEffect } from 'react';
import { Event as TxEvent } from '../store/eventSlice';
import { useRootStore } from '../store/root';

interface WebSocketContextType {
    transactions: []; // Use the appropriate type from Alchemy SDK
}

// const defaultValue: WebSocketContextType = {
//     transactions: [],
// };

// Create the WebSocket Context
const WebSocketContext = createContext<WebSocketContextType>({
    transactions: [],
});

// Alchemy settings
const settings = {
    apiKey: 'vJZLBQNAYizRVCl6gnsmeXVHHLouD95n',
    network: Network.ARB_MAINNET,
};

// Create Alchemy instance
const alchemy = new Alchemy(settings);

// WebSocket Provider Component
export const WebSocketProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [addEvent, currentNetworkConfig] = useRootStore((state) => [
        state.addEvent,
        state.currentNetworkConfig,
    ]);

    useEffect(() => {
        const filter = {
            address: currentNetworkConfig.addresses.XP_FACTORY_ADDRESS,
            topics: ['0xd5d05a8421149c74fd223cfc823befb883babf9bf0b0e4d6bf9c8fdb70e59bb4'],
        };

        alchemy.ws.on(filter, (log, event) => {
            if (log && log.blockHash) {
                const e: TxEvent = {
                    eventHash: log.blockHash,
                };
                addEvent(e);
            }
        });
    }, [addEvent, currentNetworkConfig.addresses.XP_FACTORY_ADDRESS]);

    return <>{children}</>;
};

export const useWebSocket = () => useContext(WebSocketContext);
