import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function ToastContainerModal() {
    return (
        <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            style={{
                zIndex: 9999,
                marginBottom: '6px',
            }}
            toastStyle={{
                backgroundColor: '#7BA7D7',
                color: 'white',
                border: '2px solid white',
                borderRadius: '10px',
                padding: '15px',
                fontWeight: '500',
            }}
        />
    );
}

export default ToastContainer;
