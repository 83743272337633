import { Box, Button, InputBase, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { useTokenBalance } from '../../hooks/useTokenBalance';
import { Token } from '../../types/Token';
import { fromBigInt } from '../../utils/tokenUtils';
import { FormattedNumber } from '../primitives/FormattedNumber';

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
    value: string;
}

export const NumberFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
    function NumberFormatCustom(props, ref) {
        const { onChange, ...other } = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values: { value: string }) => {
                    if (values.value !== props.value)
                        onChange({
                            target: {
                                name: props.name,
                                value: values.value || '',
                            },
                        });
                }}
                thousandSeparator
                valueIsNumericString
                allowNegative={false}
            />
        );
    },
);

export interface TokenInputProps {
    title?: string;
    token?: Token;
    tokenAmount?: string;
    priceImpact?: number | null;
    disabled?: boolean;
    setTokenAmount?: (amount: string) => void;
    setBalanceToken?: (balance: string) => void;
}

const TokenInput: React.FC<TokenInputProps> = ({
    title,
    token,
    tokenAmount,
    priceImpact,
    disabled = false,
    setTokenAmount,
    setBalanceToken,
}) => {
    const [balance, setBalance] = useState('0');

    const balanceBigInt: bigint = useTokenBalance(token?.address || '');

    useEffect(() => {
        if (balanceBigInt !== undefined && token && token.decimals) {
            const normalizedBalance = fromBigInt(balanceBigInt, token.decimals);
            setBalance(normalizedBalance);
            if (setBalanceToken) setBalanceToken(normalizedBalance);
        }
    }, [balanceBigInt, token, setBalanceToken]);

    const handleAmountChange = (value: string) => {
        if (setTokenAmount) setTokenAmount(value);
    };

    const handleMaxClick = () => {
        if (setTokenAmount) setTokenAmount(balance.toString());
    };

    return (
        <Box sx={{ p: 2, position: 'relative' }}>
            <Box sx={{ pb: 2 }}>
                <Typography
                    variant="caption"
                    color="gray"
                    align="left"
                    gutterBottom>
                    {title}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                    <InputBase
                        fullWidth
                        placeholder="0.0"
                        sx={{
                            p: 1,
                            backgroundColor: 'white',
                            color: 'black',
                            height: '30px',
                            fontSize: '25px',
                            border: '1px solid black',
                            mr: 1,
                        }}
                        disabled={disabled}
                        onChange={(e) => handleAmountChange(e.target.value)}
                        value={tokenAmount}
                        // eslint-disable-next-line
                        inputComponent={NumberFormatCustom as any}
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'absolute',
                    bottom: 1,
                    right: 1,
                }}>
                <FormattedNumber
                    color="gray"
                    value={balance}
                    visibleDecimals={5}
                />
                <Button
                    disabled={balance === '0'}
                    variant="text"
                    onClick={handleMaxClick}
                    sx={{
                        color: balance === '0' ? 'gray' : '#018281',
                        textTransform: 'none',
                        ml: 1,
                    }}>
                    Max
                </Button>
            </Box>
            {priceImpact && (
                <Box>
                    <Typography
                        variant="caption"
                        color="gray"
                        sx={{ position: 'absolute', bottom: 8, left: 20 }}>
                        Impact: {priceImpact.toFixed(2)}%
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default TokenInput;
