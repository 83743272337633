export const TokenFactoryAbi = [
    {
        type: 'constructor',
        inputs: [
            { name: '_protocolFeesRecipient', type: 'address', internalType: 'address' },
            { name: '_stakingContract', type: 'address', internalType: 'address' },
            { name: '_uniswapRouter', type: 'address', internalType: 'address' },
            { name: '_hardlock', type: 'address', internalType: 'address' },
        ],
        stateMutability: 'nonpayable',
    },
    { type: 'receive', stateMutability: 'payable' },
    {
        type: 'function',
        name: 'TOKEN_PROXY_BYTECODE',
        inputs: [],
        outputs: [{ name: '', type: 'bytes', internalType: 'bytes' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'TOKEN_PROXY_DEPLOY_BYTECODE',
        inputs: [],
        outputs: [{ name: '', type: 'bytes', internalType: 'bytes' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'childTokens',
        inputs: [{ name: '', type: 'address', internalType: 'address' }],
        outputs: [
            { name: 'isChildToken', type: 'bool', internalType: 'bool' },
            { name: 'uniqueId', type: 'uint256', internalType: 'uint256' },
            {
                name: 'launchStatus',
                type: 'uint8',
                internalType: 'enum ITokenFactory.LaunchStatus',
            },
            { name: 'feesReceived', type: 'uint256', internalType: 'uint256' },
        ],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'deployToken',
        inputs: [
            { name: '_name', type: 'string', internalType: 'string' },
            { name: '_symbol', type: 'string', internalType: 'string' },
            { name: '_telegram', type: 'string', internalType: 'string' },
            { name: '_website', type: 'string', internalType: 'string' },
            { name: '_twitter', type: 'string', internalType: 'string' },
            { name: '_logo', type: 'string', internalType: 'string' },
            { name: '_description', type: 'string', internalType: 'string' },
            { name: 'toVitalik', type: 'uint256', internalType: 'uint256' },
        ],
        outputs: [{ name: '', type: 'address', internalType: 'address' }],
        stateMutability: 'payable',
    },
    {
        type: 'function',
        name: 'externalTryBurn',
        inputs: [{ name: 'token', type: 'address', internalType: 'address' }],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'feeAccrued',
        inputs: [{ name: 'token', type: 'address', internalType: 'address' }],
        outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'getChildTokenFees',
        inputs: [{ name: 'token', type: 'address', internalType: 'contract ITokenLogic' }],
        outputs: [{ name: '', type: 'uint8', internalType: 'uint8' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'getTokenData',
        inputs: [{ name: 'token', type: 'address', internalType: 'contract ITokenLogic' }],
        outputs: [
            { name: '', type: 'uint256', internalType: 'uint256' },
            { name: '', type: 'uint8', internalType: 'enum ITokenFactory.LaunchStatus' },
        ],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'getTokenFees',
        inputs: [{ name: 'token', type: 'address', internalType: 'address' }],
        outputs: [
            { name: 'deployerFees', type: 'uint256', internalType: 'uint256' },
            { name: 'protocolFees', type: 'uint256', internalType: 'uint256' },
        ],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'getTokenUniqueId',
        inputs: [{ name: 'token', type: 'address', internalType: 'address' }],
        outputs: [{ name: '', type: 'bytes32', internalType: 'bytes32' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'getTokensDeployed',
        inputs: [{ name: 'deployer', type: 'address', internalType: 'address' }],
        outputs: [{ name: '', type: 'address[]', internalType: 'address[]' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'owner',
        inputs: [],
        outputs: [{ name: '', type: 'address', internalType: 'address' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'protocolFeesRecipient',
        inputs: [],
        outputs: [{ name: '', type: 'address', internalType: 'address payable' }],
        stateMutability: 'view',
    },
    { type: 'function', name: 'receiveEth', inputs: [], outputs: [], stateMutability: 'payable' },
    {
        type: 'function',
        name: 'renounceStakingUpgrade',
        inputs: [],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'setProtocolFeesRecipient',
        inputs: [
            { name: '_protocolFeesRecipient', type: 'address', internalType: 'address payable' },
        ],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'setStaking',
        inputs: [
            { name: '_stakingContract', type: 'address', internalType: 'address' },
            { name: '_stakingFees', type: 'uint8', internalType: 'uint8' },
        ],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'setUniswapRouter',
        inputs: [{ name: '_uniswapRouter', type: 'address', internalType: 'address' }],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'setVitalikAddress',
        inputs: [{ name: '_vitalikAddress', type: 'address', internalType: 'address' }],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'stakingContract',
        inputs: [],
        outputs: [{ name: '', type: 'address', internalType: 'address payable' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'stakingFees',
        inputs: [],
        outputs: [{ name: '', type: 'uint8', internalType: 'uint8' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'tokenLogic',
        inputs: [],
        outputs: [{ name: '', type: 'address', internalType: 'address' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'tokensDeployed',
        inputs: [
            { name: '', type: 'address', internalType: 'address' },
            { name: '', type: 'uint256', internalType: 'uint256' },
        ],
        outputs: [{ name: '', type: 'address', internalType: 'address' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'uniqueId',
        inputs: [],
        outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'uniswapRouter',
        inputs: [],
        outputs: [{ name: '', type: 'address', internalType: 'contract EtherVistaRouter' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'updateOwner',
        inputs: [{ name: '_owner', type: 'address', internalType: 'address' }],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'vitalikAddress',
        inputs: [],
        outputs: [{ name: '', type: 'address', internalType: 'address' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'withdrawLiquidity',
        inputs: [{ name: 'token', type: 'address', internalType: 'address' }],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'withdrawalAddresses',
        inputs: [{ name: '', type: 'address', internalType: 'address' }],
        outputs: [{ name: '', type: 'bool', internalType: 'bool' }],
        stateMutability: 'view',
    },
    {
        type: 'event',
        name: 'LiquidityBurned',
        inputs: [{ name: 'token', type: 'address', indexed: true, internalType: 'address' }],
        anonymous: false,
    },
    {
        type: 'event',
        name: 'LiquidityRefunded',
        inputs: [{ name: 'token', type: 'address', indexed: true, internalType: 'address' }],
        anonymous: false,
    },
    {
        type: 'event',
        name: 'Socials',
        inputs: [
            { name: 'token', type: 'address', indexed: false, internalType: 'address' },
            { name: 'telegram', type: 'string', indexed: false, internalType: 'string' },
            { name: 'website', type: 'string', indexed: false, internalType: 'string' },
            { name: 'twitter', type: 'string', indexed: false, internalType: 'string' },
            { name: 'logo', type: 'string', indexed: false, internalType: 'string' },
        ],
        anonymous: false,
    },
    {
        type: 'event',
        name: 'TokenCreated',
        inputs: [
            { name: 'token', type: 'address', indexed: true, internalType: 'address' },
            { name: 'deployer', type: 'address', indexed: true, internalType: 'address' },
            { name: 'name', type: 'string', indexed: false, internalType: 'string' },
            { name: 'symbol', type: 'string', indexed: false, internalType: 'string' },
        ],
        anonymous: false,
    },
];
