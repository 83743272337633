export const VistaPairAbi = [
    { type: 'constructor', inputs: [], stateMutability: 'nonpayable' },
    { type: 'fallback', stateMutability: 'payable' },
    {
        type: 'function',
        name: 'DOMAIN_SEPARATOR',
        inputs: [],
        outputs: [{ name: '', type: 'bytes32', internalType: 'bytes32' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'MINIMUM_LIQUIDITY',
        inputs: [],
        outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'PERMIT_TYPEHASH',
        inputs: [],
        outputs: [{ name: '', type: 'bytes32', internalType: 'bytes32' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'Providers',
        inputs: [{ name: '', type: 'address', internalType: 'address' }],
        outputs: [
            { name: 'lp', type: 'uint256', internalType: 'uint256' },
            { name: 'euler0', type: 'uint256', internalType: 'uint256' },
        ],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'allowance',
        inputs: [
            { name: '', type: 'address', internalType: 'address' },
            { name: '', type: 'address', internalType: 'address' },
        ],
        outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'approve',
        inputs: [
            { name: 'spender', type: 'address', internalType: 'address' },
            { name: 'value', type: 'uint256', internalType: 'uint256' },
        ],
        outputs: [{ name: '', type: 'bool', internalType: 'bool' }],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'balanceOf',
        inputs: [{ name: '', type: 'address', internalType: 'address' }],
        outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'burn',
        inputs: [{ name: 'to', type: 'address', internalType: 'address' }],
        outputs: [
            { name: 'amount0', type: 'uint256', internalType: 'uint256' },
            { name: 'amount1', type: 'uint256', internalType: 'uint256' },
        ],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'buyLpFee',
        inputs: [],
        outputs: [{ name: '', type: 'uint8', internalType: 'uint8' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'buyProtocolFee',
        inputs: [],
        outputs: [{ name: '', type: 'uint8', internalType: 'uint8' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'buyTotalFee',
        inputs: [],
        outputs: [{ name: '', type: 'uint8', internalType: 'uint8' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'chatUrl',
        inputs: [],
        outputs: [{ name: '', type: 'string', internalType: 'string' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'claimShare',
        inputs: [],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'creation_time',
        inputs: [],
        outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'creator',
        inputs: [],
        outputs: [{ name: '', type: 'address', internalType: 'address' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'decimals',
        inputs: [],
        outputs: [{ name: '', type: 'uint8', internalType: 'uint8' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'euler',
        inputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
        outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'factory',
        inputs: [],
        outputs: [{ name: '', type: 'address', internalType: 'address' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'fetchMetadata',
        inputs: [],
        outputs: [
            { name: '', type: 'string', internalType: 'string' },
            { name: '', type: 'string', internalType: 'string' },
            { name: '', type: 'string', internalType: 'string' },
            { name: '', type: 'string', internalType: 'string' },
            { name: '', type: 'string', internalType: 'string' },
        ],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'first_mint',
        inputs: [
            { name: 'to', type: 'address', internalType: 'address' },
            { name: 'buyLp', type: 'uint8', internalType: 'uint8' },
            { name: 'sellLp', type: 'uint8', internalType: 'uint8' },
            { name: 'buyProtocol', type: 'uint8', internalType: 'uint8' },
            { name: 'sellProtocol', type: 'uint8', internalType: 'uint8' },
            { name: 'protocolAddress', type: 'address', internalType: 'address' },
        ],
        outputs: [{ name: 'liquidity', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'future_buyLpFee',
        inputs: [],
        outputs: [{ name: '', type: 'uint8', internalType: 'uint8' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'future_buyProtocolFee',
        inputs: [],
        outputs: [{ name: '', type: 'uint8', internalType: 'uint8' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'future_protocol',
        inputs: [],
        outputs: [{ name: '', type: 'address', internalType: 'address' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'future_sellLpFee',
        inputs: [],
        outputs: [{ name: '', type: 'uint8', internalType: 'uint8' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'future_sellProtocolFee',
        inputs: [],
        outputs: [{ name: '', type: 'uint8', internalType: 'uint8' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'getReserves',
        inputs: [],
        outputs: [
            { name: '_reserve0', type: 'uint112', internalType: 'uint112' },
            { name: '_reserve1', type: 'uint112', internalType: 'uint112' },
            { name: '_blockTimestampLast', type: 'uint32', internalType: 'uint32' },
        ],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'imageUrl',
        inputs: [],
        outputs: [{ name: '', type: 'string', internalType: 'string' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'initialize',
        inputs: [
            { name: '_token0', type: 'address', internalType: 'address' },
            { name: '_token1', type: 'address', internalType: 'address' },
        ],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'kLast',
        inputs: [],
        outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'mint',
        inputs: [{ name: 'to', type: 'address', internalType: 'address' }],
        outputs: [{ name: 'liquidity', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'name',
        inputs: [],
        outputs: [{ name: '', type: 'string', internalType: 'string' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'nonces',
        inputs: [{ name: '', type: 'address', internalType: 'address' }],
        outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'payableProtocol',
        inputs: [],
        outputs: [{ name: '', type: 'address', internalType: 'address payable' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'permit',
        inputs: [
            { name: 'owner', type: 'address', internalType: 'address' },
            { name: 'spender', type: 'address', internalType: 'address' },
            { name: 'value', type: 'uint256', internalType: 'uint256' },
            { name: 'deadline', type: 'uint256', internalType: 'uint256' },
            { name: 'v', type: 'uint8', internalType: 'uint8' },
            { name: 'r', type: 'bytes32', internalType: 'bytes32' },
            { name: 's', type: 'bytes32', internalType: 'bytes32' },
        ],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'poolBalance',
        inputs: [],
        outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'price0CumulativeLast',
        inputs: [],
        outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'price1CumulativeLast',
        inputs: [],
        outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'protocol',
        inputs: [],
        outputs: [{ name: '', type: 'address', internalType: 'address' }],
        stateMutability: 'view',
    },
    { type: 'function', name: 'renounce', inputs: [], outputs: [], stateMutability: 'nonpayable' },
    {
        type: 'function',
        name: 'sellLpFee',
        inputs: [],
        outputs: [{ name: '', type: 'uint8', internalType: 'uint8' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'sellProtocolFee',
        inputs: [],
        outputs: [{ name: '', type: 'uint8', internalType: 'uint8' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'sellTotalFee',
        inputs: [],
        outputs: [{ name: '', type: 'uint8', internalType: 'uint8' }],
        stateMutability: 'view',
    },
    { type: 'function', name: 'setFees', inputs: [], outputs: [], stateMutability: 'nonpayable' },
    {
        type: 'function',
        name: 'setMetadata',
        inputs: [
            { name: 'website', type: 'string', internalType: 'string' },
            { name: 'image', type: 'string', internalType: 'string' },
            { name: 'description', type: 'string', internalType: 'string' },
            { name: 'chat', type: 'string', internalType: 'string' },
            { name: 'social', type: 'string', internalType: 'string' },
        ],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'setProtocol',
        inputs: [],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'skim',
        inputs: [{ name: 'to', type: 'address', internalType: 'address' }],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'socialUrl',
        inputs: [],
        outputs: [{ name: '', type: 'string', internalType: 'string' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'startTime_fees',
        inputs: [],
        outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'startTime_protocol',
        inputs: [],
        outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'swap',
        inputs: [
            { name: 'amount0Out', type: 'uint256', internalType: 'uint256' },
            { name: 'amount1Out', type: 'uint256', internalType: 'uint256' },
            { name: 'to', type: 'address', internalType: 'address' },
            { name: 'data', type: 'bytes', internalType: 'bytes' },
        ],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'symbol',
        inputs: [],
        outputs: [{ name: '', type: 'string', internalType: 'string' }],
        stateMutability: 'view',
    },
    { type: 'function', name: 'sync', inputs: [], outputs: [], stateMutability: 'nonpayable' },
    {
        type: 'function',
        name: 'token0',
        inputs: [],
        outputs: [{ name: '', type: 'address', internalType: 'address' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'token1',
        inputs: [],
        outputs: [{ name: '', type: 'address', internalType: 'address' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'tokenDescription',
        inputs: [],
        outputs: [{ name: '', type: 'string', internalType: 'string' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'totalCollected',
        inputs: [],
        outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'totalSupply',
        inputs: [],
        outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'transfer',
        inputs: [
            { name: 'to', type: 'address', internalType: 'address' },
            { name: 'value', type: 'uint256', internalType: 'uint256' },
        ],
        outputs: [{ name: '', type: 'bool', internalType: 'bool' }],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'transferFrom',
        inputs: [
            { name: 'from', type: 'address', internalType: 'address' },
            { name: 'to', type: 'address', internalType: 'address' },
            { name: 'value', type: 'uint256', internalType: 'uint256' },
        ],
        outputs: [{ name: '', type: 'bool', internalType: 'bool' }],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'updateFees',
        inputs: [
            { name: 'buyLpFuture', type: 'uint8', internalType: 'uint8' },
            { name: 'sellLpFuture', type: 'uint8', internalType: 'uint8' },
            { name: 'buyProtocolFuture', type: 'uint8', internalType: 'uint8' },
            { name: 'sellProtocolFuture', type: 'uint8', internalType: 'uint8' },
        ],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'updateProtocol',
        inputs: [{ name: 'protocolFuture', type: 'address', internalType: 'address' }],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'updateProvider',
        inputs: [{ name: 'user', type: 'address', internalType: 'address' }],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'viewShare',
        inputs: [],
        outputs: [{ name: 'share', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'websiteUrl',
        inputs: [],
        outputs: [{ name: '', type: 'string', internalType: 'string' }],
        stateMutability: 'view',
    },
    {
        type: 'event',
        name: 'Approval',
        inputs: [
            { name: 'owner', type: 'address', indexed: true, internalType: 'address' },
            { name: 'spender', type: 'address', indexed: true, internalType: 'address' },
            { name: 'value', type: 'uint256', indexed: false, internalType: 'uint256' },
        ],
        anonymous: false,
    },
    {
        type: 'event',
        name: 'Burn',
        inputs: [
            { name: 'sender', type: 'address', indexed: true, internalType: 'address' },
            { name: 'amount0', type: 'uint256', indexed: false, internalType: 'uint256' },
            { name: 'amount1', type: 'uint256', indexed: false, internalType: 'uint256' },
            { name: 'to', type: 'address', indexed: true, internalType: 'address' },
        ],
        anonymous: false,
    },
    {
        type: 'event',
        name: 'Mint',
        inputs: [
            { name: 'sender', type: 'address', indexed: true, internalType: 'address' },
            { name: 'amount0', type: 'uint256', indexed: false, internalType: 'uint256' },
            { name: 'amount1', type: 'uint256', indexed: false, internalType: 'uint256' },
        ],
        anonymous: false,
    },
    {
        type: 'event',
        name: 'Swap',
        inputs: [
            { name: 'sender', type: 'address', indexed: true, internalType: 'address' },
            { name: 'amount0In', type: 'uint256', indexed: false, internalType: 'uint256' },
            { name: 'amount1In', type: 'uint256', indexed: false, internalType: 'uint256' },
            { name: 'amount0Out', type: 'uint256', indexed: false, internalType: 'uint256' },
            { name: 'amount1Out', type: 'uint256', indexed: false, internalType: 'uint256' },
            { name: 'to', type: 'address', indexed: true, internalType: 'address' },
        ],
        anonymous: false,
    },
    {
        type: 'event',
        name: 'Sync',
        inputs: [
            { name: 'reserve0', type: 'uint112', indexed: false, internalType: 'uint112' },
            { name: 'reserve1', type: 'uint112', indexed: false, internalType: 'uint112' },
        ],
        anonymous: false,
    },
    {
        type: 'event',
        name: 'Transfer',
        inputs: [
            { name: 'from', type: 'address', indexed: true, internalType: 'address' },
            { name: 'to', type: 'address', indexed: true, internalType: 'address' },
            { name: 'value', type: 'uint256', indexed: false, internalType: 'uint256' },
        ],
        anonymous: false,
    },
];
