import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ContractFunctionExecutionError, formatEther } from 'viem';
import { useAccount, useWriteContract } from 'wagmi';
import AssetInput from '../../components/inputs/AssetInput';
import { FormattedNumber } from '../../components/primitives/FormattedNumber';
import { useTokenBalance } from '../../hooks/useTokenBalance';
import { useSharedDependencies } from '../../providers/SharedDependencyProvider';
import { TokenAbi } from '../../shared/abi/Token';
import { VistaRouterAbi } from '../../shared/abi/VistaRouter';
import { useRootStore } from '../../store/root';
import { Pool } from '../../types/Pools/Pool';
import { Token } from '../../types/Token';
import { fromBigInt, MAX_UINT256, toBigInt } from '../../utils/tokenUtils';
import { checkRequiresApproval } from '../../utils/transactionUtils';

interface TokenSwapSellPanelProps {
    token: Token;
    pool: Pool;
    slippageValue: number;
    autoSlippage: boolean;
}

const TokenSwapSellPanel = ({
    token,
    pool,
    slippageValue,
    autoSlippage,
}: TokenSwapSellPanelProps) => {
    const { isConnected, address } = useAccount();
    const { writeContractAsync } = useWriteContract();
    const balance = useTokenBalance(token.address);

    const { routerService, tokenService } = useSharedDependencies();
    const [currentNetworkConfig] = useRootStore((store) => [store.currentNetworkConfig]);

    const [token0Amount, setToken0Amount] = React.useState('');
    const [token1Amount, setToken1Amount] = useState('');

    useEffect(() => {
        const getAmountsOut = async () => {
            if (parseFloat(token0Amount) > 0) {
                const token0AmountBigInt = toBigInt(token0Amount, token.decimals);
                const amount = await routerService.getAmountsOut(
                    currentNetworkConfig,
                    token0AmountBigInt,
                    [token.address, currentNetworkConfig.addresses.BASE_ASSET_ADDRESS],
                );
                const amountStr = formatEther(amount);
                setToken1Amount(amountStr);
            } else {
                setToken1Amount('');
            }
        };

        if (token0Amount && token) getAmountsOut();
    }, [token0Amount, currentNetworkConfig, routerService, token]);

    const handlePreset = (preset: number) => {
        const presetBigInt = BigInt(Math.floor(preset * 100));
        const balanceBigInt = BigInt(balance);
        const amountOfBalance = (balanceBigInt * presetBigInt) / BigInt(100);
        setToken0Amount(fromBigInt(amountOfBalance, token.decimals));
    };

    const handleSwap = () => {
        const swap = async () => {
            if (!isConnected || !address) {
                toast.error('Connect your wallet');
                return;
            }

            const token0AmountBigInt = toBigInt(token0Amount, token.decimals);
            const token1AmountBigInt = toBigInt(
                token1Amount,
                currentNetworkConfig.baseAssetDecimals,
            );

            let finalSlippageValue = slippageValue;

            if (autoSlippage) {
                finalSlippageValue = Number(pool.pairSellLpFee);
            }

            const token1Min =
                token1AmountBigInt - (token1AmountBigInt * BigInt(finalSlippageValue)) / 100n;

            const deadline = Math.floor(Date.now() / 1000) + 60 * 20;

            try {
                const allowance = await tokenService.allowance(
                    currentNetworkConfig,
                    address,
                    token.address,
                    currentNetworkConfig.addresses.NATIVE_ROUTER_ADDRESS,
                );

                const approvalRequired = checkRequiresApproval({
                    approvedAmount: allowance,
                    amount: token0AmountBigInt,
                });

                if (approvalRequired) {
                    await writeContractAsync({
                        address: token.address as `0x${string}`,
                        abi: TokenAbi,
                        functionName: 'approve',
                        args: [currentNetworkConfig.addresses.NATIVE_ROUTER_ADDRESS, MAX_UINT256],
                    });

                    toast.info('Approval in progress, please wait');

                    await new Promise((resolve) => setTimeout(resolve, 5000));
                }

                await writeContractAsync({
                    address: currentNetworkConfig.addresses.NATIVE_ROUTER_ADDRESS as `0x${string}`,
                    abi: VistaRouterAbi,
                    functionName: 'swapExactTokensForETHSupportingFeeOnTransferTokens',
                    args: [
                        token0AmountBigInt,
                        token1Min > 0 ? token1Min : 0,
                        [token.address, currentNetworkConfig.addresses.BASE_ASSET_ADDRESS],
                        address,
                        deadline,
                    ],
                    value: pool.pairSellLpFeeInEth,
                });

                toast.success('Swap successful');
            } catch (error) {
                console.error(error);

                if (error instanceof ContractFunctionExecutionError) {
                    toast.error(error.cause.shortMessage);
                } else {
                    toast.error('Transaction failed, check console for details');
                }
            }
        };
        swap();
    };

    return (
        <Box
            display="flex"
            flexDirection="column">
            <Box width="100%">
                <AssetInput
                    token={token}
                    value={token0Amount}
                    setAmount={setToken1Amount}
                />
            </Box>
            <Box
                display="flex"
                flexDirection="row">
                <Typography sx={{ color: 'black', pr: 1 }}>Receive: </Typography>{' '}
                <FormattedNumber
                    sx={{ color: 'black' }}
                    value={token1Amount}
                    symbol={'ETH'}
                    visibleDecimals={7}
                />
            </Box>
            <Box sx={{ p: 2, border: '1px inset' }}>
                <Button
                    sx={{ m: 1 }}
                    variant="windows"
                    onClick={() => handlePreset(0.25)}>
                    25%
                </Button>
                <Button
                    sx={{ m: 1 }}
                    variant="windows"
                    onClick={() => handlePreset(0.5)}>
                    50%{' '}
                </Button>
                <Button
                    sx={{ m: 1 }}
                    variant="windows"
                    onClick={() => handlePreset(0.75)}>
                    75%{' '}
                </Button>
                <Button
                    sx={{ m: 1 }}
                    variant="windows"
                    onClick={() => handlePreset(1)}>
                    100%{' '}
                </Button>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 1 }}>
                <Button
                    variant="windows"
                    onClick={() => handleSwap()}>
                    Swap
                </Button>
            </Box>
        </Box>
    );
};
export default TokenSwapSellPanel;
