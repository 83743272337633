import { useQuery } from '@tanstack/react-query';
import { useSharedDependencies } from '../providers/SharedDependencyProvider';
import { queryKeysFactory } from '../shared/QueryKeysFactory';
import { useRootStore } from '../store/root';

export const useTokenData = (contractAddress: string) => {
    const { uiDataService } = useSharedDependencies();
    const currentNetworkConfig = useRootStore((store) => store.currentNetworkConfig);

    const query = useQuery({
        queryFn: () =>
            uiDataService.GetTokenMetadataHumanized(currentNetworkConfig, contractAddress),
        queryKey: queryKeysFactory.tokenMetdata(contractAddress),
        enabled: !!contractAddress,
        select: (data) => data,
    });

    return query;
};
