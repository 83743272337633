import React from 'react';
import { Route, Routes as Switch } from 'react-router-dom';
import Home from './pages';
import NotFound from './pages/NotFound';
import Token from './pages/token';
import { PageWithLayout } from './types';

const Routes: React.FC = () => {
    const renderWithLayout = (Component: PageWithLayout) => {
        const getLayout = Component.getLayout ?? ((page: React.ReactNode) => page);
        return getLayout(<Component />);
    };

    return (
        <Switch>
            <Route
                path="/"
                element={renderWithLayout(Home)}
            />
            <Route
                path="*"
                element={<NotFound />}
            />
            <Route
                path="/token/:contractAddress"
                element={renderWithLayout(Token)}
            />
        </Switch>
    );
};

export default Routes;
