import { arbitrum, mainnet } from 'viem/chains';
import { ChainId } from '../shared/ChainId';
import { NetworkConfig } from '../types/NetworkConfig';
import { Network } from '../types/Web3/Network';

export type BaseNetworkConfig = Omit<NetworkConfig, 'explorerLinkBuilder'>;

export const networkConfigs: {
    [key in keyof typeof Network]: BaseNetworkConfig;
} = {
    [Network.proto_mainnet]: {
        name: 'Mainnet',
        network: Network.proto_mainnet,
        dexToolsPrefix: 'ether',
        privateJsonRPCUrl: 'https://eth-mainnet.g.alchemy.com/v2/vJZLBQNAYizRVCl6gnsmeXVHHLouD95n',
        privateJsonRPCWSUrl: 'wss://eth-mainnet.g.alchemy.com/v2/vJZLBQNAYizRVCl6gnsmeXVHHLouD95n',
        publicJsonRPCUrl: ['https://eth-mainnet.g.alchemy.com/v2/vJZLBQNAYizRVCl6gnsmeXVHHLouD95n'],
        explorerLink: 'https://etherscan.io/',
        chainId: ChainId.Mainnet,
        chain: mainnet,
        isTestnet: false,
        isAvailable: true,
        networkLogoPath: '/images/icons/networks/ethereum.svg',
        baseAssetSymbol: 'ETH',
        wrappedBaseAssetSymbol: 'WETH',
        baseAssetDecimals: 18,
        enabledFeatures: {
            swap: true,
            staking: false,
            faucet: false,
            wrap: false,
        },
        addresses: {
            XP_TOKEN_ADDRESS: '0x306a4b5b553c7d5Cd4440e1cF4F897521c38cdaA',
            BASE_ASSET_ADDRESS: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
            XP_FACTORY_ADDRESS: '0x6c5cf7036b0dE9b3AC388fbBe3f5fA5DfA57552e',
            XP_STAKING_ADDRESS: '0x1D84F81a014dD217F8d8e8ee984ECdeA26b0bA99',
            NATIVE_FACTORY_ADDRESS: '0x787cCAa7dFD93c4BaB69633B223718acc6D01fD2',
            NATIVE_ROUTER_ADDRESS: '0x0EF9fA37Ff64F8e08a408f0885f1Fa083F5873Fa',
            XP_LP_ADDRESS: '0x793616567F36A935daB3728Ff67a7d650a4278Fd',
            HARDLOCK_ADDRESS: '0xe79cC4E6AE0Ec21bE73303b2Fe285e1f56631a63',
        },
        topTokens: [
            {
                image: '/images/icons/tokens/eth.png',
                name: 'Ethereum',
                symbol: 'ETH',
                contractAddress: '0x0',
                isBase: true,
            },
            {
                image: '/images/icons/tokens/xp.png',
                name: 'XP',
                symbol: 'XP',
                contractAddress: '0x306a4b5b553c7d5Cd4440e1cF4F897521c38cdaA',
                isBase: false,
            },
        ],
    },
    [Network.proto_arbitrum]: {
        name: 'Arbitrum',
        network: Network.proto_arbitrum,
        dexToolsPrefix: 'arbitrum',
        privateJsonRPCUrl: 'https://arb-mainnet.g.alchemy.com/v2/vJZLBQNAYizRVCl6gnsmeXVHHLouD95n',
        privateJsonRPCWSUrl: 'wss://arb-mainnet.g.alchemy.com/v2/vJZLBQNAYizRVCl6gnsmeXVHHLouD95n',
        publicJsonRPCUrl: ['https://arb-mainnet.g.alchemy.com/v2/vJZLBQNAYizRVCl6gnsmeXVHHLouD95n'],
        explorerLink: 'https://arbiscan.io/',
        chainId: ChainId.Arbitrum,
        chain: arbitrum,
        isTestnet: false,
        isAvailable: true,
        networkLogoPath: '/images/icons/networks/arbitrum.svg',
        baseAssetSymbol: 'ETH',
        wrappedBaseAssetSymbol: 'WETH',
        baseAssetDecimals: 18,
        enabledFeatures: {
            swap: true,
            staking: false,
            faucet: false,
            wrap: false,
        },
        addresses: {
            XP_TOKEN_ADDRESS: '0x1777AF5a16dfc2C9288A0F235e33AA4FBF9A58f8',
            BASE_ASSET_ADDRESS: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
            XP_FACTORY_ADDRESS: '0x99e93F6a832e548361cd3850BF5D6BdBc2180e09',
            XP_STAKING_ADDRESS: '0x877B48D370fC1830d834776EC1A542803db261d9',
            NATIVE_FACTORY_ADDRESS: '0x9aDcF333F05566bf7E06F1484A733CEbae7B7369',
            NATIVE_ROUTER_ADDRESS: '0x0E3C2E6C5617108EB5A4a4E9Af81F06B25271925',
            XP_LP_ADDRESS: '0x2fc2753e926aeba8d6feb244329755f27b0d06d7',
            HARDLOCK_ADDRESS: '0x1b35731ae3c0C8FeAAa813d620DF9a28FBB96A90',
        },
        topTokens: [
            {
                image: '/images/icons/tokens/eth.png',
                name: 'Ethereum',
                symbol: 'ETH',
                contractAddress: '0x0',
                isBase: true,
            },
            {
                image: '/images/icons/tokens/xp.png',
                name: 'XP',
                symbol: 'XP',
                contractAddress: '0x1777AF5a16dfc2C9288A0F235e33AA4FBF9A58f8',
                isBase: false,
            },
        ],
    },
} as const;
