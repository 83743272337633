import { useQuery } from '@tanstack/react-query';
import { getBalance } from '@wagmi/core';
import { useEffect, useState } from 'react';
import { useAccount, useConfig } from 'wagmi';
import { useSharedDependencies } from '../providers/SharedDependencyProvider';
import { queryKeysFactory } from '../shared/QueryKeysFactory';
import { useRootStore } from '../store/root';

export const useTokenBalance = (contractAddress: string): bigint => {
    const { isConnected, address, chain } = useAccount();
    const config = useConfig();
    const [nativeBalance, setNativeBalance] = useState<bigint>(0n);

    const { tokenService } = useSharedDependencies();
    const [currentNetworkConfig] = useRootStore((root) => [root.currentNetworkConfig]);

    // Fetch native token (ETH, BNB, etc.) balance
    useEffect(() => {
        if (contractAddress === '0x0' && isConnected && address) {
            // Fetch the native balance using wagmi's getBalance function
            getBalance(config, { address, chainId: chain?.id })
                .then((data: { value: bigint }) => {
                    setNativeBalance(data.value);
                })
                .catch((error: unknown) => {
                    console.error('Failed to fetch native balance:', error);
                    setNativeBalance(0n);
                });
        }
    }, [contractAddress, isConnected, address, config, chain?.id]);

    const query = useQuery({
        queryFn: () => tokenService.balanceOf(currentNetworkConfig, contractAddress, address || ''),
        queryKey: queryKeysFactory.tokenBalance(contractAddress, address || ''),
        enabled: contractAddress !== '0x0' && !!contractAddress && !!address,
        select: (data) => data, // You can modify this if needed
    });

    if (contractAddress === '0x0') {
        return nativeBalance;
    }

    return query.data || 0n;
};
