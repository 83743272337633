import { PublicClient } from 'viem';
import { VistaFactoryAbi } from '../shared/abi/VistaFactory';
import { NetworkConfig } from '../types/NetworkConfig';
import { Network } from '../types/Web3/Network';
import { TokenFactoryAbi } from '../shared/abi/TokenFactory';
import { GetTokenDataDto } from '../types/DTO/GetTokenDataDto';
import { GetTokenFeesDto } from '../types/DTO/GetTokenFeesDto';

export class TokenFactoryService {
    constructor(private readonly getProvider: (network: Network) => PublicClient) {}

    public async getTokenData(
        networkConfig: NetworkConfig,
        contractAddress: string,
    ): Promise<[bigint, number]> {
        try {
            const client = this.getProvider(networkConfig.network);

            const contract = {
                address: networkConfig.addresses.XP_FACTORY_ADDRESS as `0x${string}`,
                abi: TokenFactoryAbi,
            } as const;

            const result = await client.readContract({
                ...contract,
                functionName: 'getTokenData',
                args: [contractAddress],
            });

            const data = result as [bigint, number];

            return data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    public async getTokenFees(
        networkConfig: NetworkConfig,
        contractAddress: string,
    ): Promise<bigint[]> {
        try {
            const client = this.getProvider(networkConfig.network);

            const contract = {
                address: networkConfig.addresses.XP_FACTORY_ADDRESS as `0x${string}`,
                abi: TokenFactoryAbi,
            } as const;

            const result = await client.readContract({
                ...contract,
                functionName: 'getTokenFees',
                args: [contractAddress],
            });

            const data = result as bigint[];
            console.log(data);

            return data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }
}
