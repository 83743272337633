import { NetworkConfig } from '../types/NetworkConfig';

export const queryKeysFactory = {
    user: (user: string) => [user],
    tokenMetdata: (contractAddress: string) => [contractAddress, 'tokenMetadata'],
    topTokens: (networkConfig: NetworkConfig) => [networkConfig, 'topTokens'],
    tokens: () => ['tokens'],
    network: (networkConfig: NetworkConfig) => [networkConfig.chainId, networkConfig.network],
    stakeTokenBalance: (user: string, networkConfig: NetworkConfig) => [
        ...queryKeysFactory.user(user),
        ...queryKeysFactory.network(networkConfig),
        'stakeTokenBalance',
    ],
    stakedAmount: (user: string, networkConfig: NetworkConfig) => [
        ...queryKeysFactory.user(user),
        ...queryKeysFactory.network(networkConfig),
        'stakedAmount',
    ],
    tokenBalance: (contractAddress: string, address: string) => [
        ...queryKeysFactory.user(address),
        contractAddress,
        'tokenBalance',
    ],
    approvedAmount: (
        user: string,
        token: string,
        spender: string,
        networkConfig: NetworkConfig,
    ) => [
        ...queryKeysFactory.user(user),
        ...queryKeysFactory.network(networkConfig),
        token,
        spender,
        'approvedAmount',
    ],
};

export const POLLING_INTERVAL = 60000;
