import { StateCreator } from 'zustand';
import { RootStore } from './root';
import { SlippageMode } from '../types/Pools/SlippageMode';

export interface PoolSlice {
    slippageMode: SlippageMode;
    slippageValue: number;
    setSlippageMode: (mode: SlippageMode) => void;
    setSlippageValue: (value: number) => void;
}

export const createPoolSlice: StateCreator<
    RootStore,
    [['zustand/subscribeWithSelector', never], ['zustand/devtools', never]],
    [],
    PoolSlice
> = (set, get) => {
    const initialSlippageMode = SlippageMode.auto;
    const initialSlippageValue = 1;
    return {
        slippageMode: initialSlippageMode,
        slippageValue: initialSlippageValue,
        setSlippageMode(mode) {
            set({ slippageMode: mode });
        },
        setSlippageValue(value) {
            set({ slippageValue: value });
        },
    };
};
