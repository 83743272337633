export const VistaRouterAbi = [
    {
        type: 'constructor',
        inputs: [
            { name: '_factory', type: 'address', internalType: 'address' },
            { name: '_WETH', type: 'address', internalType: 'address' },
            { name: '_oracleAddress', type: 'address', internalType: 'address' },
            { name: '_teamWallet', type: 'address', internalType: 'address' },
            { name: '_stakingContractXpLp', type: 'address', internalType: 'address' },
            { name: '_stakingContractXp', type: 'address', internalType: 'address' },
        ],
        stateMutability: 'nonpayable',
    },
    { type: 'receive', stateMutability: 'payable' },
    {
        type: 'function',
        name: 'WETH',
        inputs: [],
        outputs: [{ name: '', type: 'address', internalType: 'address' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'addExemptFromFee',
        inputs: [{ name: '_address', type: 'address', internalType: 'address' }],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'addLiquidityETH',
        inputs: [
            { name: 'token', type: 'address', internalType: 'address' },
            { name: 'amountTokenDesired', type: 'uint256', internalType: 'uint256' },
            { name: 'amountTokenMin', type: 'uint256', internalType: 'uint256' },
            { name: 'amountETHMin', type: 'uint256', internalType: 'uint256' },
            { name: 'deadline', type: 'uint256', internalType: 'uint256' },
        ],
        outputs: [
            { name: 'amountToken', type: 'uint256', internalType: 'uint256' },
            { name: 'amountETH', type: 'uint256', internalType: 'uint256' },
            { name: 'liquidity', type: 'uint256', internalType: 'uint256' },
        ],
        stateMutability: 'payable',
    },
    {
        type: 'function',
        name: 'canUpdateTokenFactory',
        inputs: [],
        outputs: [{ name: '', type: 'address', internalType: 'address' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'exemptFromFee',
        inputs: [{ name: '', type: 'address', internalType: 'address' }],
        outputs: [{ name: '', type: 'bool', internalType: 'bool' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'factory',
        inputs: [],
        outputs: [{ name: '', type: 'address', internalType: 'address' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'getAmountIn',
        inputs: [
            { name: 'amountOut', type: 'uint256', internalType: 'uint256' },
            { name: 'reserveIn', type: 'uint256', internalType: 'uint256' },
            { name: 'reserveOut', type: 'uint256', internalType: 'uint256' },
        ],
        outputs: [{ name: 'amountIn', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'pure',
    },
    {
        type: 'function',
        name: 'getAmountOut',
        inputs: [
            { name: 'amountIn', type: 'uint256', internalType: 'uint256' },
            { name: 'reserveIn', type: 'uint256', internalType: 'uint256' },
            { name: 'reserveOut', type: 'uint256', internalType: 'uint256' },
        ],
        outputs: [{ name: 'amountOut', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'pure',
    },
    {
        type: 'function',
        name: 'getAmountsIn',
        inputs: [
            { name: 'amountOut', type: 'uint256', internalType: 'uint256' },
            { name: 'path', type: 'address[]', internalType: 'address[]' },
        ],
        outputs: [{ name: 'amounts', type: 'uint256[]', internalType: 'uint256[]' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'getAmountsOut',
        inputs: [
            { name: 'amountIn', type: 'uint256', internalType: 'uint256' },
            { name: 'path', type: 'address[]', internalType: 'address[]' },
        ],
        outputs: [{ name: 'amounts', type: 'uint256[]', internalType: 'uint256[]' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'hardstake',
        inputs: [
            { name: '_contract', type: 'address', internalType: 'address' },
            { name: '_token', type: 'address', internalType: 'address' },
            { name: '_amount', type: 'uint256', internalType: 'uint256' },
        ],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'launch',
        inputs: [
            { name: 'token', type: 'address', internalType: 'address' },
            { name: 'amountTokenDesired', type: 'uint256', internalType: 'uint256' },
            { name: 'amountTokenMin', type: 'uint256', internalType: 'uint256' },
            { name: 'amountETHMin', type: 'uint256', internalType: 'uint256' },
            { name: 'buyLpFee', type: 'uint8', internalType: 'uint8' },
            { name: 'sellLpFee', type: 'uint8', internalType: 'uint8' },
            { name: 'buyProtocolFee', type: 'uint8', internalType: 'uint8' },
            { name: 'sellProtocolFee', type: 'uint8', internalType: 'uint8' },
            { name: 'protocolAddress', type: 'address', internalType: 'address' },
        ],
        outputs: [
            { name: 'amountToken', type: 'uint256', internalType: 'uint256' },
            { name: 'amountETH', type: 'uint256', internalType: 'uint256' },
            { name: 'liquidity', type: 'uint256', internalType: 'uint256' },
        ],
        stateMutability: 'payable',
    },
    {
        type: 'function',
        name: 'quote',
        inputs: [
            { name: 'amountA', type: 'uint256', internalType: 'uint256' },
            { name: 'reserveA', type: 'uint256', internalType: 'uint256' },
            { name: 'reserveB', type: 'uint256', internalType: 'uint256' },
        ],
        outputs: [{ name: 'amountB', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'pure',
    },
    {
        type: 'function',
        name: 'removeLiquidityETHSupportingFeeOnTransferTokens',
        inputs: [
            { name: 'token', type: 'address', internalType: 'address' },
            { name: 'liquidity', type: 'uint256', internalType: 'uint256' },
            { name: 'amountTokenMin', type: 'uint256', internalType: 'uint256' },
            { name: 'amountETHMin', type: 'uint256', internalType: 'uint256' },
            { name: 'deadline', type: 'uint256', internalType: 'uint256' },
        ],
        outputs: [{ name: 'amountETH', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'safeTransferLp',
        inputs: [
            { name: '_token', type: 'address', internalType: 'address' },
            { name: 'to', type: 'address', internalType: 'address' },
            { name: '_amount', type: 'uint256', internalType: 'uint256' },
        ],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'setTokenFactory',
        inputs: [{ name: '_tokenFactory', type: 'address', internalType: 'address' }],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'stakingContractXp',
        inputs: [],
        outputs: [{ name: '', type: 'address', internalType: 'address payable' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'stakingContractXpLp',
        inputs: [],
        outputs: [{ name: '', type: 'address', internalType: 'address payable' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'swapExactETHForTokensSupportingFeeOnTransferTokens',
        inputs: [
            { name: 'amountOutMin', type: 'uint256', internalType: 'uint256' },
            { name: 'path', type: 'address[]', internalType: 'address[]' },
            { name: 'to', type: 'address', internalType: 'address' },
            { name: 'deadline', type: 'uint256', internalType: 'uint256' },
        ],
        outputs: [],
        stateMutability: 'payable',
    },
    {
        type: 'function',
        name: 'swapExactTokensForETHSupportingFeeOnTransferTokens',
        inputs: [
            { name: 'amountIn', type: 'uint256', internalType: 'uint256' },
            { name: 'amountOutMin', type: 'uint256', internalType: 'uint256' },
            { name: 'path', type: 'address[]', internalType: 'address[]' },
            { name: 'to', type: 'address', internalType: 'address' },
            { name: 'deadline', type: 'uint256', internalType: 'uint256' },
        ],
        outputs: [],
        stateMutability: 'payable',
    },
    {
        type: 'function',
        name: 'teamWallet',
        inputs: [],
        outputs: [{ name: '', type: 'address', internalType: 'address payable' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'tokenFactory',
        inputs: [],
        outputs: [{ name: '', type: 'address', internalType: 'address' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'treasury',
        inputs: [],
        outputs: [{ name: '', type: 'address', internalType: 'address payable' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'updateSelf',
        inputs: [{ name: '_token', type: 'address', internalType: 'address' }],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'usdcToEth',
        inputs: [{ name: 'usdcAmount', type: 'uint256', internalType: 'uint256' }],
        outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'view',
    },
];
