import { NetworkConfig } from '../types/NetworkConfig';
import { getProvider } from '../utils/protocolUtils';
import { TokenService } from './TokenService';

export class ApprovedAmountService {
    private async getTokenService(networkConfig: NetworkConfig) {
        // const provider = this.getProvider(marketData.chainId);
        const tokenService = new TokenService(getProvider);
        return tokenService;
    }

    async getApprovedAmount(
        networkConfig: NetworkConfig,
        user: string,
        token: string,
        spender: string,
    ): Promise<bigint> {
        const service = await this.getTokenService(networkConfig);
        return service.allowance(networkConfig, user, token, spender);
    }
}
