import { Box, Link, Typography } from '@mui/material';
import React from 'react';
import { CompactableTypography } from '../../components/CompactableTypography';
import WindowsContentContainer from '../../components/containers/WindowsContentContainer';
import { GenericTokenIcon } from '../../components/images/GenericTokenIcon';
import { useTokenDetails } from '../../hooks/useTokenDetails';
import ContractAddress from '../../components/ContractAddress';

interface TokenInfoHeaderProps {
    contractAddress: string;
}

const TokenInfoHeader: React.FC<TokenInfoHeaderProps> = ({ contractAddress }) => {
    const { data, error, isError, isLoading } = useTokenDetails(contractAddress);

    if (error) {
        console.error('Error loading token details', error);
    }

    return (
        <WindowsContentContainer title="Token Info">
            {isLoading && <Typography sx={{ color: 'black' }}> Loading Data </Typography>}

            {isError && <Typography sx={{ color: 'red' }}> Error Loading Data </Typography>}

            {data && (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap', // Ensure wrapping on smaller screens
                    }}>
                    {/* Left Side - Image and Info */}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ marginRight: '16px' }}>
                            {data.img_url ? (
                                <img
                                    src={data.img_url}
                                    alt={data.name}
                                    style={{
                                        width: '50px',
                                        height: '50px',
                                    }}
                                />
                            ) : (
                                <GenericTokenIcon sx={{ width: '50px', height: '50px' }} />
                            )}
                        </Box>
                        <Box sx={{ maxWidth: '300px' }}>
                            <Typography sx={{ color: 'black', fontWeight: 'bold' }}>
                                {data.name} | {data.symbol}
                            </Typography>
                            <ContractAddress address={data.contract_address} />
                            <Box
                                display="flex"
                                flexDirection="row">
                                <Typography sx={{ color: 'black', pr: 1 }}>Deployed by:</Typography>
                                <CompactableTypography sx={{ color: 'black' }}>
                                    {data.deployer || 'Unknown'}
                                </CompactableTypography>
                            </Box>

                            <Typography sx={{ color: 'black' }}>{data.description}</Typography>
                        </Box>
                    </Box>

                    {/* Right Side - Links */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                        {data.web_url && (
                            <Link
                                href={
                                    data.web_url.startsWith('http')
                                        ? data.web_url
                                        : `https://${data.web_url}`
                                }
                                target="_blank"
                                rel="noopener"
                                sx={{ color: 'blue', textDecoration: 'none' }}>
                                Website
                            </Link>
                        )}
                        {data.twitter_url && (
                            <Link
                                href={
                                    data.twitter_url.startsWith('http')
                                        ? data.twitter_url
                                        : `https://${data.twitter_url}`
                                }
                                target="_blank"
                                rel="noopener"
                                sx={{ color: 'blue', textDecoration: 'none' }}>
                                Twitter
                            </Link>
                        )}
                        {data.discord_url && (
                            <Link
                                href={
                                    data.discord_url.startsWith('http')
                                        ? data.discord_url
                                        : `https://${data.discord_url}`
                                }
                                target="_blank"
                                rel="noopener"
                                sx={{ color: 'blue', textDecoration: 'none' }}>
                                Telegram
                            </Link>
                        )}
                    </Box>
                </Box>
            )}
        </WindowsContentContainer>
    );
};

export default TokenInfoHeader;
