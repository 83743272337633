import { Box } from '@mui/material';
import { switchChain } from '@wagmi/core';
import React, { useEffect } from 'react';
import { useAccount, useConfig } from 'wagmi';
import CreateTokenButton from '../components/buttons/CreateTokenButton';
import ContentContainer from '../components/containers/ContentContainer';
import MainLayout from '../layout/MainLayout';
import Controller from '../modules/controller/Controller';
import TokenActivityPanel from '../modules/TokenActivityPanel';
import { useRootStore } from '../store/root';
import { PageWithLayout } from '../types';

const Home: PageWithLayout = () => {
    const { chainId, isConnected } = useAccount();
    const config = useConfig();

    const [currentNetworkConfig] = useRootStore((store) => [store.currentNetworkConfig]);

    useEffect(() => {
        if (chainId !== currentNetworkConfig.chainId && isConnected) {
            switchChain(config, {
                chainId: currentNetworkConfig.chainId,
            });
        }
    }, [chainId, currentNetworkConfig.chainId, config, isConnected]);

    return (
        <>
            <ContentContainer>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: { xs: 'center', xsm: 'flex-start' },
                        mt: 2,
                    }}>
                    <Box sx={{ display: 'flex', pt: 3, justifyContent: 'center' }}>
                        <CreateTokenButton />
                    </Box>
                    <Box sx={{ pt: 2 }}>
                        <Controller />
                    </Box>
                </Box>
            </ContentContainer>
            <ContentContainer>
                <Box
                    sx={{
                        pt: 5,
                        minWidth: '100%',
                    }}>
                    <TokenActivityPanel />
                </Box>
            </ContentContainer>
        </>
    );
};

Home.getLayout = (page: React.ReactNode) => <MainLayout>{page}</MainLayout>;

export default Home;
