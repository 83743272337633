import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useModalContext } from '../../hooks/useModalContext';

const CreateTokenButton = () => {
    const { openCreateToken } = useModalContext();

    return (
        <Button
            onClick={openCreateToken}
            sx={{
                p: '7px 8px',
                minWidth: 'unset',
                ml: 2,
                '&:hover': {
                    backgroundColor: 'rgba(0, 123, 255, 0.5)',
                },
            }}>
            <Box sx={{ flexDirection: 'column' }}>
                <img
                    src="/images/icons/ui/Setup.png"
                    alt="Setup"
                    width="75"
                />
                <Box sx={{ background: 'rgba(255, 255, 255, .9)', p: '1px' }}>
                    <Typography
                        variant="h3"
                        color="black">
                        {' '}
                        [Create Token]{' '}
                    </Typography>
                </Box>
            </Box>
        </Button>
    );
};

export default CreateTokenButton;
