import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import React, { ReactNode, useMemo } from 'react';
import { getDesignTokens, getThemedComponents } from '../theme';

export const ColorModeContext = React.createContext({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    toggleColorMode: () => {},
});

/**
 * Main Layout component which wrapps around the whole app
 * @param param0
 * @returns
 */
export function AppGlobalStyles({ children }: { children: ReactNode }) {
    const mode = 'light';
    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => null,
        }),
        [],
    );

    const theme = useMemo(() => {
        const themeCreate = createTheme(getDesignTokens(mode));
        return deepmerge(themeCreate, getThemedComponents(themeCreate));
    }, [mode]);

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                {children}
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}
