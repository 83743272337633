import { Grid2 } from '@mui/material';
import React, { useEffect } from 'react';
import WindowsContentContainer from '../components/containers/WindowsContentContainer';
import { useTokens } from '../hooks/useTokens';
import { useRootStore } from '../store/root';
import TokenActivityInfo from './TokenActivityInfo';

const TokenActivityPanel: React.FC = () => {
    const events = useRootStore((root) => root.events);
    const { data, refetch, error } = useTokens();

    if (error) {
        console.error('Error loading token details', error);
    }

    useEffect(() => {
        if (events.length > 0) {
            console.log('new token');
            refetch();
        }
    }, [events, refetch]);

    return (
        <WindowsContentContainer title={'Tokens'}>
            {data && (
                <Grid2
                    container
                    spacing={2}>
                    {data.tokens.map((item, index) => (
                        <Grid2
                            size={{ xsm: 6, sm: 3 }}
                            key={index}>
                            <TokenActivityInfo Token={item} />
                        </Grid2>
                    ))}
                </Grid2>
            )}
        </WindowsContentContainer>
    );
};

export default TokenActivityPanel;
