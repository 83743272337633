import { StateCreator } from 'zustand';
import { RootStore } from './root';

export enum ApprovalMethod {
    APPROVE = 'Transaction',
    PERMIT = 'Signed message',
}

export interface WalletSlice {
    account: string;
    accountLoading: boolean;
    setAccount: (account: string | undefined) => void;
    setAccountLoading: (loading: boolean) => void;
}

export const createWalletSlice: StateCreator<
    RootStore,
    [['zustand/subscribeWithSelector', never], ['zustand/devtools', never]],
    [],
    WalletSlice
> = (set, get) => {
    return {
        account: '',
        accountLoading: false,
        setAccount(account) {
            set({ account: account || '' });
        },
        setAccountLoading(loading) {
            set({ accountLoading: loading });
        },
    };
};
