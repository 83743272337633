import { Box, CssBaseline } from '@mui/material';
import React from 'react';
import { ToastContainerModal } from '../components/modals/ToastContainerModal';
import { AppFooter } from './AppFooter';
import AppHeader from './AppHeader';

const MainLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <>
            <ToastContainerModal />

            <CssBaseline />

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                }}>
                <AppHeader />

                {/* Main content area */}
                <Box
                    sx={{
                        flex: '1 0 auto',
                        overflowY: 'auto',
                    }}>
                    {children}
                </Box>

                {/* Sticky footer */}
                <Box
                    component="footer"
                    sx={{
                        flexShrink: 0,
                        position: 'sticky',
                        marginTop: 'calc(5% + 60px)',
                        bottom: 0,
                        width: '100%',
                    }}>
                    <AppFooter />
                </Box>
            </Box>
        </>
    );
};

export default MainLayout;
