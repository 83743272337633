import {
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    List,
    ListItem,
    TextField,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTokenData } from '../../hooks/useTokenData';
import { useTopTokens } from '../../hooks/useTopTokens';
import { GenericTokenIcon } from '../images/GenericTokenIcon';

interface TokenSelectModalProps {
    open: boolean;
    onClose: () => void;
    onSelectToken: (address: string) => void;
}

const TokenSelectModal: React.FC<TokenSelectModalProps> = ({ open, onClose, onSelectToken }) => {
    const { data: TopTokens } = useTopTokens();

    const [contractAddress, setContractAddress] = useState('');
    const [timeoutError, setTimeoutError] = useState(false);

    const handleTopTokenSelect = (address: string) => {
        onSelectToken(address);
        setContractAddress('');
        onClose();
    };

    const { data: tokenData, error, isLoading } = useTokenData(contractAddress);

    useEffect(() => {
        let timer: NodeJS.Timeout;

        if (contractAddress) {
            timer = setTimeout(() => {
                if (!tokenData && !isLoading) {
                    setTimeoutError(true);
                }
            }, 1000);
        } else {
            setTimeoutError(false);
        }

        return () => clearTimeout(timer);
    }, [contractAddress, tokenData, isLoading]);

    const handleContractAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContractAddress(event.target.value);
        setTimeoutError(false);
    };

    const handleClose = () => {
        setContractAddress('');
        setTimeoutError(false);
        onClose();
    };

    if (!open) return null;

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="xs"
            sx={{ top: '-25%', marginTop: 0 }}
            fullWidth>
            <DialogTitle sx={{ color: 'black' }}>Select a token</DialogTitle>
            <DialogContent>
                <TextField
                    sx={{
                        '& .MuiInputBase-input': {
                            color: 'black', // Additional selector to ensure the input text color
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'black', // Optional: border color
                        },
                        '& .MuiFormLabel-root': {
                            color: 'black', // Optional: label color
                        },
                    }}
                    fullWidth
                    placeholder="Paste Contract Address"
                    variant="outlined"
                    margin="normal"
                    onChange={handleContractAddressChange}
                />
                {contractAddress.length > 0 ? (
                    isLoading ? (
                        <Typography color="black">Loading...</Typography>
                    ) : error || timeoutError ? (
                        <Typography color="black">
                            Error fetching token data or token not found
                        </Typography>
                    ) : tokenData ? (
                        <ListItem
                            component="button"
                            onClick={() => handleTopTokenSelect(tokenData.address)}
                            style={{ display: 'flex', alignItems: 'center' }}>
                            {tokenData.image ? (
                                <img
                                    src={tokenData.image}
                                    alt={tokenData.symbol}
                                    style={{ width: 24, height: 24, marginRight: 8 }}
                                />
                            ) : (
                                <GenericTokenIcon sx={{ marginRight: 1 }} />
                            )}
                            <Typography variant="main16">{tokenData.name}</Typography>
                        </ListItem>
                    ) : (
                        <Typography>No token found</Typography>
                    )
                ) : (
                    <List>
                        {TopTokens.map((token, index) => (
                            <React.Fragment key={token.symbol}>
                                <ListItem
                                    component="button"
                                    onClick={() => handleTopTokenSelect(token.contractAddress)}
                                    style={{ display: 'flex', alignItems: 'center' }}>
                                    {token.image ? (
                                        <img
                                            src={token.image}
                                            alt={token.symbol}
                                            style={{ width: 24, height: 24, marginRight: 8 }}
                                        />
                                    ) : (
                                        <GenericTokenIcon sx={{ marginRight: 1 }} />
                                    )}
                                    <Typography variant="main16">{token.name}</Typography>
                                </ListItem>
                                {index < TopTokens.length - 1 && (
                                    <Divider sx={{ backgroundColor: '#ffffff', opacity: 0.2 }} />
                                )}
                            </React.Fragment>
                        ))}
                    </List>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default TokenSelectModal;
