import { Box, Button, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ContractFunctionExecutionError } from 'viem';
import { useAccount, useWriteContract } from 'wagmi';
import TokenSelectButton from '../../components/buttons/TokenSelectButton';
import TokenInput from '../../components/inputs/TokenInput';
import { useNormalizedPair } from '../../hooks/useNormalizedPair';
import { usePoolData } from '../../hooks/usePoolData';
import { useTokenData } from '../../hooks/useTokenData';
import { useSharedDependencies } from '../../providers/SharedDependencyProvider';
import { TokenAbi } from '../../shared/abi/Token';
import { VistaPairAbi } from '../../shared/abi/VistaPair';
import { VistaRouterAbi } from '../../shared/abi/VistaRouter';
import { useRootStore } from '../../store/root';
import { Token } from '../../types/Token';
import { MAX_UINT256, toBigInt } from '../../utils/tokenUtils';
import { checkRequiresApproval } from '../../utils/transactionUtils';

interface CreateNewPairPanelProps {
    presetToken0?: Token;
    presetToken1?: Token;
}

const CreateNewPairPanel = ({ presetToken0, presetToken1 }: CreateNewPairPanelProps) => {
    const { writeContractAsync } = useWriteContract();
    const [currentNetworkConfig] = useRootStore((store) => [store.currentNetworkConfig]);
    const { address, isConnected } = useAccount();
    const { tokenService } = useSharedDependencies();

    //UI
    const [token0ContractAddress, setToken0ContractAddress] = React.useState<string | undefined>();
    const [token0, setToken0] = useState<Token | undefined>(presetToken0);
    const [token0Amount, settoken0Amount] = useState('');
    const [token1ContractAddress, setToken1ContractAddress] = useState<string | undefined>();
    const [token1, setToken1] = useState<Token | undefined>(presetToken1);
    const [token1Amount, settoken1Amount] = useState('');

    //Protocol
    const [feeRecipientAddress, setFeeRecipientAddress] = useState('');
    const [lpBuyFee, setLpBuyFee] = useState('');
    const [lpSellFee, setLpSellFee] = useState('');
    const [protoBuyFee, setProtoBuyFee] = useState('');
    const [protoSellFee, setProtoSellFee] = useState('');

    //Metadata
    const [tokenDescription, setTokenDescription] = useState('');
    const [tokenImage, setTokenImage] = useState('');
    const [tokenTwitter, setTokenTwitter] = useState('');
    const [tokenTelegram, setTokenTelegram] = useState('');
    const [tokenWebsite, setTokenWebsite] = useState('');

    const token0Data = useTokenData(token0ContractAddress || '');
    useEffect(() => {
        if (token0Data.data) {
            setToken0(token0Data.data);
        }
    }, [token0Data.data]);

    const token1Data = useTokenData(token1ContractAddress || '');
    useEffect(() => {
        if (token1Data.data) {
            setToken1(token1Data.data);
        }
    }, [token1Data.data]);

    const handleCreatePair = () => {
        const createPair = async () => {
            if (!isConnected || !address) {
                toast.error('Please connect your wallet');
                return;
            }

            if (
                token0 &&
                token0Amount &&
                token1 &&
                token1Amount &&
                lpBuyFee &&
                lpSellFee &&
                protoSellFee &&
                protoBuyFee &&
                feeRecipientAddress
            )
                try {
                    const token0AmountBigInt = toBigInt(token0Amount, token0.decimals);
                    const token1AmountBigInt = toBigInt(token1Amount, token1.decimals);

                    if (token0.address === '0x0') {
                        const allowance = await tokenService.allowance(
                            currentNetworkConfig,
                            address,
                            token1.address,
                            currentNetworkConfig.addresses.NATIVE_ROUTER_ADDRESS,
                        );

                        const approvalRequired = checkRequiresApproval({
                            approvedAmount: allowance,
                            amount: token1AmountBigInt,
                        });

                        if (approvalRequired) {
                            await writeContractAsync({
                                address: token1.address as `0x${string}`,
                                abi: TokenAbi,
                                functionName: 'approve',
                                args: [
                                    currentNetworkConfig.addresses.NATIVE_ROUTER_ADDRESS,
                                    MAX_UINT256,
                                ],
                            });

                            toast.info('Approval in progress, please wait');

                            await new Promise((resolve) => setTimeout(resolve, 5000));
                        }

                        await writeContractAsync({
                            abi: VistaRouterAbi,
                            address: currentNetworkConfig.addresses
                                .NATIVE_ROUTER_ADDRESS as `0x${string}`,
                            functionName: 'launch',
                            args: [
                                token1.address,
                                token1AmountBigInt,
                                0,
                                0,
                                parseInt(lpBuyFee),
                                parseInt(lpSellFee),
                                parseInt(protoSellFee),
                                parseInt(protoBuyFee),
                                feeRecipientAddress,
                            ],
                            value: token0AmountBigInt,
                        });

                        toast.success('Pair created successfully');
                    } else if (token1.address === '0x0') {
                        const allowance = await tokenService.allowance(
                            currentNetworkConfig,
                            address,
                            token0.address,
                            currentNetworkConfig.addresses.NATIVE_ROUTER_ADDRESS,
                        );

                        const approvalRequired = checkRequiresApproval({
                            approvedAmount: allowance,
                            amount: token0AmountBigInt,
                        });

                        if (approvalRequired) {
                            await writeContractAsync({
                                address: token0.address as `0x${string}`,
                                abi: TokenAbi,
                                functionName: 'approve',
                                args: [
                                    currentNetworkConfig.addresses.NATIVE_ROUTER_ADDRESS,
                                    MAX_UINT256,
                                ],
                            });

                            toast.info('Approval in progress, please wait');

                            await new Promise((resolve) => setTimeout(resolve, 5000));
                        }

                        await writeContractAsync({
                            abi: VistaRouterAbi,
                            address: currentNetworkConfig.addresses
                                .NATIVE_ROUTER_ADDRESS as `0x${string}`,
                            functionName: 'launch',
                            args: [
                                token0.address,
                                token0AmountBigInt,
                                0,
                                0,
                                parseInt(lpBuyFee),
                                parseInt(lpSellFee),
                                parseInt(protoSellFee),
                                parseInt(protoBuyFee),
                                feeRecipientAddress,
                            ],
                            value: token1AmountBigInt,
                        });

                        toast.success('Pair created successfully');
                    } else {
                        toast.error('You need to provide one of the tokens as ETH');
                        return;
                    }
                } catch (error) {
                    console.error(error);

                    if (error instanceof ContractFunctionExecutionError) {
                        toast.error(error.cause.shortMessage);
                    } else {
                        toast.error('Transaction failed, check console for details');
                    }
                }
        };
        createPair();
    };

    const { normalizedToken0, normalizedToken1 } = useNormalizedPair(token0, token1);

    const { pairData } = usePoolData({
        token0Address: normalizedToken0?.address,
        token1Address: normalizedToken1?.address,
    });

    const handleSetMetadata = () => {
        const setMetadata = async () => {
            try {
                if (pairData)
                    await writeContractAsync({
                        abi: VistaPairAbi,
                        address: pairData?.pairAddress as `0x${string}`,
                        functionName: 'setMetadata',
                        args: [
                            tokenWebsite,
                            tokenImage,
                            tokenDescription,
                            tokenTelegram,
                            tokenTwitter,
                        ],
                    });

                toast.success('Metadata updated successfully');
            } catch (error) {
                console.error(error);

                if (error instanceof ContractFunctionExecutionError) {
                    toast.error(error.cause.shortMessage);
                } else {
                    toast.error('Transaction failed, check console for details');
                }
            }
        };
        setMetadata();
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            sx={{
                ml: 4,
                mr: 4,
                p: 1,
            }}>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                width="100%"
                sx={{ position: 'relative', border: '2px inset', p: 1 }}>
                <Box>
                    <Typography sx={{ color: 'black' }}>Create Pair</Typography>
                </Box>
                <Box sx={{ justifyContent: 'center' }}>
                    <Box
                        width="100%"
                        display="flex"
                        flexDirection="row"
                        alignItems="center">
                        <Box flexGrow={2}>
                            <TokenInput
                                token={token0}
                                tokenAmount={token0Amount}
                                setTokenAmount={settoken0Amount}
                            />
                        </Box>
                        <Box>
                            <TokenSelectButton
                                token={token0}
                                onAddressChange={setToken0ContractAddress}
                            />
                        </Box>
                    </Box>
                    <Box
                        width="100%"
                        display="flex"
                        flexDirection="row"
                        alignItems="center">
                        <Box flexGrow={2}>
                            <TokenInput
                                token={token1}
                                tokenAmount={token1Amount}
                                setTokenAmount={settoken1Amount}
                            />
                        </Box>
                        <Box>
                            <TokenSelectButton
                                token={token1}
                                onAddressChange={setToken1ContractAddress}
                            />
                        </Box>
                    </Box>
                </Box>

                <Box
                    flexDirection="column"
                    sx={{ display: 'flex', marginBottom: '12px', p: 2 }}>
                    <Box>
                        <Typography sx={{ color: 'black' }}>Fee Recipient Address:</Typography>
                    </Box>
                    <Box>
                        <TextField
                            variant="outlined"
                            placeholder="(Address)"
                            required
                            fullWidth
                            sx={{
                                backgroundColor: '#fff',
                                border: '1px solid #00008b',
                                color: 'black',
                                '& .MuiInputBase-input': {
                                    color: 'black', // Additional selector to ensure the input text color
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'black', // Optional: border color
                                },
                                '& .MuiFormLabel-root': {
                                    color: 'black', // Optional: label color
                                },
                            }}
                            value={feeRecipientAddress}
                            onChange={(e) => setFeeRecipientAddress(e.target.value)}
                        />
                    </Box>
                </Box>

                <Box
                    display="flex"
                    flexDirection="row">
                    <Box
                        flexDirection="column"
                        sx={{ display: 'flex', marginBottom: '12px', p: 2 }}>
                        <Box>
                            <Typography sx={{ color: 'black' }}>LP-Fees:</Typography>
                        </Box>
                        <Box>
                            <TextField
                                variant="outlined"
                                placeholder="(Buy)"
                                required
                                sx={{
                                    backgroundColor: '#fff',
                                    border: '1px solid #00008b',
                                    color: 'black',
                                    '& .MuiInputBase-input': {
                                        color: 'black', // Additional selector to ensure the input text color
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'black', // Optional: border color
                                    },
                                    '& .MuiFormLabel-root': {
                                        color: 'black', // Optional: label color
                                    },
                                }}
                                value={lpBuyFee}
                                onChange={(e) => setLpBuyFee(e.target.value)}
                            />
                        </Box>
                        <Box>
                            <TextField
                                variant="outlined"
                                placeholder="(Sell)"
                                required
                                sx={{
                                    backgroundColor: '#fff',
                                    border: '1px solid #00008b',
                                    color: 'black',
                                    '& .MuiInputBase-input': {
                                        color: 'black', // Additional selector to ensure the input text color
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'black', // Optional: border color
                                    },
                                    '& .MuiFormLabel-root': {
                                        color: 'black', // Optional: label color
                                    },
                                }}
                                value={lpSellFee}
                                onChange={(e) => setLpSellFee(e.target.value)}
                            />
                        </Box>
                    </Box>

                    <Box
                        flexDirection="column"
                        sx={{ display: 'flex', marginBottom: '12px', p: 2 }}>
                        <Box>
                            <Typography sx={{ color: 'black' }}>Protocol Fees:</Typography>
                        </Box>
                        <Box>
                            <TextField
                                variant="outlined"
                                placeholder="(Buy)"
                                required
                                sx={{
                                    backgroundColor: '#fff',
                                    border: '1px solid #00008b',
                                    color: 'black',
                                    '& .MuiInputBase-input': {
                                        color: 'black', // Additional selector to ensure the input text color
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'black', // Optional: border color
                                    },
                                    '& .MuiFormLabel-root': {
                                        color: 'black', // Optional: label color
                                    },
                                }}
                                value={protoBuyFee}
                                onChange={(e) => setProtoBuyFee(e.target.value)}
                            />
                        </Box>
                        <Box>
                            <TextField
                                variant="outlined"
                                placeholder="(Sell)"
                                required
                                sx={{
                                    backgroundColor: '#fff',
                                    border: '1px solid #00008b',
                                    color: 'black',
                                    '& .MuiInputBase-input': {
                                        color: 'black', // Additional selector to ensure the input text color
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'black', // Optional: border color
                                    },
                                    '& .MuiFormLabel-root': {
                                        color: 'black', // Optional: label color
                                    },
                                }}
                                value={protoSellFee}
                                onChange={(e) => setProtoSellFee(e.target.value)}
                            />
                        </Box>
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        width: '100%',
                        p: 2,
                    }}>
                    <Button
                        variant="windows"
                        onClick={() => handleCreatePair()}>
                        Create Pair
                    </Button>
                </Box>
            </Box>

            <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                width="100%"
                sx={{ position: 'relative', border: '2px inset', mt: 2 }}>
                <Box sx={{ p: 1 }}>
                    <Typography color="black">Set Metadata</Typography>
                </Box>
                <Box sx={{ justifyContent: 'center' }}>
                    {/* Website Field */}
                    <Box
                        flexDirection="column"
                        sx={{ display: 'flex', marginBottom: '12px', p: 2 }}>
                        <Box>
                            <Typography sx={{ color: 'black' }}>Website URL:</Typography>
                        </Box>
                        <Box>
                            <TextField
                                variant="outlined"
                                placeholder="(optional)"
                                fullWidth
                                sx={{
                                    backgroundColor: '#fff',
                                    border: '1px solid #00008b',
                                    color: 'black',
                                    '& .MuiInputBase-input': {
                                        color: 'black', // Additional selector to ensure the input text color
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'black', // Optional: border color
                                    },
                                    '& .MuiFormLabel-root': {
                                        color: 'black', // Optional: label color
                                    },
                                }}
                                value={tokenWebsite}
                                onChange={(e) => setTokenWebsite(e.target.value)}
                            />
                        </Box>
                    </Box>

                    {/* Desc Field */}
                    <Box
                        flexDirection="column"
                        sx={{ display: 'flex', marginBottom: '12px', p: 2 }}>
                        <Box>
                            <Typography sx={{ color: 'black' }}>Description:</Typography>
                        </Box>
                        <Box>
                            <TextField
                                variant="outlined"
                                placeholder="(optional)"
                                fullWidth
                                sx={{
                                    backgroundColor: '#fff',
                                    border: '1px solid #00008b',
                                    color: 'black',
                                    '& .MuiInputBase-input': {
                                        color: 'black', // Additional selector to ensure the input text color
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'black', // Optional: border color
                                    },
                                    '& .MuiFormLabel-root': {
                                        color: 'black', // Optional: label color
                                    },
                                }}
                                value={tokenDescription}
                                onChange={(e) => setTokenDescription(e.target.value)}
                            />
                        </Box>
                    </Box>

                    {/* Img Field */}
                    <Box
                        flexDirection="column"
                        sx={{ display: 'flex', marginBottom: '12px', p: 2 }}>
                        <Box>
                            <Typography sx={{ color: 'black' }}>Image URL:</Typography>
                        </Box>
                        <Box>
                            <TextField
                                variant="outlined"
                                placeholder="(optional)"
                                fullWidth
                                sx={{
                                    backgroundColor: '#fff',
                                    border: '1px solid #00008b',
                                    color: 'black',
                                    '& .MuiInputBase-input': {
                                        color: 'black', // Additional selector to ensure the input text color
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'black', // Optional: border color
                                    },
                                    '& .MuiFormLabel-root': {
                                        color: 'black', // Optional: label color
                                    },
                                }}
                                value={tokenImage}
                                onChange={(e) => setTokenImage(e.target.value)}
                            />
                        </Box>
                    </Box>

                    {/* Telegram Field */}
                    <Box
                        flexDirection="column"
                        sx={{ display: 'flex', marginBottom: '12px', p: 2 }}>
                        <Box>
                            <Typography sx={{ color: 'black' }}>Telegram:</Typography>
                        </Box>
                        <Box>
                            <TextField
                                variant="outlined"
                                placeholder="(optional)"
                                fullWidth
                                sx={{
                                    backgroundColor: '#fff',
                                    border: '1px solid #00008b',
                                    color: 'black',
                                    '& .MuiInputBase-input': {
                                        color: 'black', // Additional selector to ensure the input text color
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'black', // Optional: border color
                                    },
                                    '& .MuiFormLabel-root': {
                                        color: 'black', // Optional: label color
                                    },
                                }}
                                value={tokenTelegram}
                                onChange={(e) => setTokenTelegram(e.target.value)}
                            />
                        </Box>
                    </Box>

                    {/* soci Field */}
                    <Box
                        flexDirection="column"
                        sx={{ display: 'flex', marginBottom: '12px', p: 2 }}>
                        <Box>
                            <Typography sx={{ color: 'black' }}>Twitter:</Typography>
                        </Box>
                        <Box>
                            <TextField
                                variant="outlined"
                                placeholder="(optional)"
                                fullWidth
                                sx={{
                                    backgroundColor: '#fff',
                                    border: '1px solid #00008b',
                                    color: 'black',
                                    '& .MuiInputBase-input': {
                                        color: 'black', // Additional selector to ensure the input text color
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'black', // Optional: border color
                                    },
                                    '& .MuiFormLabel-root': {
                                        color: 'black', // Optional: label color
                                    },
                                }}
                                value={tokenTwitter}
                                onChange={(e) => setTokenTwitter(e.target.value)}
                            />
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            width: '100%',
                            p: 2,
                        }}>
                        <Button
                            variant="windows"
                            onClick={() => handleSetMetadata()}>
                            Update Metadata
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
export default CreateNewPairPanel;
