import { StateCreator } from 'zustand';
import { RootStore } from './root';
import { Network } from '../types/Web3/Network';
import { NetworkConfig } from '../types/NetworkConfig';
import { getNetworkConfig } from '../utils/protocolUtils';
import { ChainId } from '../shared/ChainId';

export interface ProtocolSlice {
    currentNetwork: Network;
    currentChainId: number;
    setCurrentNetwork: (network: Network) => void;
    currentNetworkConfig: NetworkConfig;
}

export const createProtocolSlice: StateCreator<
    RootStore,
    [['zustand/subscribeWithSelector', never], ['zustand/devtools', never]],
    [],
    ProtocolSlice
> = (set, get) => {
    const initialNetwork = Network.proto_mainnet;
    return {
        currentNetwork: initialNetwork,
        currentNetworkConfig: getNetworkConfig(initialNetwork),
        currentChainId: ChainId.Mainnet,
        setCurrentNetwork: (network) => {
            localStorage.setItem('currentNetwork', network);
            set({
                currentNetwork: network,
                currentNetworkConfig: getNetworkConfig(network),
            });
        },
    };
};
