import { Typography } from '@mui/material';
import React from 'react';
import MainLayout from '../layout/MainLayout';
import { PageWithLayout } from '../types';

const Pool: PageWithLayout = () => {
    return (
        <>
            <Typography>Not Found</Typography>
        </>
    );
};

Pool.getLayout = (page: React.ReactNode) => <MainLayout>{page}</MainLayout>;

export default Pool;
