import { Box, Link, Typography } from '@mui/material';
import React from 'react';
import { CompactableTypography, CompactMode } from '../components/CompactableTypography';
import { GenericTokenIcon } from '../components/images/GenericTokenIcon';
import { CreatedToken } from '../types/CreatedToken';
import { timeAgo } from '../utils/utils';

interface TokenActivityInfoProps {
    Token: CreatedToken;
}

const TokenActivityInfo: React.FC<TokenActivityInfoProps> = ({ Token }) => {
    return (
        <Link href={`/token/${Token.contract_address}`}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '10px',
                    borderRadius: '4px',
                    width: 'auto',
                    fontFamily: '"Tahoma", sans-serif', // XP-like font
                }}>
                {/* Image Section */}
                <Box sx={{ marginRight: '10px' }}>
                    {Token.img_url ? (
                        <img
                            src={Token.img_url}
                            alt={Token.name}
                            width="75"
                            height="75"
                        />
                    ) : (
                        <GenericTokenIcon />
                    )}
                </Box>

                {/* Text Content Section */}
                <Box sx={{ flexGrow: 1 }}>
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                            fontSize: '12px',
                            color: '#000080', // XP-style dark blue
                            marginBottom: '2px',
                        }}>
                        {Token.name} | ${Token.symbol}
                    </Typography>

                    <Box
                        display="flex"
                        flexDirection="row">
                        <Typography
                            sx={{
                                color: '#b22222', // Firebrick color
                                fontSize: '11px',
                                marginBottom: '4px',
                            }}>
                            Created by:{' '}
                        </Typography>
                        <CompactableTypography
                            sx={{
                                color: '#b22222', // Firebrick color
                                fontSize: '11px',
                                marginBottom: '4px',
                            }}
                            compactMode={CompactMode.SM}>
                            {Token.deployer}
                        </CompactableTypography>
                    </Box>

                    <Box
                        display="flex"
                        flexDirection="row">
                        <Typography
                            sx={{
                                color: '#b22222', // Firebrick color
                                fontSize: '11px',
                                marginBottom: '4px',
                            }}>
                            {timeAgo(Token.created_at)}
                        </Typography>
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="row">
                        <Typography
                            sx={{
                                color: 'black', // Firebrick color
                                fontSize: '11px',
                                marginBottom: '4px',
                            }}>
                            {Token.description}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Link>
    );
};
export default TokenActivityInfo;
