import { Box, Button, ButtonBase, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ContractFunctionExecutionError, formatEther } from 'viem';
import { useAccount, useWriteContract } from 'wagmi';
import TokenSelectButton from '../../components/buttons/TokenSelectButton';
import TokenInput from '../../components/inputs/TokenInput';
import { useModalContext } from '../../hooks/useModalContext';
import { useNormalizedPair } from '../../hooks/useNormalizedPair';
import { useTokenData } from '../../hooks/useTokenData';
import { useSharedDependencies } from '../../providers/SharedDependencyProvider';
import { TokenAbi } from '../../shared/abi/Token';
import { VistaRouterAbi } from '../../shared/abi/VistaRouter';
import { useRootStore } from '../../store/root';
import { Pool } from '../../types/Pools/Pool';
import { Token } from '../../types/Token';
import { MAX_UINT256, toBigInt } from '../../utils/tokenUtils';
import { checkRequiresApproval } from '../../utils/transactionUtils';

const AddLiquidityPanel = () => {
    const { writeContractAsync } = useWriteContract();
    const { address, isConnected } = useAccount();
    const { openCreateNewPair } = useModalContext();
    const [currentNetworkConfig] = useRootStore((store) => [store.currentNetworkConfig]);
    const { uiDataService, tokenService } = useSharedDependencies();

    const [activeToken, setActiveToken] = React.useState<'token0' | 'token1' | undefined>(
        undefined,
    );

    //UI
    const [token0ContractAddress, setToken0ContractAddress] = useState<string | undefined>();
    const [token0, setToken0] = useState<Token | undefined>();
    const [token0Amount, setToken0Amount] = useState('');
    const [token1ContractAddress, setToken1ContractAddress] = useState<string | undefined>();
    const [token1, setToken1] = useState<Token | undefined>();
    const [token1Amount, setToken1Amount] = useState('');
    const [pool, setPool] = useState<Pool | undefined>(undefined);
    const [isChecking, setIsChecking] = useState(false);
    const [balanceToken0, setBalanceToken0] = useState<string | undefined>();
    const [balanceToken1, setBalanceToken1] = useState<string | undefined>();

    const token0Data = useTokenData(token0ContractAddress || '');
    useEffect(() => {
        if (token0Data.data) {
            setToken0(token0Data.data);
        }
    }, [token0Data.data]);

    const token1Data = useTokenData(token1ContractAddress || '');
    useEffect(() => {
        if (token1Data.data) {
            setToken1(token1Data.data);
        }
    }, [token1Data.data]);

    const handleToken0AmountChange = (amount: string) => {
        setActiveToken('token0');
        setToken0Amount(amount);
    };
    const handleToken1AmountChange = (amount: string) => {
        setActiveToken('token1');
        setToken1Amount(amount);
    };

    const { normalizedToken0, normalizedToken1 } = useNormalizedPair(token0, token1);

    useEffect(() => {
        const getPoolData = async () => {
            if (token0 && token1 && normalizedToken0 && normalizedToken1) {
                setIsChecking(true);

                try {
                    const pool = await uiDataService.GetPoolDataHumanized(
                        currentNetworkConfig,
                        normalizedToken0.address,
                        normalizedToken1.address,
                    );
                    setPool(pool);
                    setIsChecking(false);
                } catch (error) {
                    setPool(undefined);
                    setIsChecking(false);
                }
            }
        };
        getPoolData();
    }, [token0, token1, currentNetworkConfig, uiDataService, normalizedToken0, normalizedToken1]);

    useEffect(() => {
        const fetchLiquidityQuote = async () => {
            if (!token0 || !token1 || !pool) return;

            try {
                if (activeToken === 'token0' && token0Amount) {
                    const quote = await uiDataService.GetLiquidityQuote(
                        currentNetworkConfig,
                        token0,
                        token0Amount,
                        pool,
                    );
                    console.log(quote);

                    setToken1Amount(formatEther(quote));
                } else if (activeToken === 'token1' && token1Amount) {
                    const quote = await uiDataService.GetLiquidityQuote(
                        currentNetworkConfig,
                        token1,
                        token1Amount,
                        pool,
                    );
                    console.log(quote);
                    setToken0Amount(formatEther(quote));
                }
            } catch (error) {
                console.error('Error fetching liquidity quote:', error);
            }
        };

        fetchLiquidityQuote();
    }, [
        token0Amount,
        token1Amount,
        activeToken,
        pool,
        currentNetworkConfig,
        token0,
        token1,
        uiDataService,
    ]);

    const handleAddLiquidity = () => {
        const addLiquidity = async () => {
            if (!isConnected || !address) {
                toast.error('Please connect your wallet');
                return;
            }

            if (token0 && token1 && pool && normalizedToken0 && normalizedToken1)
                try {
                    if (token0Amount === '' || token1Amount === '') {
                        toast.error('Please enter a valid amount');
                        return;
                    }

                    if (Number(balanceToken0) < Number(token0Amount)) {
                        toast.error(`Insufficient balance for token ${token0.symbol}`);
                        return;
                    }

                    if (Number(balanceToken1) < Number(token1Amount)) {
                        toast.error(`Insufficient balance for token ${token1.symbol}`);
                        return;
                    }

                    const token0AmountBigInt = toBigInt(token0Amount, token0.decimals);
                    const token1AmountBigInt = toBigInt(token1Amount, token1.decimals);

                    const deadline = Math.floor(Date.now() / 1000) + 60 * 20;

                    if (token0.address === '0x0') {
                        const allowance = await tokenService.allowance(
                            currentNetworkConfig,
                            address,
                            token1.address,
                            currentNetworkConfig.addresses.NATIVE_ROUTER_ADDRESS,
                        );

                        const approvalRequired = checkRequiresApproval({
                            approvedAmount: allowance,
                            amount: token1AmountBigInt,
                        });

                        if (approvalRequired) {
                            await writeContractAsync({
                                address: token1.address as `0x${string}`,
                                abi: TokenAbi,
                                functionName: 'approve',
                                args: [
                                    currentNetworkConfig.addresses.NATIVE_ROUTER_ADDRESS,
                                    MAX_UINT256,
                                ],
                            });

                            toast.info('Approval in progress, please wait');

                            await new Promise((resolve) => setTimeout(resolve, 5000));
                        }

                        await writeContractAsync({
                            abi: VistaRouterAbi,
                            address: currentNetworkConfig.addresses
                                .NATIVE_ROUTER_ADDRESS as `0x${string}`,
                            functionName: 'addLiquidityETH',
                            args: [token1.address, token1AmountBigInt, 0, 0, deadline],
                            value: token0AmountBigInt,
                        });

                        toast.success('Transaction successful');
                    } else if (token1.address === '0x0') {
                        const allowance = await tokenService.allowance(
                            currentNetworkConfig,
                            address,
                            token0.address,
                            currentNetworkConfig.addresses.NATIVE_ROUTER_ADDRESS,
                        );

                        const approvalRequired = checkRequiresApproval({
                            approvedAmount: allowance,
                            amount: token0AmountBigInt,
                        });

                        if (approvalRequired) {
                            await writeContractAsync({
                                address: token0.address as `0x${string}`,
                                abi: TokenAbi,
                                functionName: 'approve',
                                args: [
                                    currentNetworkConfig.addresses.NATIVE_ROUTER_ADDRESS,
                                    MAX_UINT256,
                                ],
                            });

                            toast.info('Approval in progress, please wait');

                            await new Promise((resolve) => setTimeout(resolve, 5000));
                        }

                        await writeContractAsync({
                            abi: VistaRouterAbi,
                            address: currentNetworkConfig.addresses
                                .NATIVE_ROUTER_ADDRESS as `0x${string}`,
                            functionName: 'addLiquidityETH',
                            args: [token0.address, token0AmountBigInt, 0, 0, deadline],
                            value: token1AmountBigInt,
                        });

                        toast.success('Transaction successful');
                    } else {
                        toast.error('You need to provide one of the tokens as ETH');
                        return;
                    }
                } catch (error) {
                    console.error(error);

                    if (error instanceof ContractFunctionExecutionError) {
                        toast.error(error.cause.shortMessage);
                    } else {
                        toast.error('Transaction failed, check console for details');
                    }
                }
        };
        addLiquidity();
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            sx={{
                ml: 4,
                mr: 4,
                p: 1,
            }}>
            {token0 && token1 && !isChecking && !pool && (
                <ButtonBase
                    component="span"
                    onClick={() => openCreateNewPair(token0, token1)}>
                    <Typography sx={{ color: 'red' }}>
                        Pool Does Not Exist, Launch a New Pool Here
                    </Typography>
                </ButtonBase>
            )}
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                width="100%"
                sx={{ position: 'relative' }}>
                <Box sx={{ position: 'relative' }}>
                    <Box
                        width="100%"
                        display="flex"
                        flexDirection="row"
                        alignItems="center">
                        <Box flexGrow={2}>
                            <TokenInput
                                token={token0}
                                tokenAmount={token0Amount}
                                setTokenAmount={handleToken0AmountChange}
                                setBalanceToken={setBalanceToken0}
                            />
                        </Box>
                        <Box>
                            <TokenSelectButton
                                token={token0}
                                onAddressChange={setToken0ContractAddress}
                            />
                        </Box>
                    </Box>
                    <Box
                        width="100%"
                        display="flex"
                        flexDirection="row"
                        alignItems="center">
                        <Box flexGrow={2}>
                            <TokenInput
                                token={token1}
                                tokenAmount={token1Amount}
                                setTokenAmount={handleToken1AmountChange}
                                setBalanceToken={setBalanceToken1}
                            />
                        </Box>
                        <Box>
                            <TokenSelectButton
                                token={token1}
                                onAddressChange={setToken1ContractAddress}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                    mt: 2,
                }}>
                <Button
                    disabled={token0 && token1 && !pool}
                    variant="windows"
                    onClick={() => handleAddLiquidity()}>
                    Add Liquidity
                </Button>
            </Box>
        </Box>
    );
};
export default AddLiquidityPanel;
