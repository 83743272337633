import { Box, LinearProgress, Link, Typography } from '@mui/material';
import React, { useState } from 'react';
import WindowsContentContainer from '../../components/containers/WindowsContentContainer';
import { useRootStore } from '../../store/root';
import { Pool } from '../../types/Pools/Pool';
import { fromBigInt } from '../../utils/tokenUtils';
import { useTokenDetails } from '../../hooks/useTokenDetails';
import { CreatedToken } from '../../types/CreatedToken';
import { formatEther } from 'viem';
import { FormattedNumber } from '../../components/primitives/FormattedNumber';

interface TokenStatisticsProps {
    pool: Pool;
}

const TokenStatistics = ({ pool }: TokenStatisticsProps) => {

    const [currentNetworkConfig] = useRootStore((store) => [store.currentNetworkConfig]);
    const oneETH = BigInt(1e18);
    const burn = Number(pool.tokenFees.deployerFees * 100n / oneETH);
    const burnProgress = burn > 100 ? 100 : burn;
    return (

        <WindowsContentContainer title="Token Statistics">
            <Box sx={{ padding: '16px' }}>
                <Typography sx={{ color: 'black' }}>
                    Liquidity Revenue:{' '}
                    <span style={{ color: 'black', fontWeight: 'bold' }}>
                        {Number(fromBigInt(pool.totalCollected, 18)).toFixed(3)} ETH
                    </span>
                </Typography>
                <Typography sx={{ color: 'black' }}>
                    Fee Revenue:{' '}
                    <span style={{ color: 'black', fontWeight: 'bold' }}>
                        {Number(fromBigInt(pool.tokenFees.deployerFees, 18)).toFixed(3)} ETH
                    </span>
                </Typography>
                <FormattedNumber value={0} />
                <Box
                    display="flex"
                    alignItems="center"
                    mt={1}>
                    <Typography color="textSecondary">Burn Progress: {burnProgress > 100 ? 100 : burnProgress}%</Typography>
                    <Box
                        ml={1}
                        width="100%">
                        <LinearProgress
                            variant="determinate"
                            value={burnProgress}
                            sx={{
                                backgroundColor: '#D7CCC8',
                                '& .MuiLinearProgress-bar': { backgroundColor: '#2E7D32' },
                            }}
                        />
                    </Box>
                    <Link
                        href={currentNetworkConfig.explorerLinkBuilder({
                            address: pool.pairAddress,
                        })}
                        target="_blank"
                        rel="noopener"
                        sx={{ ml: 1, color: '#1E88E5', textDecoration: 'none' }}>
                        Explorer
                    </Link>
                </Box>
                <Typography
                    color="textSecondary"
                    mt={2}>
                    When the token will have generated enough fees for the creator, the liquidity
                    will be <span style={{ fontWeight: 'bold' }}>burned</span>.
                </Typography>
                <Typography
                    color="textSecondary"
                    mt={1}>
                    If the token does not generate enough fees within{' '}
                    <span style={{ fontWeight: 'bold', color: '#D32F2F' }}>5 days</span>, the
                    liquidity will be{' '}
                    <span style={{ fontWeight: 'bold', color: '#D32F2F' }}>withdrawn</span> and sent
                    back to the creators wallet.
                </Typography>

                {pool.isLpBurned ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        mt={3}
                        p={2}
                        bgcolor="#FFEBEE"
                        borderRadius="8px">
                        <Typography sx={{ fontWeight: 'bold', color: '#B71C1C' }}>
                            LP BURNED
                        </Typography>
                    </Box>
                ) : (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        mt={3}
                        p={2}
                        bgcolor="#green"
                        borderRadius="8px">
                        <Typography sx={{ fontWeight: 'bold', color: 'green' }}>
                            LP NOT BURNED
                        </Typography>
                    </Box>
                )}
            </Box>
        </WindowsContentContainer>
    );
};

export default TokenStatistics;
