import { PublicClient } from 'viem';
import { StakingAbi } from '../shared/abi/Staking';
import { NetworkConfig } from '../types/NetworkConfig';
import { StakersInfo } from '../types/Staking/StakersInfo';
import { Network } from '../types/Web3/Network';

export class StakingService {
    constructor(private readonly getProvider: (network: Network) => PublicClient) {}

    public async stakersInfos(
        currentNetworkConfig: NetworkConfig,
        address: string,
    ): Promise<StakersInfo> {
        const tokenContract = {
            address: currentNetworkConfig.addresses.XP_STAKING_ADDRESS as `0x${string}`,
            abi: StakingAbi,
        } as const;

        const client = this.getProvider(currentNetworkConfig.network);

        const result = await client.readContract({
            ...tokenContract,
            functionName: 'stakersInfos',
            args: [address],
        });

        const resultData = result as bigint[];

        const data: StakersInfo = {
            tokensStaked: resultData[0],
            accumulatedRewardsPerToken: resultData[1],
        };

        return data;
    }
}
