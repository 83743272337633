import React from 'react';
import { Box, Typography, InputBase } from '@mui/material';
import { NumberFormatCustom } from '../../libs/NumberFormatCustom';
import { GenericTokenIcon } from '../images/GenericTokenIcon';
import { Token } from '../../types/Token';

interface AssetInputProps {
    token?: Token;
    value?: string;
    setAmount?: (amount: string) => void;
}

const AssetInput: React.FC<AssetInputProps> = ({ token, value, setAmount }) => {
    const handleChange = (value: string) => {
        if (setAmount) setAmount(value);
    };

    return (
        <Box
            sx={{
                p: 2,
                borderRadius: '8px',
                position: 'relative',
            }}>
            <Box sx={{ pb: 1 }}>
                <Box
                    sx={{
                        border: '1px solid #1d5281',
                        display: 'flex',
                        alignItems: 'center',
                        position: 'relative',
                        backgroundColor: 'white',
                    }}>
                    <InputBase
                        fullWidth
                        placeholder="0"
                        value={value ? value : undefined}
                        sx={{
                            pl: 2,
                            fontSize: '15px',
                            color: 'black',
                        }}
                        // disabled={disabled}
                        onChange={(e) => handleChange(e.target.value)}
                        // value={tokenAmount}
                        // eslint-disable-next-line
                        inputComponent={NumberFormatCustom as any}
                    />
                    <Box
                        sx={{
                            display: 'inline-flex',
                            textTransform: 'none',
                            padding: '6px 20px',
                            width: 'auto',
                            alignItems: 'center',
                            whiteSpace: 'nowrap',
                        }}>
                        {token && (
                            <>
                                <GenericTokenIcon sx={{ marginRight: 1 }} />
                                <Typography sx={{ color: 'black' }}>{token.symbol}</Typography>
                            </>
                        )}
                    </Box>
                </Box>
            </Box>

            {/* <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'absolute',
                    bottom: 8,
                    right: 1,
                }}>
                <Typography
                    variant="caption"
                    color="gray">
                    Balance: {balance.toFixed(3)}
                </Typography>
                <Button
                    disabled={balance <= 0}
                    variant="text"
                    onClick={handleMaxClick}
                    sx={{
                        color: balance > 0 ? '#ff4081' : 'gray',
                        textTransform: 'none',
                        ml: 1,
                    }}>
                    Max
                </Button>
            </Box> 

      {priceImpact && (
        <Box>
          <Typography
            variant="caption"
            color="gray"
            sx={{ position: "absolute", bottom: 8, left: 20 }}
          >
            Impact: {priceImpact.toFixed(2)}%
          </Typography>
        </Box>
      )}*/}
        </Box>
    );
};
export default AssetInput;
