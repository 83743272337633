import React from 'react';
import { NumericFormatProps, NumericFormat } from 'react-number-format';

interface NumberFormatCustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
    value: string;
}

export const NumberFormatCustom = React.forwardRef<NumericFormatProps, NumberFormatCustomProps>(
    function NumberFormatCustom(props, ref) {
        const { onChange, ...other } = props;

        return (
            <NumericFormat
                {...other}
                decimalScale={20}
                getInputRef={ref}
                onValueChange={(values) => {
                    if (values.value !== props.value)
                        onChange({
                            target: {
                                name: props.name,
                                value: values.value || '',
                            },
                        });
                }}
                thousandSeparator
                valueIsNumericString
                allowNegative={false}
            />
        );
    },
);
