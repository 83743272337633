import { Reserves } from '../types/Pools/Reserves';

export const checkRequiresApproval = ({
    approvedAmount,
    amount,
}: {
    approvedAmount: bigint;
    amount: bigint;
}) => {
    if (approvedAmount !== undefined && approvedAmount !== 0n && approvedAmount >= amount) {
        return false;
    } else {
        return true;
    }
};

export const calculatePriceImpactBigInt = (
    amountIn: bigint,
    amountOut: bigint,
    reserves: Reserves,
    token0Decimals: number,
    token1Decimals: number,
): number | null => {
    if (!amountIn || !amountOut || !reserves) {
        console.error('Invalid inputs for price impact calculation.');
        return null;
    }

    try {
        const marketRate =
            (reserves.token1Reserve * BigInt(10 ** token0Decimals)) / reserves.token0Reserve;
        const actualRate = (amountOut * BigInt(10 ** 18)) / amountIn;
        const impact = ((marketRate - actualRate) * BigInt(100)) / marketRate;

        return Number(impact) / 10 ** token1Decimals;
    } catch (error) {
        console.error('Error calculating price impact:', error);
        return null;
    }
};
