import { Box, Paper, PaperProps } from '@mui/material';
import React, { ReactNode } from 'react';

interface WindowsContentContainerProps extends PaperProps {
    title: string;
    children: ReactNode;
    statusBar?: boolean;
    statusBarFields?: ReactNode[];
}

const WindowsContentContainer: React.FC<WindowsContentContainerProps> = ({
    title,
    children,
    sx,
}) => {
    return (
        <Paper
            variant="windows"
            sx={{ ...sx }}>
            <Box
                sx={{
                    fontFamily: '"Trebuchet MS", sans-serif',
                    background: `linear-gradient(
                                180deg,
                                rgba(9, 151, 255, 1) 0%,
                                rgba(0, 83, 238, 1) 8%,
                                rgba(0, 80, 238, 1) 40%,
                                rgba(0, 102, 255, 1) 88%,
                                rgba(0, 102, 255, 1) 93%,
                                rgba(0, 91, 255, 1) 95%,
                                rgba(0, 61, 215, 1) 96%,
                                rgba(0, 61, 215, 1) 100%
                              )`,
                    padding: '3px 5px 3px 3px',
                    borderTop: '1px solid #0831d9',
                    borderLeft: '1px solid #0831d9',
                    borderRight: '1px solid #001ea0',
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '7px',
                    fontSize: '13px',
                    textShadow: '1px 1px #0f1089',
                    height: '21px',
                }}>
                {title}
            </Box>
            <Box sx={{ p: 3 }}>{children}</Box>
        </Paper>
    );
};
export default WindowsContentContainer;
