import { createConfig, http } from 'wagmi';
import { arbitrum, mainnet } from 'wagmi/chains';
import { metaMask, safe, walletConnect } from 'wagmi/connectors';

const projectId = 'da7f10784dde4040b31b92398fbea53b';

export const walletConfig = createConfig({
    chains: [arbitrum, mainnet],
    connectors: [walletConnect({ projectId }), metaMask(), safe()],
    transports: {
        [mainnet.id]: http('https://eth-mainnet.g.alchemy.com/v2/vJZLBQNAYizRVCl6gnsmeXVHHLouD95n'),
        [arbitrum.id]: http(
            'https://arb-mainnet.g.alchemy.com/v2/vJZLBQNAYizRVCl6gnsmeXVHHLouD95n',
        ),
    },
});
