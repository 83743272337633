import { useQuery } from '@tanstack/react-query';
import { queryKeysFactory } from '../shared/QueryKeysFactory';
import { useRootStore } from '../store/root';
import { CreatedToken } from '../types/CreatedToken';

const fetchTokens = async () => {
    const url = `https://xp-fun-api-uw3h.vercel.app/api/tokens`;
    const response = await fetch(url).then(async (res) => {
        if (!res.ok) {
            throw new Error('Network response was not ok');
        }

        return (await res.json()) as CreatedToken[];
    });

    return response;
};

const sortTokens = (data: CreatedToken[]) => data.sort((a, b) => b.id - a.id);

export const useTokens = () => {
    const [currentNetworkConfig] = useRootStore((store) => [store.currentNetworkConfig]);

    return useQuery({
        queryFn: async () => {
            const tokens = await fetchTokens().then(sortTokens);
            return {
                tokens: tokens
                    .filter((token) => token.chain_id === currentNetworkConfig.chainId.toString())
                    .slice(0, 16),
            };
        },
        queryKey: queryKeysFactory.tokens(),
        staleTime: 0, // Ensure it doesn't use stale data
        refetchOnWindowFocus: false, // Disable background refetching
    });
};
