import React from 'react';
import WindowsContentContainer from '../../components/containers/WindowsContentContainer';
import { useRootStore } from '../../store/root';

interface TokenChartProps {
    title: string;
    poolAddress: string;
}

const TokenChart: React.FC<TokenChartProps> = ({ title, poolAddress }) => {
    const [currentNetworkConfig] = useRootStore((root) => [root.currentNetworkConfig]);
    const url = `https://www.dextools.io/widget-chart/en/${currentNetworkConfig.dexToolsPrefix}/pe-light/${poolAddress}?theme=dark&chartType=1&chartResolution=30&drawingToolbars=false`;
    return (
        <WindowsContentContainer
            title={title}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                minHeight: '500px',
                height: '100%', // Make sure this stretches to 100% of the container
                overflow: 'hidden', // Prevent content overflow
            }}>
            <iframe
                id="dextools-widget"
                title="DEXTools Trading Chart"
                style={{
                    border: 'none', // Remove border if necessary
                    minHeight: '500px',
                }}
                width="100%"
                height="100%"
                src={url}
            />
        </WindowsContentContainer>
    );
};

export default TokenChart;
