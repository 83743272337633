import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LinkIcon from '@mui/icons-material/Link';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useRootStore } from '../store/root';

interface ContractAddressProps {
    address: string;
}

const ContractAddress: React.FC<ContractAddressProps> = ({ address }) => {
    const [currentNetworkConfig] = useRootStore((store) => [store.currentNetworkConfig]);

    const handleCopy = () => {
        navigator.clipboard.writeText(address);
    };

    const handleOpenLink = () => {
        const etherscanLink = currentNetworkConfig.explorerLinkBuilder({ address });
        window.open(etherscanLink, '_blank');
    };

    return (
        <Box
            sx={{ pt: 1, pb: 1 }}
            display="flex"
            alignItems="center">
            <Tooltip title="Copy Address">
                <IconButton
                    onClick={handleCopy}
                    size="small"
                    sx={{ padding: 0, marginRight: 1 }}>
                    <ContentCopyIcon sx={{ width: 16, height: 16, color: 'black' }} />
                </IconButton>
            </Tooltip>
            <Tooltip title="View on Explorer">
                <IconButton
                    onClick={handleOpenLink}
                    size="small"
                    sx={{ padding: 0, marginRight: 1 }}>
                    <LinkIcon sx={{ width: 16, height: 16, color: 'black' }} />
                </IconButton>
            </Tooltip>
            <Typography sx={{ color: 'black' }}>{address}</Typography>
        </Box>
    );
};

export default ContractAddress;
