import { isAddress, PublicClient } from 'viem';
import { VistaPairAbi } from '../shared/abi/VistaPair';
import { NetworkConfig } from '../types/NetworkConfig';
import { Network } from '../types/Web3/Network';

export class PairService {
    constructor(private readonly getProvider: (network: Network) => PublicClient) {}

    public async buyTotalFee(
        currentNetworkConfig: NetworkConfig,
        contractAddress: string,
    ): Promise<number> {
        const contract = {
            address: contractAddress as `0x${string}`,
            abi: VistaPairAbi,
        } as const;

        const client = this.getProvider(currentNetworkConfig.network);

        const result = await client.readContract({
            ...contract,
            functionName: 'buyTotalFee',
        });

        const data = result as number;

        return data;
    }

    public async sellTotalFee(
        currentNetworkConfig: NetworkConfig,
        contractAddress: string,
    ): Promise<number> {
        const contract = {
            address: contractAddress as `0x${string}`,
            abi: VistaPairAbi,
        } as const;

        const client = this.getProvider(currentNetworkConfig.network);

        const result = await client.readContract({
            ...contract,
            functionName: 'sellTotalFee',
        });

        const data = result as number;

        return data;
    }

    public async viewShare(
        currentNetworkConfig: NetworkConfig,
        contractAddress: string,
    ): Promise<bigint> {
        if (!isAddress(contractAddress)) return 0n;
        const contract = {
            address: contractAddress as `0x${string}`,
            abi: VistaPairAbi,
        } as const;

        const client = this.getProvider(currentNetworkConfig.network);

        const result = await client.readContract({
            ...contract,
            functionName: 'viewShare',
        });

        const data = result as bigint;

        return data;
    }

    public async getReserves(
        currentNetworkConfig: NetworkConfig,
        contractAddress: string,
    ): Promise<bigint[]> {
        const contract = {
            address: contractAddress as `0x${string}`,
            abi: VistaPairAbi,
        } as const;

        const client = this.getProvider(currentNetworkConfig.network);

        const result = await client.readContract({
            ...contract,
            functionName: 'getReserves',
        });

        const data = result as bigint[];

        return data;
    }

    public async token0(
        currentNetworkConfig: NetworkConfig,
        contractAddress: string,
    ): Promise<string> {
        const contract = {
            address: contractAddress as `0x${string}`,
            abi: VistaPairAbi,
        } as const;

        const client = this.getProvider(currentNetworkConfig.network);

        const result = await client.readContract({
            ...contract,
            functionName: 'token0',
        });

        const data = result as string;

        return data;
    }

    public async token1(
        currentNetworkConfig: NetworkConfig,
        contractAddress: string,
    ): Promise<string> {
        const contract = {
            address: contractAddress as `0x${string}`,
            abi: VistaPairAbi,
        } as const;

        const client = this.getProvider(currentNetworkConfig.network);

        const result = await client.readContract({
            ...contract,
            functionName: 'token1',
        });

        const data = result as string;

        return data;
    }

    public async totalCollected(
        currentNetworkConfig: NetworkConfig,
        contractAddress: string,
    ): Promise<bigint> {
        const contract = {
            address: contractAddress as `0x${string}`,
            abi: VistaPairAbi,
        } as const;

        const client = this.getProvider(currentNetworkConfig.network);

        const result = await client.readContract({
            ...contract,
            functionName: 'totalCollected',
        });

        const data = result as bigint;

        return data;
    }
}
