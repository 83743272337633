export const HardLockAbi = [
    {
        type: 'constructor',
        inputs: [{ name: '_factory', type: 'address', internalType: 'address' }],
        stateMutability: 'nonpayable',
    },
    { type: 'receive', stateMutability: 'payable' },
    {
        type: 'function',
        name: 'claimShare',
        inputs: [{ name: 'token', type: 'address', internalType: 'address' }],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'getStakerInfo',
        inputs: [
            { name: '_staker', type: 'address', internalType: 'address' },
            { name: 'token', type: 'address', internalType: 'address' },
        ],
        outputs: [
            { name: 'amountStaked', type: 'uint256', internalType: 'uint256' },
            { name: 'currentShare', type: 'uint256', internalType: 'uint256' },
        ],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'stake',
        inputs: [
            { name: '_amount', type: 'uint256', internalType: 'uint256' },
            { name: 'user', type: 'address', internalType: 'address' },
            { name: 'token', type: 'address', internalType: 'address' },
        ],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'totalCollected',
        inputs: [],
        outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'totalSupplies',
        inputs: [{ name: '', type: 'address', internalType: 'address' }],
        outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'viewShare',
        inputs: [{ name: 'token', type: 'address', internalType: 'address' }],
        outputs: [{ name: 'share', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'view',
    },
    { type: 'error', name: 'ReentrancyGuardReentrantCall', inputs: [] },
];
