import React, { lazy, Suspense } from 'react';
import './App.css';
import { QueryClientProvider } from '@tanstack/react-query';
import { AppGlobalStyles } from './layout/AppGlobalStyles';
import { queryClient } from './shared/QueryClient';
import Routes from './Routes';
import { BrowserRouter as Router } from 'react-router-dom';
import { ModalContextProvider } from './hooks/useModalContext';
import { WagmiProvider } from 'wagmi';
import { walletConfig } from './config/walletConfig';
import { SharedDependenciesProvider } from './providers/SharedDependencyProvider';
import { WebSocketProvider } from './providers/WebSocketProvider';
import { SnackbarProvider } from './providers/SnackProvider';

const SwapModal = lazy(() => import('../src/components/transactions/Swap/SwapModal'));
const CreateTokenModal = lazy(() => import('../src/components/modals/CreateTokenModal'));
const WalletConnectModal = lazy(() => import('../src/components/modals/WalletConnectModal'));
const UpdateTokenModal = lazy(() => import('../src/components/modals/UpdateTokenModal'));
const SettingsModal = lazy(() => import('../src/components/modals/SettingsModal'));
const RunModal = lazy(() => import('../src/components/modals/RunModal'));

const App = () => {
    return (
        <SnackbarProvider>
            <WagmiProvider config={walletConfig}>
                <QueryClientProvider client={queryClient}>
                    <AppGlobalStyles>
                        <ModalContextProvider>
                            <SharedDependenciesProvider>
                                <WebSocketProvider>
                                    <Router>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <Routes />
                                            <SwapModal />
                                            <CreateTokenModal />
                                            <WalletConnectModal />
                                            <UpdateTokenModal />
                                            <SettingsModal />
                                            <RunModal />
                                        </Suspense>
                                    </Router>
                                </WebSocketProvider>
                            </SharedDependenciesProvider>
                        </ModalContextProvider>
                    </AppGlobalStyles>
                </QueryClientProvider>
            </WagmiProvider>
        </SnackbarProvider>
    );
};

export default App;
