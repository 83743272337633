export const TabStyle = {
    fontSize: '0.875rem',
    backgroundColor: '#c0c0c0', // Classic grey for Windows
    marginLeft: '-1px',
    marginRight: '2px',
    padding: '12px',
    minHeight: 'unset',
    borderTop: '2px solid #c0c0c0',
    borderLeft: '2px solid #c0c0c0',
    borderRight: '2px solid #c0c0c0',
    borderRadius: '4px 4px 0 0',

    '&:hover': {
        backgroundColor: '#d0d0d0',
        cursor: 'pointer',
    },
    '&.Mui-selected': {
        backgroundColor: '#e0e0e0',
        fontWeight: 'bold',
        color: 'black',
        borderBottom: 'none',
    },
};
