import { Box, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import AddLiquidityPanel from './AddLiquidityPanel';
import AddNewPairPanel from './CreateNewPairPanel';
import RemoveLiquidityPanel from './RemoveLiquidityPanel';

const tabSx = {
    fontSize: '0.875rem',
    backgroundColor: '#c0c0c0', // Classic grey for Windows
    marginLeft: '-1px',
    marginRight: '2px',
    padding: '5px 12px',
    minHeight: 'unset',
    borderTop: '2px solid #c0c0c0',
    borderLeft: '2px solid #c0c0c0',
    borderRight: '2px solid #c0c0c0',
    borderRadius: '4px 4px 0 0',

    '&:hover': {
        backgroundColor: '#d0d0d0',
        cursor: 'pointer',
    },
    '&.Mui-selected': {
        backgroundColor: '#e0e0e0',
        fontWeight: 'bold',
        color: 'black',
        borderBottom: 'none',
    },
};

// const xpSliderSx = {
//     width: '200px',
//     color: '#4caf50', // Green color for track
//     '& .MuiSlider-thumb': {
//         width: '16px',
//         height: '20px',
//         backgroundColor: '#d8d8d8', // Gray thumb background
//         border: '1px solid #4caf50', // Green border to match XP style
//         borderRadius: '2px',
//         boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.8)',
//         '&:before': {
//             display: 'none', // Removes inner circle
//         },
//         '&:hover, &.Mui-focusVisible, &.Mui-active': {
//             boxShadow: '0px 0px 4px rgba(0, 128, 0, 0.4)',
//         },
//     },
//     '& .MuiSlider-track': {
//         height: '2px',
//         backgroundColor: '#4caf50',
//     },
//     '& .MuiSlider-rail': {
//         height: '2px',
//         opacity: 1,
//         backgroundColor: '#c0c0c0', // Gray color for the rail
//     },
//     '& .MuiSlider-markLabel': {
//         color: '#333', // Darker label color for better visibility
//         fontSize: '0.875rem', // Adjust font size if needed
//     },
//     '& .MuiSlider-markLabelActive': {
//         color: '#000', // Even darker color for the active label
//     },
//     '& .MuiSlider-mark': {
//         backgroundColor: '#4caf50', // Green color for the tick marks
//         height: '8px',
//         width: '2px',
//     },
// };

// const liquidityAmount = [0, 25, 50, 75, 100];
// const marks = liquidityAmount.map((value) => ({
//     value,
//     label: `${value}%`, // Adding labels if you want the percentage displayed
// }));

const Liquidity = () => {
    const [activeTab, setActiveTab] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    return (
        <Box sx={{ pb: 2 }}>
            <Tabs
                value={activeTab}
                onChange={handleChange}>
                <Tab
                    label="Add Liquidity"
                    sx={tabSx}
                />
                <Tab
                    label="Remove Liquidity"
                    sx={tabSx}
                />
                <Tab
                    label="Add New Pair"
                    sx={tabSx}
                />
            </Tabs>

            <Box
                sx={{
                    border: '1px solid #d0d0bf',
                }}>
                {activeTab === 0 && <AddLiquidityPanel />}

                {activeTab === 1 && <RemoveLiquidityPanel />}

                {activeTab === 2 && <AddNewPairPanel />}
            </Box>
        </Box>
    );
};
export default Liquidity;
