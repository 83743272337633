import { Box, Button, Typography } from '@mui/material';
import { UseQueryResult } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ContractFunctionExecutionError } from 'viem';
import { useAccount, useWriteContract } from 'wagmi';
import TokenSelectButton from '../../components/buttons/TokenSelectButton';
import { useTokenData } from '../../hooks/useTokenData';
import { useSharedDependencies } from '../../providers/SharedDependencyProvider';
import { TokenAbi } from '../../shared/abi/Token';
import { useRootStore } from '../../store/root';
import { Token } from '../../types/Token';
import { fromBigInt } from '../../utils/tokenUtils';

const Rewards = () => {
    const { writeContractAsync } = useWriteContract();
    const { address } = useAccount();
    const [currentNetworkConfig] = useRootStore((store) => [store.currentNetworkConfig]);
    const { pairService, factoryService, hardlockService } = useSharedDependencies();

    const [poolAddress, setPoolAddress] = React.useState('');
    const [shares, setShares] = useState('');
    const [token, setToken] = useState<Token | undefined>();
    const [selectedAddress, setSelectedAddress] = useState('');
    const [isLocked, setIsLocked] = useState(false);

    const tokenData: UseQueryResult<Token, Error> = useTokenData(selectedAddress || '');
    useEffect(() => {
        const fetchData = async () => {
            if (tokenData.data) {
                setToken(tokenData.data);
                const pair = await factoryService.getPair(
                    currentNetworkConfig,
                    tokenData.data.address,
                    currentNetworkConfig.addresses.BASE_ASSET_ADDRESS,
                );
                setPoolAddress(pair);
                const isTokenLocked = await hardlockService.totalSupplies(
                    currentNetworkConfig,
                    poolAddress,
                );
                setIsLocked(isTokenLocked);
            }
        };
        fetchData();
    }, [tokenData.data, currentNetworkConfig, factoryService, hardlockService, poolAddress]);

    useEffect(() => {
        try {
            const getBalance = async () => {
                if (token && poolAddress) {
                    let balance = '0';
                    if (!isLocked) {
                        const tokenBalance = await pairService.viewShare(
                            currentNetworkConfig,
                            poolAddress,
                        );
                        balance = fromBigInt(tokenBalance, 18);
                    } else {
                        const tokenBalance = await hardlockService.viewShare(
                            currentNetworkConfig,
                            token.address,
                        );
                        balance = fromBigInt(tokenBalance, 18);
                    }
                    setShares(balance);
                }
            };
            getBalance();
        } catch (error) {
            console.log('invalid address');
        }
    }, [address, token, currentNetworkConfig, pairService, poolAddress, hardlockService, isLocked]);

    const handleClaim = () => {
        const claim = async () => {
            if (!tokenData.data) return;

            if (!shares) {
                toast.error('No shares to claim');
                return;
            }

            try {
                await writeContractAsync({
                    abi: TokenAbi,
                    address: poolAddress as `0x${string}`,
                    functionName: 'claimShare',
                });

                toast.success('Claimed rewards');
            } catch (error) {
                console.error(error);

                if (error instanceof ContractFunctionExecutionError) {
                    toast.error(error.cause.shortMessage);
                } else {
                    toast.error('Transaction failed, check console for details');
                }
            }
        };

        claim();
    };

    return (
        <Box sx={{ p: 2 }}>
            <Box sx={{ pb: 2 }}>
                <Box sx={{ pb: 2 }}>
                    <TokenSelectButton
                        token={token}
                        onAddressChange={setSelectedAddress}
                    />
                </Box>
                <Typography
                    variant="h4"
                    sx={{ color: 'black' }}>
                    Rewards Info
                </Typography>
            </Box>
            <Box sx={{ pb: 2 }}>
                <Box
                    display="flex"
                    flexDirection="column"
                    sx={{ ml: 4, mr: 4, p: 2, border: '1px solid #d0d0bf' }}>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                        sx={{ mb: 1 }}>
                        <Typography sx={{ color: 'black' }}>ETH Rewards</Typography>
                        <Typography sx={{ color: 'black' }}>{shares || '0'}</Typography>
                    </Box>
                </Box>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                    p: 2,
                }}>
                <Button
                    variant="windows"
                    onClick={() => handleClaim()}>
                    Claim Rewards
                </Button>
            </Box>
        </Box>
    );
};

export default Rewards;
