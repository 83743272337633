import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ContractFunctionExecutionError, formatEther } from 'viem';
import { useAccount, useWriteContract } from 'wagmi';
import AssetInput from '../../components/inputs/AssetInput';
import { FormattedNumber } from '../../components/primitives/FormattedNumber';
import { useNormalizedPair } from '../../hooks/useNormalizedPair';
import { useSharedDependencies } from '../../providers/SharedDependencyProvider';
import { VistaRouterAbi } from '../../shared/abi/VistaRouter';
import { useRootStore } from '../../store/root';
import { Pool } from '../../types/Pools/Pool';
import { fromBigInt, toBigInt } from '../../utils/tokenUtils';

interface TokenSwapBuyPanelProps {
    pool: Pool;
    slippageValue: number;
    autoSlippage: boolean;
}

const TokenSwapBuyPanel = ({ pool, slippageValue, autoSlippage }: TokenSwapBuyPanelProps) => {
    const { isConnected, address } = useAccount();
    const { writeContractAsync } = useWriteContract();

    const { routerService } = useSharedDependencies();
    const [currentNetworkConfig] = useRootStore((store) => [store.currentNetworkConfig]);

    const normalizedPair = useNormalizedPair(pool.token0, pool.token1);
    const [token0] = React.useState(pool.token1); // TODO: Add setToken0
    const [token0Amount, setToken0Amount] = useState('');
    const [token1] = useState(pool.token0); // TODO: Add setToken1
    const [token1Amount, setToken1Amount] = useState('');

    const handlePreset = (preset: string) => {
        const presetLogic = async () => {
            if (normalizedPair.normalizedToken0 && normalizedPair.normalizedToken1)
                if (preset === '1%') {
                    const onePercent = normalizedPair.normalizedToken0.totalSupply / BigInt(100);
                    const amountIn = await routerService.getAmountsIn(
                        currentNetworkConfig,
                        onePercent,
                        [
                            normalizedPair.normalizedToken1.address,
                            normalizedPair.normalizedToken0.address,
                        ],
                    );
                    const formatAmountWeth = formatEther(amountIn);

                    setToken0Amount(formatAmountWeth);
                } else {
                    setToken0Amount(preset);
                }
        };
        presetLogic();
    };

    const handleSwap = () => {
        const swap = async () => {
            if (!isConnected || !address) {
                toast.error('Connect your wallet');
                return;
            }

            if (token0Amount) {
                const token0AmountBigInt = toBigInt(token0Amount, token0.decimals);
                const token1AmountBigInt = toBigInt(
                    token1Amount,
                    currentNetworkConfig.baseAssetDecimals,
                );

                let finalSlippageValue = slippageValue;

                if (autoSlippage) {
                    finalSlippageValue = Number(pool.pairBuyLpFee);
                }

                const token1Min =
                    token1AmountBigInt - (token1AmountBigInt * BigInt(finalSlippageValue)) / 100n;

                const deadline = Math.floor(Date.now() / 1000) + 60 * 20;

                const token0AmountBigIntWithFee = token0AmountBigInt + pool.pairBuyLpFeeInEth;
                try {
                    await writeContractAsync({
                        address: currentNetworkConfig.addresses
                            .NATIVE_ROUTER_ADDRESS as `0x${string}`,
                        abi: VistaRouterAbi,
                        functionName: 'swapExactETHForTokensSupportingFeeOnTransferTokens',
                        args: [token1Min, [token0.address, token1.address], address, deadline],
                        value: token0AmountBigIntWithFee,
                    });

                    toast.success('Swap successful');
                } catch (error) {
                    console.error(error);

                    if (error instanceof ContractFunctionExecutionError) {
                        toast.error(error.cause.shortMessage);
                    } else {
                        toast.error('Transaction failed, check console for details');
                    }
                }
            }
        };
        swap();
    };

    useEffect(() => {
        const getAmountsOut = async () => {
            if (parseFloat(token0Amount) > 0) {
                const token0AmountBigInt = toBigInt(token0Amount, token0.decimals);
                const amount = await routerService.getAmountsOut(
                    currentNetworkConfig,
                    token0AmountBigInt,
                    [token0.address, token1.address],
                );
                const amountStr = fromBigInt(amount, token1.decimals);
                setToken1Amount(amountStr);
            } else {
                setToken1Amount('');
            }
        };

        if (token0Amount && token0 && token1) getAmountsOut();
    }, [token0Amount, currentNetworkConfig, routerService, token0, token1]);

    return (
        <Box
            display="flex"
            flexDirection="column">
            <Box width="100%">
                <AssetInput
                    token={normalizedPair.normalizedToken1}
                    value={token0Amount}
                    setAmount={setToken0Amount}
                />
            </Box>
            <Box
                display="flex"
                flexDirection="row">
                <Typography sx={{ color: 'black', pr: 1 }}>Receive: </Typography>{' '}
                <FormattedNumber
                    sx={{ color: 'black' }}
                    value={token1Amount}
                    symbol={token1.symbol}
                />
            </Box>
            <Box sx={{ p: 2, border: '1px inset' }}>
                <Button
                    sx={{ m: 1 }}
                    variant="windows"
                    onClick={() => handlePreset('.01')}>
                    .01 ETH
                </Button>
                <Button
                    sx={{ m: 1 }}
                    variant="windows"
                    onClick={() => handlePreset('.05')}>
                    .05 ETH{' '}
                </Button>
                <Button
                    sx={{ m: 1 }}
                    variant="windows"
                    onClick={() => handlePreset('.1')}>
                    .1 ETH{' '}
                </Button>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 1 }}>
                <Button
                    variant="windows"
                    onClick={() => handleSwap()}>
                    Swap
                </Button>
            </Box>
        </Box>
    );
};
export default TokenSwapBuyPanel;
