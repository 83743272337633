export const textCenterEllipsis = (str: string, from: number, to: number) => {
    return `${str.substr(0, from)}...${str.substr(str.length - to, str.length)}`;
};

export const timeAgo = (dateString: string): string => {
    const time = new Date(dateString);
    const now = new Date();
    const diffMs = now.getTime() - time.getTime();

    const minutes = Math.floor(diffMs / (1000 * 60));
    const hours = Math.floor(minutes / 60);

    if (hours > 0) {
        return `${hours} hr${hours > 1 ? 's' : ''} ago`;
    } else if (minutes > 0) {
        return `${minutes} min${minutes > 1 ? 's' : ''} ago`;
    } else {
        return 'just now';
    }
};
