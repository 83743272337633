import { useEffect, useState } from 'react';
import { useRootStore } from '../store/root';
import { Token } from '../types/Token';

export const useNormalizedPair = (token0?: Token, token1?: Token) => {
    const [currentNetworkConfig] = useRootStore((store) => [store.currentNetworkConfig]);
    const [normalizedToken0, setNormalizedToken0] = useState<Token | undefined>();
    const [normalizedToken1, setNormalizedToken1] = useState<Token | undefined>();

    useEffect(() => {
        if (!token0 || !token1 || !currentNetworkConfig) return;

        const isToken0Eth = token0.address === '0x0';
        const isToken1Eth = token1.address === '0x0';

        let updatedToken0 = token0;
        let updatedToken1 = token1;

        if (isToken0Eth) {
            updatedToken0 = {
                ...token0,
                address: currentNetworkConfig.addresses.BASE_ASSET_ADDRESS,
            };
        }

        if (isToken1Eth) {
            updatedToken1 = {
                ...token1,
                address: currentNetworkConfig.addresses.BASE_ASSET_ADDRESS,
            };
        }

        setNormalizedToken0(updatedToken0);
        setNormalizedToken1(updatedToken1);
    }, [token0, token1, currentNetworkConfig]);

    return { normalizedToken0, normalizedToken1 };
};
